import React from "react";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import MyButton from "./MyButton";

class ToggleBtn extends MyButton {
  mOn = false;

  constructor(props) {
    super(props);
    this.mOn = props.on === true;
    makeObservable(this, {
      mOn: observable,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.on !== nextProps.on) this.mOn = nextProps.on === true;
  }

  render() {
    const arr = [];
    let type = "button";
    if (this.props.className) arr.push(this.props.className);
    if (this.mOn) arr.push("on");
    if (this.m_pID > 0) arr.push("down");

    const className = arr.join(" ");
    let { style } = this.props;
    if (this.props.view === false) {
      if (!style) style = {};
      style.display = "none";
    }
    if(this.props.type){
      type = this.props.type
    }else{
      type = "button"
    }

    return (
      <button
        id={this.props.id}
        className={className}
        type={type}
        onClick={this.onClick}
        ref={this.inited}
        disabled={this.props.disabled === true}
        draggable={false}
        onTransitionEnd={this.props.onTransitionEnd}
        style={style}>
        {this.props.children}
      </button>
    );
  }
}

export default observer(ToggleBtn);
