import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import "./Home.less";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import PartList from "./PartList";
import ToggleBtn from "@common/button/ToggleBtn";
import { SwiperSlide } from "swiper/react";
import { tools } from "util/Tools";
import { getLastContents } from "../util/APIUtils"
import TodayKorList from "./review/TodayKorList";
import { CoachMarkPopup } from "components/coach_mark/CoachMark";
import LevelThumbSwiper from "components/swiper/LevelThumbSwiper";

import help from "@images/icon/question.png"
import { notification } from "antd";

const Home = () => {
  const history = useHistory();
  const { homeStore, userStore, globalStateStore, contentsStore } = useStore();
  const { setReviewOpen, isLevelHeader, getReviewLearned, isReviewListOpen, initHome } = homeStore;
  const hideReviewBtn = isLevelHeader || getReviewLearned() || isReviewListOpen; //

  const [isCoachSwiperView, setCoachSwiperView] = useState(false);

  const getLastcon = (userId) => {
    const { setLocalLastContents } = globalStateStore;
    getLastContents(userId).then((response) => {
      setLocalLastContents(response[0]);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const LevelSwiperItem = ()=>{
    const imagesPath = "/data/";
    const { contentsData } = contentsStore;
    const { isLevelHeader, setLevelHeader } = homeStore;
    return contentsData.map((level, idx)=>(
      <SwiperSlide key={`slide-${idx}`} tag="li">
        <div className={`img-wrapper ${isLevelHeader? "on" : ""}`}
          onClick={()=>setLevelHeader(!isLevelHeader)}
        >
          <div className={`level-thumbnail ${isLevelHeader ? "on" : ""}`}
            style={{ background: `no-repeat top / 100% url(${imagesPath}${level.img_url})` }}>
          </div>
          <p className={`title ${isLevelHeader ? "on" : ""}`}> {level.title} </p>
        </div>
        <div className="part-list-wrapper" style={{display: isLevelHeader? "": "none"}}>
          <PartList level={level}/>
        </div>
      </SwiperSlide>
    ))
  } 

  useEffect(()=>{
    const { currentUser } = userStore;
    initHome();
    if(currentUser){
      getLastcon(currentUser.id);
    }else{
      notification.info({message:"please login"});
      history.push("/login");
    }
  },[])

  console.log(tools.red`*** HOME RENDER ***`);
  return (
    <>
      <div className="home-container">
        <div id="home">
          <ToggleBtn className="button help-btn" onClick={()=>setCoachSwiperView(!isCoachSwiperView)}>
            <img src={help} alt="help"/>
          </ToggleBtn>
          {isReviewListOpen? (
            <TodayKorList style={{ display: isReviewListOpen? "" : "none"}} setReviewOpen={setReviewOpen}/>
          ) : (
            <LevelThumbSwiper swiperItem={LevelSwiperItem}/>
          )}
          <ToggleBtn className="todays-korean-btn bottom"
            style={{ display: hideReviewBtn ? "none":"" }}
            onClick={()=>setReviewOpen(true)}
          >
            오늘의<br/>한국어
          </ToggleBtn>
        </div>
      </div>
      {isCoachSwiperView? <CoachMarkPopup onBackBtnClick={()=>setCoachSwiperView(false)}/> : null}
    </>
  );
};

export default withRouter(observer(Home));
