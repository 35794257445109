import { getAiServerResult } from "../util/APIUtils";
import AudioPlay from "./AudioPlay";

let leftchannel = [];
let rightchannel = [];
let recorder = null;
let recordingLength = 0;
let getStream = null;
let mediaStream = null;
const sampleRate = 16000;
let context = null;
let blob = null;
let timerId = null;
const state = {
  recorde: false,
  play: false,
};

function init() {
  leftchannel = [];
  rightchannel = [];
  recorder = null;
  recordingLength = 0;
  getStream = null;
  mediaStream = null;
  context = null;
  blob = null;
  timerId = null;
}

export function play(endAudio, setAudio) {
  if (blob == null) {
    return;
  }
  // const url = window.URL.createObjectURL(blob);
  // const audio = new AudioPlay(url, endAudio, setAudio);
  // audio.play();
  
  const typeblob = new Blob([blob], { type: "audio/wav" });
  const url = window.URL.createObjectURL(typeblob);
  const audio = new AudioPlay(url, endAudio, setAudio);
  audio.play();
}

export function getUrl() {
  if (blob == null) {
    return "";
  }
  // const url = window.URL.createObjectURL(blob);
  // return url;
  const typeblob = new Blob([blob], { type: "audio/wav" });
  const url = window.URL.createObjectURL(typeblob);
  return url;
}

export function Download() {
  if (blob == null) {
    return;
  }

  // const url = URL.createObjectURL(blob);
  const typeblob = new Blob([blob], { type: "audio/wav" });
  const url = URL.createObjectURL(typeblob);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = "sample.wav";
  a.click();
  window.URL.revokeObjectURL(url);
}

function flattenArray(channelBuffer, Length) {
  const result = new Float32Array(Length);
  let offset = 0;
  for (let i = 0; i < channelBuffer.length; i++) {
    const buffer = channelBuffer[i];
    result.set(buffer, offset);
    offset += buffer.length;
  }
  return result;
}
// 2채널 녹음시 사용
// function interleave(leftChannel, rightChannel) {
//   const length = leftChannel.length + rightChannel.length;
//   const result = new Float32Array(length);

//   let inputIndex = 0;

//   for (let index = 0; index < length; ) {
//     result[index++] = leftChannel[inputIndex];
//     result[index++] = rightChannel[inputIndex];
//     inputIndex++;
//   }
//   return result;
// }

function writeUTFBytes(view, offset, string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function recordTimer(script, callback, setEnd) {
  timerId = setTimeout(()=>{
    if(state.recorde){
      recordStop(script, callback,setEnd);
    }
  },30000)
}

export function recordStart(script, callback, callbackStop,setEnd) {
  if (navigator.mediaDevices && !state.recorde) {
    init();
    const constraints = {
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        context = new AudioContext({
          sampleRate: 16000,
        });

        getStream = stream;
        mediaStream = context.createMediaStreamSource(stream);
        const bufferSize = 2048;
        const numberOfInputChannels = 2;
        const numberOfOutputChannels = 2;
        if (context.createScriptProcessor) {
          recorder = context.createScriptProcessor(bufferSize, numberOfInputChannels, numberOfOutputChannels);
        } else {
          recorder = context.createJavaScriptNode(bufferSize, numberOfInputChannels, numberOfOutputChannels);
        }
        recorder.onaudioprocess = (data) => {
          leftchannel.push(new Float32Array(data.inputBuffer.getChannelData(0)));
          rightchannel.push(new Float32Array(data.inputBuffer.getChannelData(1)));
          recordingLength += bufferSize;
        };
        mediaStream.connect(recorder);
        recorder.connect(context.destination);
        state.recorde = true;
        callback(true);
        recordTimer(script, callbackStop ,setEnd)
      })
      .catch((err) => {
        // 마이크가 안될 때
        callback(false);
        console.log(`The following error occurred: ${err}`);
      });
  }
}

export function recordStop(script, callback, setEnd) {
  if (navigator.mediaDevices && state.recorde) {
    state.recorde = false;
    clearTimeout(timerId);

    getStream?.getAudioTracks().forEach((track)=>track.stop());
    recorder.disconnect(context.destination);
    mediaStream.disconnect(recorder);
    context.close().then(function(){
      context = null;
      getStream = null;
    })

    // const leftBuffer = flattenArray(leftchannel, recordingLength);
    const rightBuffer = flattenArray(rightchannel, recordingLength);

    // const interleaved = interleave(leftBuffer,rightBuffer);
    const interleaved = rightBuffer;
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    // var buffer = new ArrayBuffer(interleaved.length * 2);
    const view = new DataView(buffer);
    // RIFF chunk descriptor
    writeUTFBytes(view, 0, "RIFF");
    view.setUint32(4, 44 + interleaved.length * 2, true);
    writeUTFBytes(view, 8, "WAVE");
    // FMT sub-chunk
    writeUTFBytes(view, 12, "fmt ");
    view.setUint32(16, 16, true); // chunkSize
    view.setUint16(20, 1, true); // wFormatTag
    view.setUint16(22, 1, true); // wChannels: stereo (2 channels)
    view.setUint32(24, sampleRate, true); // dwSamplesPerSec
    view.setUint32(28, sampleRate * 4, true); // dwAvgBytesPerSec
    view.setUint16(32, 4, true); // wBlockAlign
    view.setUint16(34, 16, true); // wBitsPerSample
    // data sub-chunk
    writeUTFBytes(view, 36, "data");
    view.setUint32(40, interleaved.length * 2, true);

    // write the PCM samples
    let index = 44;
    // var index = 0;
    const volume = 1;
    for (let i = 0; i < interleaved.length; i++) {
      view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
      index += 2;
    }

    // our final blob
    const blobpcm = new Blob([view]);
    const reader = new FileReader();

    reader.onload = function (event) {
      const base64 = event.target.result;
      const dataBase64 = base64.split("base64,")[1]
      /////////////////////////////////////////////////////////////////////////////////
      // 분석엔진 오류 발생시 임시 랜덤 적용 배포시
      /////////////////////////////////////////////////////////////////////////////////
      /*
      const getRandom = (min, max) => Math.random() * (max - min) + min;

      // console.log("=================");
      // console.log (getRandom(50,93).toFixed(1));
      // console.log (getRandom(3,5).toFixed(2));
      // console.log("=================");
      //21 B, 61 A
      const scoreObject = [JSON.parse('{"name": "acoustic", "score": '+getRandom(55,65).toFixed(1)+', "min": 0.0, "max": 100.0 }'),
        JSON.parse('{"name": "EN_HOLISTIC","score": '+getRandom(3,5).toFixed(2)+',"min": 1.0,"max": 5.0}'),
        JSON.parse('{"name": "EN_SEGMENT","score": '+getRandom(3,5).toFixed(2)+',"min": 1.0,"max": 5.0}'),
        JSON.parse('{"name": "EN_PHONOLOGY","score": '+getRandom(3,5).toFixed(2)+',"min": 1.0,"max": 5.0}'),
        JSON.parse('{"name": "EN_RATE","score": '+getRandom(3,5).toFixed(2)+',"min": 1.0,"max": 5.0}'),
        JSON.parse('{"name": "EN_PITCH","score": '+getRandom(3,5).toFixed(2)+',"min": 1.0,"max": 5.0}')];
      callback(true,scoreObject,dataBase64,setEnd());
       */
      /////////////////////////////////////////////////////////////////////////////////
      getAiServerResult(dataBase64, script)
        .then((response) => {
          const score = JSON.parse(response.data.substring(0, response.data.lastIndexOf("}") + 1));
          let scoreObject;
          let scoreBool = false
          if (score.reason) {
            scoreObject = [{score:0},{score:0},{score:0},{score:0},{score:0},{score:0}]
            scoreBool = true
          } else {
            scoreObject = score.sentenceLevel.proficiencyScore;
            scoreBool = true
          }
          if(setEnd){
            callback(scoreBool,scoreObject,dataBase64,setEnd());
          }else{
            callback(scoreBool,scoreObject,dataBase64,-1);
          }
        })
        .catch((error) => {
          console.log("값 성공적으로 못왔냐!");
          console.log(error);
          //callback(false,"서버통신오류","",-1);
          callback(false,"D","",setEnd());
        });
      /////////////////////////////////////////////////////////////////////////////////
    };
    if (blobpcm.size === 44) {
      callback(false,"D","",setEnd());
    } else {
      reader.readAsDataURL(blobpcm);

      blob = new Blob([view]);
    }
  }
}

export function recordCancel(){
  if (navigator.mediaDevices && state.recorde) {
    state.recorde = false;
    clearTimeout(timerId);
    getStream?.getAudioTracks().forEach((track)=>track.stop());
    recorder.disconnect(context.destination);
    mediaStream.disconnect(recorder);
    context.close().then(function(){
      context = null;
      getStream = null;
    })
    console.log("녹음 취소")
  }
}