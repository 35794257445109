// import React from 'react';
import RootStore from './RootStore';

declare let data: Array<JSON>

class ContentsStore{
    rootStore;

    contentsData: Array<JSON>

    constructor(root: RootStore | undefined) {        
        this.rootStore = root;
        this.contentsData = data;
    }
}
export default ContentsStore;