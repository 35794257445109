import React, { useState } from "react";
import ToggleBtn from "@common/button/ToggleBtn";
import { Swiper, SwiperSlide } from "swiper/react";

import xBtn from "@images/button/x_btn.png";
import cm_home from "@images/coach_mark/coach_home_slide.png";
import cm_part from "@images/coach_mark/coach_part_slide.png";
import cm_grammar from "@images/coach_mark/coach_grammar_slide.png";
import cm_dialogue from "@images/coach_mark/coach_dialogue_slide.png";
import cm_video from "@images/coach_mark/coach_video_slide.png";

import swiperLeftBtn from "@images/button/swiper_left_btn.png";
import swiperRightBtn from "@images/button/swiper_right_btn.png";

import SwiperCore, {
  Scrollbar,
  Pagination
} from 'swiper/core';
SwiperCore.use([Scrollbar,Pagination]);

export function CoachMarkPopup ({ onBackBtnClick }){
  const images = [
    cm_home,
    cm_part,
    cm_grammar,
    cm_dialogue,
    cm_video,
  ]

  const onNaviBtnClick =(side)=>{
    const swiper = document.querySelector('.coach-mark-swiper-popup .swiper-container')?.swiper;
    if(swiper){
      if(side === "right") swiper.slideNext();
      if(side === "left") swiper.slidePrev();
    }
  }

  const slides = [];
  slides.push(
    images.map((img, idx)=>(
      <SwiperSlide key={`cm-slide-${idx}`} tag="li">
        <img className="coach-mark-slide" src={img} alt="" onClick={onBackBtnClick}/>
      </SwiperSlide>
    ))
  )
  
  return(
    <div className="coach-mark-swiper-popup" >
      <Swiper
        className="swiper"
        tag="section"
        wrapperTag="ul"
        pagination
        spaceBetween={30}
        centeredSlides={true}
        slidesPerView={'auto'}
      >
        {slides}
      </Swiper>
      <img className="left cm-nav-btn" src={swiperLeftBtn} alt="" onClick={()=>onNaviBtnClick("left")}/>
      <img className="right cm-nav-btn" src={swiperRightBtn} alt="" onClick={()=>onNaviBtnClick("right")}/>
    </div>
  )
}

const CoachMarck =(props)=>{
  const {children, on, isBackView, onMarkClick} = props;
  const [isCoachMarkView, setIsCoachMarkView]= useState(true);

  const onBackBtnClick=()=>{
    setIsCoachMarkView(false);
    if(onMarkClick) onMarkClick();
  }

  return(
    <>
      {isCoachMarkView?(
        <>
          <div onClick={onBackBtnClick}>{children}</div>
          <canvas style={{display: on? "" : "none"}} onClick={onBackBtnClick}/>
          <ToggleBtn className="close-coach" style={{display: on && isBackView? "" : "none"}} onClick={onBackBtnClick}>
            <img src={xBtn} />
          </ToggleBtn>
        </>
      ):null}
    </>
  )
}

export default CoachMarck;