import React, { useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import "./SentenceCard.less";
import { observer } from "mobx-react";
import ToggleBtn from "common/button/ToggleBtn";
import AudioBtn from "common/button/AudioBtn";
import AudioPlay from "common/AudioPlay";
import RecordingDots from "common/RecordingDots";
import LoadingIndicator from "common/LoadingIndicator";
import { randomArray } from "common/Reviwe";
import { Swiper, SwiperSlide } from "swiper/react";
import { getJSX, lineFeed, excludeBlock} from "util/Parser";
import { useStore } from "store/Context";
import CoachMark from "components/coach_mark/CoachMark";
import MicCheckPopup from "@components/popup/MicCheckPopup";

import backBtn from "@images/button/back_btn.png";
import speakerBtn from "@images/button/speaker_btn.png";
import speakerOnBtn from "@images/button/stop_recording_btn.png";
import ListeningBtn from "@images/button/listening_btn.png";
import PauseBtn from "@images/button/pause_btn2.png"
import RetryBtn from "@images/button/retry_btn.png";
import ScoreBadge from "@images/badge/common_score_badge.png";
import RedScoreBadge from "@images/badge/red_score_badge.png";
import button_exam from "@images/coach_mark/coach_grammar1.png";
import bottom_exam from "@images/coach_mark/coach_grammar2.png";
import card_exam from "@images/coach_mark/coach_grammar3.png";
import badge_exam from "@images/coach_mark/coach_grammar4.png";
import { setLastContents } from "util/APIUtils";

export const CardView =({content, cardTopView})=>{
  return(
    <div className="card common-scroll-white" key={content.idx}>
      <div className="top" style={{height: 45}}>
        {cardTopView? cardTopView(): null}
      </div>
      <div className="title-wrapper">
        <div className="title">{getJSX(content.sentence_kr)}</div>
      </div>
      <p className="pronunciation">{content.pronunciation}</p>
      <p className="instruction">{content.sentence_en}</p>
    </div>
  )
}

export const AllCardView = (cardContainer, data, isPopupOpen, setCurrentAudio ,score, todaysKorean)=>{
  const {recorderStore} = useStore();
  const { recordBool } = recorderStore;
  // 초반 문법 설명 카드
  data.learn_sentence.forEach((g, idx) => {
    if(!todaysKorean){
      const { syntax, usage, usage_en } = g.example;
      cardContainer.push(
        <SwiperSlide key={`grammar-${idx}`} tag="li">
          <div className="card intro common-scroll-white">
            <h2 className="title">{syntax}</h2>
            <p className="instruction">{lineFeed(usage+"<br/>"+usage_en)}</p>
          </div>
        </SwiperSlide>
      );
    }
    // 문장 카드
    g.drills.forEach((content, i) => {
      cardContainer.push(
				<SwiperSlide key={`${idx}sentence-${i}`} tag="li">
					<CardView
						content={content}
						cardTopView={() => (
							<>
								<AudioBtn
									className="speaker"
                  // style={{pointerEvents: recordBool ==="unrecord"? "":"none"}}
									src={content.audio_url}
									callback={() => {
										console.log("빈콜백!");
									}}
                  disabled={(recordBool ==="unrecord") && (isPopupOpen)? false:true}
									onClick={setCurrentAudio}
								>
									<img src={speakerBtn} alt="" />
								</AudioBtn>
								<div
									className="small-badge bg-center"
									style={{ display: score ? "" : "none", backgroundImage: `url(${ScoreBadge})` }}
								>
									{score}
								</div>
							</>
						)}
					/>
				</SwiperSlide>,
			);
    });
  });
  return cardContainer;
}

const LearningCardView = ({ data, isPopupOpen , setCurrentAudio, score, startIndex, todaysKorean ,todayNum, style, card }) => {
  let cardContainer = [];
  cardContainer = AllCardView(cardContainer, data, isPopupOpen ,setCurrentAudio, score, todaysKorean);
  
  
  if(todaysKorean){
    let temp = []
    todayNum.forEach((num)=>{
      temp.push(cardContainer[num])
    })
    cardContainer = temp;
  }
  const pagination = {
    // "clickable": true,
    "renderBullet": function (index, className) {
      return `<span class=\"${className} ${card[index]?.example && !todaysKorean ? "grammar" : ""}\"></span>`;
    },
  }
  return (
    <Swiper
      className="swiper"
      tag="section"
      wrapperTag="ul"
      allowTouchMove={false}
      initialSlide={startIndex}
      pagination={pagination}
      spaceBetween={30}
      centeredSlides
      slidesPerView={'auto'}
      clickable="true" // If true then clicking on pagination button will cause transition to appropriate slide.
      style={style}
      onReachEnd={() => console.log('Swiper end reached')}
    >
      {[cardContainer]}
    </Swiper>
  );
};


const SentenceCard = ({ currentUser, learningsContents }) => {
  const history = useHistory(), location = useLocation();
  const { recorderStore, learningStore, audioStore, globalStateStore, timeoutStore } = useStore();
  const { 
    isPopupOpen,
    getIsLoading,
    setPopup,
    setIdToRecord,
    playRecorder,
    score,
    // scoreObject,
    resetScore,
    recordToggle,
    getRetry,
    setRetry,
    turnRecorderOff,
    recordBool,
    isMicConnected,
    setMicState,
  } = recorderStore;
  const { setCurrentTimeout, stopTimeout } = timeoutStore;
  const { setCurrentAudio, stopCurrentAudio } = audioStore;
  const { setEndTime, getBadge, setBadge} = learningStore;
  const { lastContent } = globalStateStore;
  const [isBadgePop, setBadgePop] = useState(false);
  const [scoreView, setScoreView] = useState("None");
  const [ isPlay, setIsPlay ] = useState(false);
  const [ isBadge, setIsBadge] = useState(false);
  const [audioPlayIns, setAudioPlayIns] = useState(new AudioPlay());

  const fromHome = location.from === "home";
  const todaysKorean = location.from === "TodaysKorean";
  const endTime = todaysKorean ? null : setEndTime
  const retry = getRetry();

  if(!(fromHome || todaysKorean)){
    console.log("Only from Home page");
    history.push("/");
  }
  let startInd = 0
  if(!todaysKorean){
    for(let i = 1 ; i < lastContent.grammar ; i ++){
      startInd += 1;
      startInd += learningsContents.learn_sentence[i]?.drills.length;
    }
    startInd += lastContent.index;
  }

  const [slideIndex, setSlideIndex] = useState(startInd);
  const [isCoachView, setCoachView] = useState(true);
  const viewGrammrCoMark = Boolean(localStorage?.getItem("hide-grammar-coach-mark"));
  const isCoachMarkView = slideIndex === 1 && isCoachView && !todaysKorean && !viewGrammrCoMark;
  
  let container = [];

  // 카드 컨텐츠 가져오기
  learningsContents.learn_sentence.forEach(grammar => {
    container.push({example: grammar.example});
    container = container.concat(grammar.drills);
  });
  let isLastCard = slideIndex === container.length - 1;

  let currentIndex = 0;
  let currentGrammar = 0;
  let cardGrammar = learningsContents.learn_sentence[0];
  let contentLength = [];
  let noGrammarLength = 0;

  learningsContents.learn_sentence.forEach((learn) => {
    contentLength.push(learn.drills.length + (todaysKorean? 0 : 1))
    noGrammarLength += learn.drills.length
  })

  const setCurrentIndexAndCardGrammar = (slideIdx) => {
    for(let idx = 0; idx < contentLength.length; idx++){
      let tempSlideIndex = slideIdx;
      currentGrammar = idx;
      if(idx - 1 >= 0){
        for(let j=0 ; j < idx ; j++){
          tempSlideIndex -= contentLength[j]
        }
        if(tempSlideIndex < contentLength[idx]){
          currentIndex = tempSlideIndex
          cardGrammar = learningsContents.learn_sentence[idx]
          break
        }
      }else{
        if(slideIdx < contentLength[idx]){
          currentIndex = slideIdx
          cardGrammar = learningsContents.learn_sentence[idx];
          // currentGrammar = 0;
          break
        }
      }
    }
  }

  const [todayNum, setTodayNum] = useState(randomArray(noGrammarLength, learningsContents.review.step1))

  if(todaysKorean){
    isLastCard = slideIndex === todayNum.length - 1;
    if(todayNum.length){
      setCurrentIndexAndCardGrammar(todayNum[slideIndex]);
    }else{
      setCurrentIndexAndCardGrammar(slideIndex);
    }
  }else{
    setCurrentIndexAndCardGrammar(slideIndex);
  }

  let isGrammarCard = todaysKorean? false : (currentIndex === 0);

  const cardContent = cardGrammar.drills[currentIndex - (todaysKorean? 0 : 1)];

  // !isGrammarCard
  const currentScript = cardContent?.sentence_kr || "";
  const currentAudio = cardContent?.audio_url || "";
  const currentId = cardContent?.id || 0;

  const onNextBtnClick = () => {
    const swiper = document.querySelector('.sentence-card-container .swiper-container').swiper;
    swiper.slideNext();
    setIsPlay(false)
    stopTimeout();
    if (isLastCard) {
      // if (todaysKorean) history.push({pathname: "/review/result", learned: `${learningsContents.reviwe.step1}`});
      if (todaysKorean) history.push("/review/result");
      else history.push("/learning/result");
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex + 1);
    }
    setPopup(false);
  };

  const confirmMicConneced =()=>{
    setMicState(true);
    // const audioPlay = new AudioPlay(`/data/${currentAudio}`, endAudio, setCurrentAudio);
    // audioPlay.play();
    audioPlayIns.setInit(`/data/${currentAudio}`, endAudio, setCurrentAudio);
    audioPlayIns.play();
  }

  const autoAudioHandler = (audioPlay) => {
    const audio = audioPlay;
    audio.play();
  }

  const clickRetry = () => {
    setBadgePop(false);
    setPopup(false);
    setRetry();
    // const audioPlay = new AudioPlay(`/data/${currentAudio}`, endAudio, setCurrentAudio);
    // audioPlay.play();    
    audioPlayIns.setInit(`/data/${currentAudio}`, endAudio, setCurrentAudio);
    audioPlayIns.play();
  }

  const clickAudio = () =>{
    if(isPlay){
      setIsPlay(false)
      stopCurrentAudio()
    }else{
      setIsPlay(true)
      playRecorder(()=>{setIsPlay(false)},setCurrentAudio); // 유저가 녹음한거 실행
    }
  }

  const endAudio = ()=>{
    console.log("sentence card end audio")
    recordToggle(excludeBlock(currentScript), endTime);
  }

  const recordSet = () =>{
    if (isCoachMarkView) return;
    setIdToRecord(currentUser.id, currentId, currentScript ,1, true);
    setScoreView("");
    setIsBadge(false);
    resetScore();
    const timetime = setEndTime();
    console.log(timetime + "timetime -------------------------------------------------------");
    if(cardContent){
      console.log(cardContent)
      if(!todaysKorean){
        setLastContents(currentUser.id,currentId, getBadge() ,timetime)
      }
    }
    if(isGrammarCard) return;
    // autoAudioHandler(new AudioPlay(
    //   `/data/${currentAudio}`,
    //   endAudio,
    //   setCurrentAudio,
    // ));
    audioPlayIns.setInit(
      `/data/${currentAudio}`,
      endAudio,
      setCurrentAudio,
    );
    autoAudioHandler(audioPlayIns);
  }

  useEffect(()=>{
    setBadgePop(isPopupOpen);
  },[isPopupOpen])



  useEffect(()=>{
    console.log(score);
    if (score === "연결안됨"){
      setMicState(false);
      setScoreView("");
    }else{
      setMicState(true);
    }
    if(score ==="None" || score === "연결안됨") setScoreView("");
    else {
      setScoreView(score);
      if(!isBadge && !retry){
        setIsBadge(true);
        setBadge(getBadge()+1)
      }
    }
  },[score]);

  useEffect(()=>{
    if(isBadgePop){
      const tempTimeout = setTimeout(()=>{
        setBadgePop(false);
        if(retry){
          console.log("ddddds")
          setPopup(false);
          // autoAudioHandler(new AudioPlay(
          //   `/data/${currentAudio}`,
          //   endAudio,
          //   setCurrentAudio,
          // ));
          audioPlayIns.setInit(
            `/data/${currentAudio}`,
            endAudio,
            setCurrentAudio,
          );
          autoAudioHandler(audioPlayIns);
        }
      },3000);
      setCurrentTimeout(tempTimeout);
    }
  },[isBadgePop]);

  useEffect(()=>{
    turnRecorderOff();
  },[]);

  // 다음 슬라이드로 넘어갈 때, 음성 재생
  useEffect(()=>{
    recordSet();
  },[slideIndex]);

  useEffect(()=>{
    recordSet();
    if(isCoachMarkView)
      localStorage.setItem("hide-grammar-coach-mark", true);
  },[isCoachMarkView]);

  console.log("retry", retry);
  console.log("isBadgePop", isBadgePop);

  return (
    <div className="sentence-card-container">
      <CoachMark on={isCoachMarkView} onMarkClick={()=>setCoachView(false)}>
        <img className="coach-badge" src={badge_exam} style={{display: isCoachMarkView? "": "none"}}/>
        <div className="coach-wrapper" style={{display: isCoachMarkView? "": "none"}}>
          <img className="coach-sentence" src={card_exam}/>
          <img className="coach-sub-button" src={button_exam}/>
        </div>
      </CoachMark>
      <div className="learning-card-view-wrapper">
        <div className="grammar-indicator" style={{display: todaysKorean || isCoachMarkView? "none": ""}}>
          {learningsContents.learn_sentence.map((g, i)=>(
            <div className="grammar" key={"grmmar-", i}>
              <p className={currentGrammar === i? "on": null}>{g.grammar}</p>
            </div>
          ))}
        </div>
        <LearningCardView
          data={learningsContents}
          style={{visibility: isCoachMarkView? "hidden": "visible"}}
          isPopupOpen={isPopupOpen}
          setCurrentAudio={setCurrentAudio}
          score={scoreView}
          startIndex={startInd}
          todaysKorean={todaysKorean}
          todayNum={todayNum}
          card={container}
        />
      </div>
      <div className="widget-container bottom">
        {isGrammarCard ? (
          <ToggleBtn
            className="btn-frame"
            onClick={() => onNextBtnClick()}
          >
            {"다음"} <img src={backBtn} />
          </ToggleBtn>
        ) : !isPopupOpen ? (
          // 하단 녹음 버튼
          <ToggleBtn
            className="widget record"
            on={recordBool !== "unrecord"}
            style={{display: recordBool !== "unrecord" || isCoachMarkView? "" : "none"}}
            onClick={() => {
              if(isCoachMarkView){
                setCoachView(false);
                return;
              }
              stopCurrentAudio();
              recordToggle(excludeBlock(currentScript), endTime);
            }}
          >
            <img src={speakerOnBtn} alt=""/>
            {recordBool !== "unrecord" ? <RecordingDots /> : null}
          </ToggleBtn>
        ) : null}
        <img className="coach-bottom" src={bottom_exam} style={{display: isCoachMarkView? "": "none"}}/>
      </div>
      <div className="dimed-bg fade-anim" style={{display: isBadgePop && isMicConnected ? "": "none"}}>
        {
          retry ? (
            <div>
              <div className="retry-panel absolute-center"> 다시 말해보세요 </div>
              <div className="badge absolute-center bg-center" style={{ backgroundImage: `url(${RedScoreBadge})` }}>
                {scoreView}
                {/* {scoreObject.scoreNum} */}
              </div>
            </div>
          ) : (
            <div className="badge absolute-center bg-center" style={{ backgroundImage: `url(${ScoreBadge})` }}>
              {scoreView}
            </div>
          )
        }
      </div>
      <div className="btn-bundle" style={{display: isPopupOpen && !retry? "": "none"}}>
        <img className="voca-widget" alt="" src={isPlay? PauseBtn : ListeningBtn} onClick={clickAudio} />
        <img className="retry voca-widget" alt="" src={RetryBtn} onClick={clickRetry} />
        <ToggleBtn className="btn-frame" onClick={() => onNextBtnClick()}>
          {isLastCard ? (todaysKorean ? "일별 평가 관리" : "결과 보기") : ("다음")}
          <img src={backBtn} />
        </ToggleBtn>
      </div>
      <LoadingIndicator className="loading-indicator" isLoading={getIsLoading()}/>
      <MicCheckPopup on={!isMicConnected} onYesBtnClick={confirmMicConneced}/>
    </div>
  );
}

export default withRouter((observer(SentenceCard)));
