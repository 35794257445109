import React from "react";

function LightList({ data, cols, renderItem }) {
  const gridCols = cols ? Math.floor(100 / cols) : 100;
  return (
    <div className="grid-list">
      <div className="list-container">
        <div className="list">
          {data?.map((val, idx) => {
            return (
              <div className="list-item-wrap" key={(val, idx)} style={{ width: `${gridCols}%` }}>
                <div className="list-item">{renderItem(val, idx)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LightList;
