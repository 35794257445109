import { observable, makeObservable } from "mobx";

class ProfileStore {
  rootStore;

  profileState = "profile";

  isSettingOpen = false;

  pickedLevel = 0;

  pickedPart = -1;

  pickedStep = 0;

  userResultData = [];

  totalTime = "0초";

  constructor(root) {
    makeObservable(this, {
      profileState: observable,
      isSettingOpen: observable,
      pickedLevel: observable,
      pickedPart: observable,
      pickedStep: observable,
      userResultData: observable,
      totalTime: observable
    });
    this.rootStore = root;
  }

  /* handle */
  handleSetting = () => {
    this.isSettingOpen = !this.isSettingOpen;
  };

  /* set */
  setLevel = (val) => {
    this.pickedLevel = val;
  };

  setPart = (val) => {
    this.pickedPart = val;
  };

  getReportPart = ()=> {
    return this.pickedPart;
  }

  setStep = (val) => {
    this.pickedStep = val;
  }

  setUserResultData = (val) => {
    this.userResultData = val;
    console.log("user result data ", val)
  }

  setTotalTime = (val) => {
    const cSec = Math.round(val/1000)
    var hours = Math.floor(cSec/3600)
    var mins = Math.floor((cSec - hours*3600)/60)
    var secs = cSec - hours*3600 - mins*60
    this.totalTime = ( hours == 0 ? "" : hours + "시 " ) + mins + "분 " + secs + "초";
    console.log("total Time ", this.totalTime)
  }

  /* reset */
  resetLevel = () => {
    this.profileState = "level";
    this.pickedLevel = 0;
  }

  resetPart = () => {
    this.profileState = "part";
    this.pickedPart = -1;
  }

  resetStep = () => {
    this.profileState = "step";
    this.pickedStep = 0;
  }

  resetAll = () => {
    this.pickedLevel = 0;
    this.pickedPart = -1;
    this.pickedStep = 0;
    this.isSettingOpen = false;
  }
}
export default ProfileStore;