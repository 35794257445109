import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./BottomTab.less";
import { TabBar } from "antd-mobile";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import ContinueLessonPopup from "components/popup/ContinueLessonPopup";
import NoSavedLessonPopup from "components/popup/NoSavedLessonPopup";

import home from "@images/icon/home.png";
import home_on from "@images/icon/home_on.png";
import learning from "@images/icon/learning.png";
import learning_on from "@images/icon/learning_on.png";
import profile from "@images/icon/profile.png";
import profile_on from "@images/icon/profile_on.png";


const BottomTab = (props) => {
  const { globalStateStore, homeStore } = useStore();
  const { selectedBottomTab, isLastContents } = globalStateStore;
  const { setReviewOpen } = homeStore;
  const [popState, setPopState] = useState(false);
  const bgSuffix = "no-repeat center /  21px 21px";
  const tabItemData = [
    { id: "home", name: "홈", path: "/", img: home, img_on: home_on},
    { id: "learning", name: "학습", path: "/learning/card", img: learning, img_on: learning_on},
    { id: "profile", name: "학습 현황", path: "/profile/0", img: profile, img_on: profile_on},
  ];

  const onTabPress = (data)=> {
    if(data.id === "home"){
      setReviewOpen(false);
      props.history.push(data.path);
    }else if(data.id === "learning"){
      setPopState(true);
    } else if (data.id === "profile"){
      // resetAll();
      props.history.push(data.path);
    }
  }

  return (
    <div id="bottom-tab" className="bottom-tab-bar">
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#08C1BB"
        barTintColor="rgba(0,0,0,0.9)"
        hidden={globalStateStore.onHiddenBottomTab}>
        {tabItemData.map(data => {
          return (
            <TabBar.Item
              title={data.name}
              key={data.id}
              icon={<div className="icon" style={{ background: `url(${data.img}) ${bgSuffix}` }} />}
              selectedIcon={<div className="icon-on" style={{ background: `url(${data.img_on}) ${bgSuffix}` }} />}
              selected={selectedBottomTab === data.id}
              onPress={()=>onTabPress(data)}
              data-seed="logId1"
            >
              {isLastContents?(
                <ContinueLessonPopup
                  on={data.id === "learning" && popState}
                  closePopup={()=>setPopState(false)}
                />
              ):(
                <NoSavedLessonPopup
                  on={data.id === "learning" && popState}
                  closePopup={()=>setPopState(false)}
                />
              )}
            </TabBar.Item>
          );
        })}
      </TabBar>
    </div>
  );
};

export default withRouter(observer(BottomTab));
