import { observable, action, makeObservable } from "mobx";
import { play, recordStart, recordStop, getUrl, recordCancel } from "../common/Recoder";
import { setTestResult } from "../util/APIUtils";
import { getScore, getGrade } from "../common/Score"

const UNRECORD = "unrecord";
const RECORDING = "recording";
const RESULTWAIT = "resultwait";

class RecorderStore {
  currentUserId = 0;

  currentContentId = 0;

  currentScript = "";

  rootStore;

  isPopupOpen = false;

  score = "None";

  scoreObject = {};

  recordBool = UNRECORD;

  currentRecord = false;

  retry = 0;

  cutLine = 4 // A+ = 0  A = 1 B = 2 C = 3 D = 4

  scoreLevel = 0;

  scoreRange = [80,60,30,20,0]; 

  playRecorder = play;

  getAudioUrl = getUrl;

  endRecord;

  isLoading = false;

  isMicConnected = true;

  constructor(root) {
    makeObservable(this, {
      isPopupOpen: observable,
      score: observable,
      recordBool: observable,
      retry: observable,
      scoreObject : observable,
      isLoading : observable,
      isMicConnected : observable,
      callbackScore: action,
      recordCallback: action,
      recordToggle: action,
      playRecorder: action,
      setPopup: action,
      getMicState: action,
    });
    this.rootStore = root;
  }

  initRecord = () => {
    this.currentUserId = 0;
    this.currentContentId = 0;
    this.currentScript = "";
    this.setPopup(false);
    this.score = "None";
    this.recordBool = UNRECORD;
    this.currentRecord = false;
    this.retry = 0;
    this.cutLine = 4;
    this.endRecord = null;
  }

  getRetry = () =>{
    return this.retry
  }

  getRecordScore = ()=>{
    return this.score
  }

  getIsLoading = () =>{
    return this.isLoading
  }

  setEndRecord = (callback)=> {
    this.endRecord = callback;
  }
  
  setRetry = () =>{
    this.retry = 5;
  }

  setIsLoading =(state)=>{
    this.isLoading = state;
  }

  setIdToRecord = (userId, contentId, script ,step, record) =>{
    const contentsData = this.rootStore.contentsStore.contentsData[this.rootStore.homeStore.selectedLevel]
    const cutLines = contentsData?.cutline;
    const gradeToNum = (grade) => {
      let scoreNum = 0
      switch (grade){
        case "A+" : scoreNum = 0; break;
        case "A" : scoreNum = 1; break;
        case "B" : scoreNum = 2; break;
        case "C" : scoreNum = 3; break;
        case "D" : scoreNum = 4; break;
        default : scoreNum = 4; break;
      }
      return scoreNum;
    }
    this.scoreLevel = contentsData?.score_level;
    this.scoreRange = [contentsData?.grade['A+'],contentsData?.grade['A'],contentsData?.grade['B'],contentsData?.grade['C'],contentsData?.grade['D']];
    if(this.currentUserId !== userId || this.currentContentId !== contentId){
      console.log("유저 아이디 다르거나 컨텐츠 아이디 다름", step)
      if(step === 1){
        console.log("step 1")
        this.retry = 5;
        this.cutLine = gradeToNum(cutLines.step1);
      }else if(step === 2){
        console.log("step 2")
        this.retry = 5;
        this.cutLine = gradeToNum(cutLines.step2);
      }else {
        console.log("step 3")
        this.retry = 5;
        this.cutLine = gradeToNum(cutLines.step3);
      }
      this.currentRecord = record
    }
    
    this.currentUserId = userId;
    this.currentContentId = contentId;
    this.currentScript = script;
  }

  resetScore = ()=> {
    this.score = "None";
  }

  setRecord = (record) =>{
    this.currentRecord = record
  }

  getRecord = () => {
    return this.currentRecord
  }

  getRecordBool = () => {
    return this.recordBool
  }

  turnRecorderOff = () => {
    console.log("Close Learning Page");
    this.setPopup(false);
    this.score = "None";
    this.recordBool = UNRECORD;
    this.setIsLoading(false);
    this.setMicState(true);
    recordCancel();
  };

  setPopup = (state) => {
    this.isPopupOpen = state;
  };

  setMicState = ( state )=>{
    this.isMicConnected = state;
  }

  getMicState = ()=>{
    return this.isMicConnected;
  }

  callbackScore = (scoreBool,callbackscore,baseData,learnTime) => {
    let testResult;
    if(scoreBool){
      const tempScore = {
        acoustic : callbackscore[0].score,
        ko_HOLISTIC : callbackscore[1].score * 100,
        ko_SEGMENT : callbackscore[2].score * 100,
        ko_PHONOLOGY : callbackscore[3].score * 100,
        ko_RATE : callbackscore[4].score * 100,
        ko_PITCH : callbackscore[5].score * 100,
      }
      const scoreNum = getScore(tempScore,this.scoreLevel)
      this.score = getGrade(scoreNum, this.scoreRange[0],this.scoreRange[1],this.scoreRange[2],this.scoreRange[3],this.scoreRange[5]);
      testResult = {
        acoustic : callbackscore[0].score,
        ko_HOLISTIC : callbackscore[1].score * 100,
        ko_SEGMENT : callbackscore[2].score * 100,
        ko_PHONOLOGY : callbackscore[3].score * 100,
        ko_RATE : callbackscore[4].score * 100,
        ko_PITCH : callbackscore[5].score * 100,
        level : this.scoreLevel,
        grade : this.score,
        userId : this.currentUserId,
        script : this.currentScript,
        contentId : Number(this.currentContentId),
        audio_file : baseData,
        learning_time : learnTime
      }
      this.scoreObject = {
        acoustic : callbackscore[0].score,
        ko_HOLISTIC : callbackscore[1].score,
        ko_SEGMENT : callbackscore[2].score,
        ko_PHONOLOGY : callbackscore[3].score,
        ko_RATE : callbackscore[4].score,
        ko_PITCH : callbackscore[5].score ,
        scoreNum : scoreNum
      }  
    }else{
      testResult = {
        acoustic : 0,
        ko_HOLISTIC : 0,
        ko_SEGMENT : 0,
        ko_PHONOLOGY : 0,
        ko_RATE : 0,
        ko_PITCH : 0,
        level : 1,
        grade : "D",
        userId : this.currentUserId,
        script : this.currentScript,
        contentId : Number(this.currentContentId),
        audio_file : baseData,
        learning_time : learnTime
      }
      this.scoreObject = {
        acoustic : 0,
        ko_HOLISTIC : 0,
        ko_SEGMENT : 0,
        ko_PHONOLOGY : 0,
        ko_RATE : 0,
        ko_PITCH : 0,
        scoreNum : 0
      }
      this.score = callbackscore;
    }
    let scoreNum = 0;
    console.log("디스 스코어:",this.score);
    switch (this.score){
      case "A+" : scoreNum = 0; break
      case "A" : scoreNum = 1; break;
      case "B" : scoreNum = 2; break;
      case "C" : scoreNum = 3; break;
      case "D" : scoreNum = 4; break;
      default : scoreNum = 4; break;
    }
    console.log("this.retry ++++++++++++ "+this.retry)
    if(scoreNum <= this.cutLine ){
      this.retry = 0;
    }else {
      this.retry -= 1
      if(this.retry < 0){
        this.retry = 0;
      }
    }
    if(this.retry === 0){
      let sucess = "no save"
      if(learnTime !== -1){
        sucess = setTestResult(testResult);
      }
      if(sucess) console.log(sucess);
    }
    console.log("리트라이 횟수 : " , this.retry)
    console.log("커트라인 : " , this.cutLine)
    this.isLoading = false;
    if(this.score === "연결안됨"){
      this.setPopup(false);
    }else{
      this.setPopup(true);
    }

    if(this.endRecord){
      this.endRecord(this.retry);
    }
    this.recordBool = UNRECORD;
  };

  recordCallback = (bool) => {
    if (bool) {
      this.recordBool = RECORDING;
      this.isMicConnected = true;
      console.log("============================");
    } else {
      console.log("===========================1");
      this.isMicConnected = false;
      this.recordBool = UNRECORD;
      // this.retry = 0;
      // this.callbackScore(bool,"연결안됨","",-1);
    }
  };

  recordToggle = (script, setEndTime) => {
    console.log(script)
    if(!setEndTime){
      this.retry = 0;
    }
    if (this.recordBool === UNRECORD) {
      this.recordBool = RECORDING;
      recordStart(script,this.recordCallback,this.callbackScore,setEndTime);
    } else if (this.recordBool === RECORDING) {
      console.log("stop recording");
      this.isLoading = true;
      this.recordBool = RESULTWAIT;
      recordStop(script, this.callbackScore,setEndTime);
    }
    console.log("record state: ", this.recordBool);
  };
}

export default RecorderStore;
