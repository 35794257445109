import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import './Setting.less';
import { useState } from "react";
import ToggleBtn from "common/button/ToggleBtn";
import SuspendLessonPopup from "components/popup/SuspendLessonPopup";

const Setting = () => {
  const history = useHistory();
  const [popState, setPopupOpen] = useState(false);
  const NOTICE_URL = "https://mastertopikhelp.zendesk.com/hc/ko/sections/4402997436179-%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD";
  const FAQ_URL = "https://mastertopikhelp.zendesk.com/hc/ko/sections/4402997437459-F";

  const onYesBtnClick = ()=>{
    history.push("/");
    setPopupOpen(false);
  }

  const sendRedirect =(newHref)=>{
    window.location.href = newHref;
  }

  return (
    <div className="setting-container">
      <div id="setting-contents" className="setting">
        <div className="top"> 
          <ToggleBtn className="setting-btn" onClick={() => sendRedirect(NOTICE_URL)}>공지사항</ToggleBtn>
          <ToggleBtn className="setting-btn" onClick={() => sendRedirect(FAQ_URL)}>FAQ</ToggleBtn>
        </div>
        <div className="bottom">
          <ToggleBtn className="setting-btn" onClick={()=> setPopupOpen(true)}>나가기</ToggleBtn>
        </div>
      </div>
      <SuspendLessonPopup 
        on={popState}
        onYesBtnClick={onYesBtnClick}
        onNoBtnClick={()=> setPopupOpen(false)}
      />
    </div>
  );
};
export default withRouter(observer(Setting));
