import { observable, makeObservable } from "mobx";

class LearningStore {
  rootStore;

  slideIndex = 0;

  startTime = 0;
  
  endTime = 0;

  learningTime = 0;

  badge = 0;

  constructor(root) {
    makeObservable(this, {
      slideIndex: observable,
      startTime: observable,
      endTime: observable,
      learningTime: observable,
      badge : observable,
    });
    this.rootStore = root;
  }

  initLearn = () =>{
    this.badge = 0;
    this.startTime = 0;
    this.endTime = 0;
    this.learningTime = 0;
  }

  setBadge = (count)=>{
    this.badge = count;
  }

  getBadge = () =>{
    return this.badge
  }

  setStartTime =()=>{
    this.startTime = Date.now();
  }

  setLastTime = (time) =>{
    console.log(Date.now() + "date now ---------------------------")
    console.log(time + "time------------------------------")
    this.startTime = Date.now() - time;
  }

  setEndTime =()=>{
    this.endTime = Date.now()
    var timeGap = this.endTime - this.startTime;
    if (!timeGap || timeGap <= 0 || this.startTime === 0){
      timeGap = 0;
    }
    return timeGap
  }

  getLearnigTime =()=>{
    console.log("endTime : " + this.endTime);
    console.log("startTime : " + this.startTime);
    if(this.endTime === 0 || this.startTime === 0){
      return 0;
    }
    var timeGap = Math.round((this.endTime - this.startTime)/1000); 
    if(timeGap < 0 || !timeGap){
      timeGap = 0;
    }

    var diffHour = Math.floor(timeGap/3600);       // 시간
    var diffMin  = Math.floor((timeGap%3600)/60);      // 분
    var diffSec  = ((timeGap%3600)%60);      // 초

    const learningTime = {
      totalSecond : this.endTime - this.startTime,
      hour : diffHour,
      Min : diffMin,
      sec : diffSec
    }

    return learningTime

  }
}
// const store = window.store = new HomeStore();

export default LearningStore;
