import * as React from "react";

const Popup = (props)=>{
  const {on, children} = props;
  return(
    <div className={`popup-background ${on? "on": ""}`} style={{display: on? "" : "none"}}>
      <div className="popup">
        {children}
      </div>
    </div>
  );
}
export default Popup;