import React from "react";
import loading from "@images/loading.gif";

const LoadingIndicator = ({className, isLoading}) => {
  return (
    <div className={className} style={{display: isLoading? "" : "none"}}>
      <img src={loading} alt="loading" />
    </div> 
  );
}

export default LoadingIndicator;