import React, { createContext, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";
import { Avatar, notification, Progress } from 'antd';
import ProfileHeader from "./ProfileHeader"; // 설정 + 뒤로 가기 버튼 고정
import Setting from "./Setting"; // 설정 UI
import LevelReport from "./LevelReport"; // 레벨 리포트 UI: depth 2
import { useStore } from "store/Context";
import { getResultScore } from "common/Score"
import ToggleBtn from "common/button/ToggleBtn";
import "./Profile.less";
import { tools } from 'util/Tools';
import { Swiper, SwiperSlide } from "swiper/react";
import PartReport from './PartReport';

import badgeOn from "@images/badge/star_badge_on.png"
import badgeOff from "@images/badge/star_badge_off.png"

const initialSlide = createContext(0);
const SlideProvider = initialSlide.Provider;

export function ReportBtnView({className, val, noNum, setData}) {
  const history = useHistory();
  const slide = useContext(initialSlide);

  const onClick = () => {
    if(setData) setData();
    history.push(`/profile/${slide + 1}`);
  }

  return (
		<ToggleBtn className={`report-panel ${className}`} onClick={onClick}>
			<div className="title-box">
				<div className="title-num">{noNum ? null : val.id}</div>
				<div className="title">{val.title}</div>
			</div>
			<div className="score-box">{val.score}점</div>
			<div className="badge-box">
				<Progress
          className="learning-circular-progress bg-center"
					type="circle"
					style={{ backgroundImage: `url(${val.progress > 0? badgeOn : badgeOff})`}}
					strokeColor={"#08c1bb"}
          strokeWidth={10}
					width={50}
					percent={val.progress}
				/>
				<div className="progress-num">{val.progress>100? 100:val.progress}%</div>
			</div>
		</ToggleBtn>
	);
}

const PofileView = ({ currentUser, resultData }) => {
  const { totalTime, userResultData, setLevel} = useStore().profileStore;
  const metaData = [
    {name: "총 획득 배찌", userData: userResultData.length + " 개" },
    {name: "총 학습 시간", userData: totalTime },
  ];

  const username = currentUser ? currentUser.username : "none";
  return(
    <div className ="profile-wrapper">
      <div className="profile">
        <div className="user-avatar">
          <Avatar className="avatar" size="large" >
            {tools.getUserAvatar(username)}
          </Avatar>
          <div className="user-name">{username}</div>
        </div>
        <div className="user-info">
          {metaData.map((val, idx) => (
            <div className="info" key={(val, idx)}>
              <p className="meta">{val.name}</p>
              <p className="total">{`${val.userData}`}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="learning-state">
        {resultData.map((val, idx) => (
          <ReportBtnView
            className="level-btn"
            key={`level-${val.id}`}
            val={val}
            noNum
            setData={()=>setLevel(val.id)}
          />)
        )}
      </div>
    </div>
  )
} 

const Profile = (props) => {
  const { currentUser } = props;
  const { profileStore, resultStore, contentsStore } = useStore(); 
  const { pickedLevel, pickedPart, getReportPart, isSettingOpen, userResultData, setUserResultData, setTotalTime, resetAll} = profileStore;
  const { getBaseData, getTotalTime } = resultStore;
  const { contentsData } = contentsStore;
  const pSlideIdx = useRouteMatch("/profile/:profile").params.profile;

  const getPartData = () => { // type: num or null
    const level = pickedLevel;
    let returnData = null;
    contentsData.forEach(data => {
      if(data.id === level){
        returnData = data
      }
    })
    if(!returnData) returnData = contentsData[0];
    console.log(pickedPart, getReportPart);
    return returnData?.parts[pickedPart] || returnData.parts[0];
  }

  const resultData = getResultScore(userResultData, contentsData);
  console.log(resultData)
  const content = [];
  const views = [
    {compo: <PofileView currentUser={currentUser} resultData={resultData} />, path: 0},
    {compo: <LevelReport resultData={resultData} />, path: 1},
    {compo: <PartReport reportContents ={getPartData()}/>, path: 2},
  ]

  content.push(views.map((view, i)=>(
		<SwiperSlide className="report-wrapper common-scroll-white" key={"profile-",i} tag="li" >
			<SlideProvider value={view.path}>
        {view.compo}
			</SlideProvider>
		</SwiperSlide>
  )));

  useEffect(() => {
    if (!currentUser) {
      notification.info({message:"please login"});
      props.history.push("/login");
      return;
    }
    getTotalTime(currentUser.id, setTotalTime);
    getBaseData(currentUser.id, setUserResultData);
    resetAll();
  }, []);

  useEffect(()=>{
    const swiper = document.querySelector('.profile-container > #report-content > .swiper-container')?.swiper;
    if(swiper) swiper.slideTo(pSlideIdx);
  },[pSlideIdx, isSettingOpen])

  return (
    <div className="profile-container">
      <ProfileHeader reportContents={getPartData()}/>
      {isSettingOpen ? <Setting /> : null}
      <div id="report-content" className="swiper-box" style={{display: isSettingOpen? "none": ""}}>
        <Swiper
          className="swiper"
          initialSlide={useContext(initialSlide)}
          tag="section"
          wrapperTag="ul"
          allowTouchMove={false}
          autoHeight
          spaceBetween={5}
          centeredSlides
          slidesPerView={'auto'}
          clickable= "true"
        >
          {[content]}
        </Swiper>
      </div>      
    </div>
  );
}
export default withRouter(observer(Profile));