class AudioPlay {
	audio;
	callback;
	loadCallback;

	constructor(src, callback, setCurrentAudio, loadCallback) {
		this.audio = new Audio(src);
		if(setCurrentAudio) setCurrentAudio(this.audio);
		if(callback) this.callback = callback;
		if(loadCallback) this.loadCallback = loadCallback;
		this.audio.load();
		this.audio.addEventListener("loadeddata",()=>{
			console.log("audio loaded ::", this.audio.src, this.loadCallback);
			if(this.loadCallback) this.loadCallback();
		})
		this.audio.addEventListener("timeupdate", () => {
			if (this.audio.ended) {
				console.log("음성 끝", this.audio.src);
				console.log(this.audio.duration);
				if (this.callback) {
					this.callback();
				}
			}
		});
	}	

	setInit = (src, callback, setCurrentAudio, loadCallback) => {
		if(loadCallback) this.loadCallback = loadCallback;
		if(src){
			this.audio.src = src;
			this.audio.load();
		}
		if(setCurrentAudio) setCurrentAudio(this.audio);
		if(callback) this.callback = callback;
	}

	play = () => {
		console.log("audio play ::", this.audio.src);
		// this.audio.play()
		// .catch((e)=>{console.log('audio play 에러::', this.audio.src, e);
	
		const playPromise = this.audio.play();
		if(playPromise !== undefined){
			playPromise.then(_=>{return;})
			.catch((e)=>{console.log('audio play 에러::', this.audio.src, e)});
		}
	};

	stop = () => {
		this.audio.currentTime = 0;
		this.audio.pause();
	};
}
export default AudioPlay;
