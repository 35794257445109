import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import RadarReport from "./RadarReport";
import "./PartReport.less";
import ToggleBtn from "common/button/ToggleBtn";
import { getStepScore } from "common/Score";
import { useStore } from "store/Context";
import { blockLineFeed, lineFeed } from "util/Parser";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);

import speaker from "@images/button/play_btn.png";
import pause from "@images/button/pause_btn.png";
import starBadgeOn from "@images/badge/star_badge_on.png";
import starBadgeOff from "@images/badge/star_badge_off.png";
import PartReportVideo from './PartReportVideo';
import smallBadge from "@images/badge/review_badge.png";

const VRef = createContext("");

const DetailBoxView = ({ result, resultData, reportContents }) =>{
  const { resultStore, audioStore } = useStore();
  const { setCurrentAudio, stopCurrentAudio} = audioStore;
  const { getAudioData } = resultStore;
  const [onIdx, setOnIdx]= useState(-2);
  const [onGIdx, setOnGIdx]= useState(-2);
  const ref = useContext(VRef);

  console.log(reportContents);
  console.log(resultData);

  const isReportGroup =(step)=> result.title === step;

  const boxStyle = {
    backgroundColor: "rgba(20, 23, 25, 0.7)",
    borderRadius: 15,
    border: "1.5px solid rgba(103, 113, 116, 0.3)",
    padding: 10,
  }
  
  const onPlayBtnClick =(sentence, i)=>{
    if(onIdx === sentence.idx){
      stopCurrentAudio();
      setOnIdx(-1);
    }else{
      const videoElement = ref && ref.current;
      setOnIdx(sentence.idx);
      getAudioData(sentence.audio, ()=> setOnIdx(-1), setCurrentAudio);
      videoElement.pause();
    }
    setOnGIdx(i);
  }

  const getContentsIdx =(index)=>{
    let count = 0;
    for(let i= 0; i< result.detail.length; i++){
      for(let j=0; j< result.detail[i].length; j++){
        if(index === count) return j;
        count+=1;
      }
    }
  }

  const getRoleIndex =(groupIdx, scriptIdx)=>{
    if (result.title === "문법") return;
    else if (result.title === "대화"){
      return reportContents.learn_dialog[groupIdx].sequences[scriptIdx]?.role -1;
    }
    else if (result.title === "영상"){
      return reportContents.learn_movie[groupIdx].scripts[scriptIdx]?.role -1;
    }
  }

  const getRoleImage =(groupIdx, scriptIdx)=>{
    const roleIdx = getRoleIndex(groupIdx, scriptIdx);
    if (result.title === "문법") return;
    else if (result.title === "대화"){
      return reportContents.learn_dialog[groupIdx].role[roleIdx]?.img_url;
    }
    else if (result.title === "영상"){
      return reportContents.learn_movie[groupIdx].role[roleIdx]?.img_url;
    }
  }

  return (
    <div className="detail-box" >
      {result.detail.map((det,i)=>{
        return( // 그룹(대화) 단위
        <div key={det, i}>
          {result.title === "영상"? (
            reportContents.learn_movie.map(video=>(
              <PartReportVideo
                key={"video-group-", video.group}
                content={video}
                resultData={resultData[2]} // 2: 영상
                slide={0}
                useElement={()=>useContext(VRef)}
              />
            ))
          ): null}
          {/* 내 음성 듣기 */}
          <div className="sentence-group" style={isReportGroup("어휘")? null : boxStyle}>
            {det.map((sentence, idx)=>{ // 문장 단위
              return(
                <div
                  className="sentence-box"
                  key={"detail_b", sentence.idx}
                  style={isReportGroup("어휘")? boxStyle : null}
                >
                  <div className="left-side">
                    <img className="profile-img" src={`/data/${getRoleImage(i,idx)}`} alt="" />
                    <p className={`sentence ${sentence.audio? "audio" : ""}`} >{blockLineFeed(sentence.text)}</p>
                  </div>
                  <div className="right-side">
                    <div className="score-badge bg-center" style={{display: sentence.audio? "" : "none", backgroundImage : `url(${smallBadge})`}}>
                      {sentence.grade}
                    </div>
                    <ToggleBtn className="play-btn" style={{display: sentence.audio? "" : "none"}} onClick={()=>onPlayBtnClick(sentence, i)}>
                      <img src={getContentsIdx(onIdx) === idx && onGIdx === i? pause : speaker} alt="speaker" />
                    </ToggleBtn>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
      })}
    </div>
  )
}

const PartReport = ({reportContents}) => {
  const { profileStore, contentsStore } = useStore();
  const { userResultData, pickedLevel, pickedPart, pickedStep, setStep, isSettingOpen } = profileStore;
  const { contentsData } = contentsStore;
  const resultData = getStepScore(userResultData, contentsData, pickedLevel, pickedPart);

  const VideoRefProvider = VRef.Provider;
  const ref = useRef(null);
  
  const list = [
    { slide: 0, text: "학습 목표"},
    { slide: 1, text: "문법"},
    { slide: 2, text: "대화"},
    { slide: 3, text: "영상"},
  ]

  const onSlideChange =(swiper)=>{
    const videoElement = ref && ref.current;
    videoElement?.pause();
    setStep(swiper.activeIndex);
  }

  const slide = [];
  slide.push(
    <SwiperSlide className="step-swiper-slide common-scroll-white"
      style={{maxHeight: window.innerHeight - 130, overflow: "auto"}}
      tag="li" key={`gcard-${pickedLevel,pickedPart}`}
    >
      {reportContents.learn_sentence.map((val, idx) => (
        <div className="grammar-card" key={`gcard-${val, idx}`}>
          <h2 className="title">{val.example.syntax}</h2>
          <p className="instruction">{lineFeed(val.example.usage + "<br/>" + val.example.usage_en)}</p>
        </div>
      ))}
    </SwiperSlide>
  )
  slide.push(resultData.map((val, idx) => {
    return (
      <SwiperSlide className="step-swiper-slide common-scroll-white"
        style={{maxHeight: window.innerHeight - 130, overflow: "auto"}}
        tag="li" key={`slide-${val.comment,idx}`}
      >
        <div className="score-box">
          <p>종합 점수</p>
          <div className="score">{val.score}점</div>
          <div className="badge">
            <img className="bedge-img" src={val.score > 0? starBadgeOn : starBadgeOff}/>
          </div>
        </div>
        <div className="radar-box">
          <RadarReport partData={val} pickedStep={pickedStep} />
        </div>
        <p className="header-title">교사 코멘트</p>
        <div className="comment-box">{val.comment}</div>
        <p className="header-title">내 음성 듣기</p>
        <DetailBoxView
          result={val}
          resultData={resultData}
          reportContents ={reportContents}
        />
      </SwiperSlide>
    );
  }));

  useEffect(()=>{
    if (isSettingOpen){
      const videoElement = ref && ref.current;
      videoElement?.pause();
    }
  },[isSettingOpen])

  // part 선택 페이지로 넘어가면, 세부 학습형황 슬라이드를 초기화
  useEffect(()=>{
    const swiper = document.querySelector('.part-report-container > .carousel-part-container > .swiper-container')?.swiper;
    if(swiper) swiper.slideTo(0);
  },[pickedPart])

  return (
    <div className="part-report-container">
      {/* 스크롤 추가 */}
      <div className="carousel-part-container" >
        <VideoRefProvider value={ref}>
          <div className="page-title">{list[pickedStep].text}</div>
          <Swiper
            className="swiper"
            tag="section"
            wrapperTag="ul"
            autoHeight
            navigation
            centeredSlides
            onSlideChange={(swiper) => onSlideChange(swiper)}
            slidesPerView={'auto'}
            clickable= "true"
          >
            {slide}
          </Swiper>
        </VideoRefProvider>
      </div>
    </div>
  );
}

export default withRouter(observer(PartReport));