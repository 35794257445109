import React from "react";
import { useHistory } from "react-router";
import Popup from "common/Popup";
import ToggleBtn from "common/button/ToggleBtn";
import { useStore } from "store/Context";
import { notification } from "antd";

const ContinueLessonPopup = ({on, closePopup})=> {
  const { globalStateStore, homeStore, contentsStore, learningStore } = useStore();
  const { contentsData } = contentsStore;
  const history = useHistory();
  const { time, level, part, step, badge } = globalStateStore.lastContent;

  const tmpContents = contentsData && contentsData.find(val => val.id === level);
  const tmpPartContents = tmpContents && tmpContents.parts.find(val=> val.id === part);
  const data = {
    level : tmpContents?.idx || 0,
    part : tmpPartContents?.idx || 0,
    step: step -1,
  }
  
  const onContinueBtnClick = ()=>{
    const { setLevel, setPart, setLesson } = homeStore;
    const { setLastTime, setBadge } = learningStore;
  
    console.log("Set Lesson Index:", level, part, step, badge);
    setLastTime(time); // set last learning time
    setLevel(data.level);
    setPart(data.part);
    setLesson(data.step);
    setBadge(badge);
    switch(data.step){
      case 0: history.push({ pathname:`/learning/card`, from:"home"}); break;
      case 1: history.push({ pathname:`/learning/dialogue`, from:"home"}); break;
      case 2: history.push({ pathname:`/learning/video`, from:"home"}); break;
      default: notification.info({ message: "Can't find the contents" });
    }
    closePopup()
  }
  
  return (
    <Popup on={on}>
      <div className="popup-name">
        <p>이어서 진행하시겠습니까?</p>
        <div className="history" style={{color: "#08c1bb"}}>
          <div className="level">{(tmpContents?.title)}</div>
          <div className="part-num">{(tmpPartContents?.id)}</div>
          <div>{(tmpPartContents?.title)}</div>
        </div>
      </div>
      <div className="button-wrap">
        <ToggleBtn onClick={closePopup}>아니오</ToggleBtn>
        <ToggleBtn onClick={onContinueBtnClick}>예</ToggleBtn>
      </div>
    </Popup>
  )
}

export default ContinueLessonPopup;