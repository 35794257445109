import React, { useEffect } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import "./Review.less";
import { observer } from "mobx-react";
import SentenceCard from "content/learning/SentenceCard";
import Dialogue from "content/learning/Dialogue";
import VideoRolePlay from "content/learning/VideoRolePlay";
import { useStore } from "store/Context";
import { notification } from "antd";
import DailyResult from "content/review/DailyResult";
// import LoadingIndicator from "../../common/LoadingIndicator";

// 학습 컨텐츠의 기본 화면
const Review = (props) => {
  const { homeStore, contentsStore } = useStore();
  const {contentsData} = contentsStore;
  const { selectedLevel, selectedPart } = homeStore;
  const dataPart = contentsData[selectedLevel]?.parts[selectedPart] || contentsData[0].parts[0];
  const isResultPage = props.location.pathname === "/review/result";
  const lvImgUrl ="/data";

  console.log("--level--: ", selectedLevel);
  console.log("--part---: ", selectedPart);

  if (!props.currentUser) {
    props.history.push("/login");
    notification.info({
      message: "Visang GEO App",
      description: "Please login",
    });
    return <></>;
  }

  // 과 인덱스(contentsIndex) 검색
  const getPartData = () => { // type: num or null
    const level = selectedLevel;
    let returnData = null;
    contentsData.forEach(data => {
      if(data.idx === level){
        returnData = data
      }
    })
    return returnData?.parts[selectedPart] || returnData.parts[0];
  }

  useEffect(()=>{
    // 선택한 과가 없을 경우 메인화면으로 이동
    if (selectedPart === -1) {
      props.history.push("/");
      notification.warning({
        message: 'Visang GEO App',
        description: 'Sorry! Something went wrong. Please try again!'
      });
    }
  },[])

  return (
    <div className="learning-contents-container">
      <div className="learning-bg">
        <img
          style={{filter: "blur(15px) brightness(0.5)"}}
          src={`${lvImgUrl}/${dataPart.img_url}`} 
          alt="no background image"
        />
      </div>
      <div className="learning-header">
        {isResultPage? "일일 평가 관리" : `${dataPart.id}과 ${dataPart.title}`}
      </div>
      <div id="learning-contents">
        <img
          className="inner-bg"
          style={{filter:"brightness(0.5)"}}
          src={`${lvImgUrl}/${dataPart.img_url}`} 
          alt="no background image"
        />
        <Switch>
          <Route exact path="/review/card" render={() => <SentenceCard currentUser={props.currentUser} learningsContents={getPartData()} {...props} />}/>
          <Route exact path="/review/dialogue" render={() => <Dialogue currentUser={props.currentUser} learningsContents={getPartData()} {...props} />}/>
          <Route exact path="/review/video" render={() => <VideoRolePlay currentUser={props.currentUser} learningsContents={getPartData()} {...props} />}/>
          <Route path="/review/result" render={() => <DailyResult currentser={props.currentUser} learningsContents={getPartData()} {...props} />}/>
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(observer(Review));
