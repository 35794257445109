import * as React from "react";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";

class MyButton extends React.Component {
  mEl;

  mPID = -1;

  constructor(props) {
    super(props);
    makeObservable(this, {
      mPID: observable,
    });
  }

  componentDidUpdate(prev) {
    if ((this.props.disabled && !prev.disabled) || (!this.props.view && prev.view)) {
      // console.log(this.mPID);
      if (this.mPID >= 0) {
        try {
          if (this.mEl) this.mEl.releasePointerCapture(this.mPID);
        } catch (e) {
          //
        }
        this.mPID = -1;
      }
    }
  }

  onClick = (evt) => {
    console.log(this.props);
    if (this.props.onClick) {
      this.props.onClick(evt);
      console.log("click event");
    }
    if (this.props.preventEvent) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  inited = (el) => {
    const { onRef, onMouseDown, onMouseUp, disableCapture, onMouseLeave } = this.props;
    if (this.mEl || !el) return;
    this.mEl = el;
    if (onRef) onRef(el);
    el.setAttribute("touch-action", "none");

    el.addEventListener("pointerdown", (evt) => {
      if (this.mPID >= 0) return;
      this.mPID = evt.pointerId;
      if (!disableCapture) {
        try {
          el.setPointerCapture(this.mPID);
        } catch (e) {
          //
        }
      }
      if (onMouseDown) onMouseDown(evt);
    });

    el.addEventListener("pointerup", (evt) => {
      if (this.mPID !== evt.pointerId) return;
      try {
        el.releasePointerCapture(this.mPID);
      } catch (e) {
        //
      }
      this.mPID = -1;
      if (onMouseUp) onMouseUp(evt);
    });
    el.addEventListener("pointerleave", (evt) => {
      if (disableCapture) {
        if (this.mPID !== evt.pointerId) return;
        try {
          el.releasePointerCapture(this.mPID);
        } catch (e) {
          //
        }
        this.mPID = -1;
      }
      if (onMouseLeave) onMouseLeave(evt);
    });

    el.addEventListener("pointercancel", (evt) => {
      if (this.mPID !== evt.pointerId) return;
      try {
        el.releasePointerCapture(this.mPID);
      } catch (e) {
        //
      }
      this.mPID = -1;
      if (onMouseLeave) onMouseLeave(evt);
    });
  };

  render () {
    return (
      <button
        id={this.props.id}
        className={className}
        type={type}
        onClick={this.onClick}
        ref={this.inited}
        disabled={this.props.disabled === true}
        draggable={false}
        onTransitionEnd={this.props.onTransitionEnd}
        style={style}>
        {this.props.children}
      </button>
    )
  }

}
export default observer(MyButton);
