import React, {useState} from "react";
import LightList from "common/LightList";
import { useStore } from "store/Context";
import { useHistory } from "react-router";
import ToggleBtn from "common/button/ToggleBtn";
import grammar from "@images/icon/grammar.png";
import dialogue from "@images/icon/dialogue.png";
import video_roleplay from "@images/icon/video_roleplay.png";
import backBtn from "@images/button/back_btn.png";
import Popup from "@common/Popup";

const TodayKorList =({style, setReviewOpen})=>{
  
  const { homeStore, learningStore, resultStore, userStore, contentsStore } = useStore();
  const { contentsData } = contentsStore;
  const history = useHistory();
  const { currentUser } = userStore;
  const { getContentId } = resultStore;
  const { setStartTime } = learningStore;
  const { setLevel, setPart, setLesson } = homeStore;
  const [popState, setPopState] = useState(false);
  
  const NoContentAlert = () => {
    return (                   
      <Popup on={popState} className='no-content-wrapper'> 
        <div className="content">해당 단계를 먼저 학습하세요.</div>
        <div className="button-wrap">
          <ToggleBtn onClick={()=>setPopState(false)}>돌아가기</ToggleBtn>
        </div>
      </Popup>
    )
  }

  const onClick=(i)=>{
    handleTodayContents(i);
  };

  const makeRandom = (array, i) => {
    const getTrueIdx = (sLevel, sPart) => {
      let returnData = null;
      let partIdx = 0;
      contentsData.forEach(data => {
        if(data.id === sLevel){
          returnData = data
        }
      })
      returnData?.parts.forEach(part =>{
        if(part.id === sPart){
          partIdx = part.idx;
        }
      })
      const returnSelect = {
        level : returnData?.idx || 0,
        part : partIdx
      }
      return returnSelect;
    }
    if (array.length === 0) {
      setPopState(true);
    } else {
      const randomNum = Math.floor(Math.random() * (array.length))
      console.log(randomNum)
      const level = Math.floor(array[randomNum]/10000);
      const part = Math.floor((array[randomNum]%10000)/100);
      const tIdx = getTrueIdx(level, part);
      setStartTime();
      setLevel(tIdx.level);
      setPart(tIdx.part);
      setLesson(i);
      switch(i) {
        case 0: history.push({pathname: "/review/card", from: "TodaysKorean"}); break;
        case 1: history.push({pathname: "/review/dialogue", from: "TodaysKorean"}); break;
        case 2: history.push({pathname: "/review/video", from: "TodaysKorean"}); break;
        default: notification.info({ message: "Can't find the contents" });
        }
    }
  }

  // 오늘의 컨텐츠를 결정
  const handleTodayContents = (i) => {
    getContentId(currentUser.id, makeRandom, i);
  };

  const lessonData =[
    {text: "문법을 보며 연습해봅시다.", thumb: grammar},
    {text: "대화를 보며 연습해봅시다.", thumb: dialogue},
    {text: "영상을 보며 연습해봅시다.", thumb: video_roleplay},
  ];

  return(
    <div className="today-korean" style={style}>
      {popState ? <NoContentAlert /> : null }
      <button className="back-btn" onClick={()=>{setReviewOpen(false)}}>
        <img src={backBtn} alt="" />
      </button>
      <div className="today-korean-list-wrapper">
        <div className="today-korean-header" onClick={()=>setReviewOpen(false)}> 오늘의 한국어 </div>
        <div className="today-korean-list">
          <LightList
            data ={lessonData} 
            renderItem = {(lesson, idx)=> (
              <div className="lesson-item" onClick={()=>onClick(idx)}>
                <img src={lesson.thumb} />
                <div className="lesson-contents">
                  <div className="lesson-title">{lesson.text}</div>
                  <div className="start-btn"> 시작 </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default TodayKorList;