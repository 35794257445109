import { observable, makeObservable } from "mobx";

class TimeoutStore {
    rootStore;

    timeout = "";

    constructor(root){
        this.rootStore = root;
        makeObservable(this, {
            timeout: observable,
          });
    }

    setCurrentTimeout = (timeout) => {
        this.stopTimeout();
        this.timeout = timeout
        console.log("setCurrentAudio")
    }

    stopTimeout = () => {
        if(this.timeout){
            clearTimeout(this.timeout);
        }
    }
    
}

export default TimeoutStore