import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import "./Result.less";
// import CarouselSwiper from "@common/CarouselSwiper"
import { Swiper } from "swiper/react";
import ToggleBtn from "@common/button/ToggleBtn";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import { SwiperSlide } from "swiper/react";
import { setTotalLearningTime, setLearnedContent } from "util/APIUtils";
import { notification } from "antd";
import { AllCardView } from "./SentenceCard";
import { BubbleView } from "./Dialogue";
import AudioPlay from "common/AudioPlay"

import commonBadge from "@images/badge/common_badge.png"

const DialogueSlide = ({content})=> {
  const { audioStore } = useStore()
  const { sequences } = content;
  const { setCurrentAudio } = audioStore;
  const [audioPlayIns, setAudioPlayIns] = useState(new AudioPlay());

  const audioPlay = (idx) => {
    // const audio = new AudioPlay(
    //   '/data/' + sequences[idx].audio_url,
    //   null,
    //   setCurrentAudio,
    // )
    // audio.play();
    audioPlayIns.setInit(
      '/data/' + sequences[idx].audio_url,
      null,
      setCurrentAudio,
    )
    audioPlayIns.play();
  }
  return(
    <div className="card common-scroll-white" key={sequences.idx}
      style={{overflowY: "auto"}}>
      <BubbleView
        contents={content}
        currentRole={1}
        slideIndex={1}
        scoreView={new Array(sequences.length)}
        onClick={audioPlay}
        mode="result"
      />
    </div>
  );
}

const VideoSlide = ({ content, slide })=> {
  const ref = useRef(null);
  const [nowPlaying, setNowPlaying] = useState(false);

  // 카드 슬라이드가 바뀌면 영상 일시 정지
  useEffect(()=>{
    const videoElement = ref && ref.current;
    if(videoElement){
      setNowPlaying(false);
      videoElement.pause();
    }
  },[slide]);

  const onPlayIconClick = () => {
    const videoElement = ref && ref.current;
    if (videoElement) {
      if (nowPlaying) {
        console.log("------------on click");
        setNowPlaying(false);
        videoElement.pause();
      } else {
        console.log("------------on click");
        setNowPlaying(true);
        videoElement.play();
      }
    }
  };
  return(
    <div className="card" style={{paddingBottom: 0}}>
      <video
        className="video-wrapper"
        ref={ref}
        height={200}
        playsInline={true}
        onClick={onPlayIconClick}
      >
        <source src={`/data/${content}`} type="video/mp4" />
      </video>
    </div>
  )
};

const sorryMessage =()=>{
  const history = useHistory();
  history.push("/");
  notification.warning({
    message: 'Visang GEO App',
    description: 'Sorry! Something went wrong. Please try again!'
  });
  return;
}

const CarouselContentsView = ({ contents }) => {
  let view = [];
  const { homeStore, audioStore } = useStore();
  const { selectedLesson } = homeStore;
  const { setCurrentAudio,stopCurrentAudio } = audioStore;
  const [slideIndex, setSlideIndex] = useState(0);
  let slide = <></>;
  switch (selectedLesson){
    // Sentence Card
    case 0:
      view = AllCardView(view, contents, true, setCurrentAudio);
      break;
    // Dialogue Card
    case 1:
      view.push(contents.learn_dialog.map((g, idx) => {
        slide = DialogueSlide({content : g}); 
        return <SwiperSlide key={`slide-${idx}`} tag="li">{slide}</SwiperSlide>;
      }));
      break;
    // Video Card
    case 2:
      view.push(contents.learn_movie.map((g, idx) => {
        slide = VideoSlide({content : g.video_url, slide : slideIndex}); 
        return <SwiperSlide key={`slide-${idx}`} tag="li">{slide}</SwiperSlide>;
      }));
      break;
    default:
      sorryMessage();
      return <></>;
  }

  const allCardContainer=()=>{
    let container = [];
    contents.learn_sentence.forEach(grammar => {
      container.push({example: grammar.example});
      container = container.concat(grammar.drills.map(drill=> ({id : drill.id})));
    });
    return container;
  }

  const pagination = {
    "clickable": true,
    "renderBullet": (index, className) => {
      return `<span class=\"${className} ${allCardContainer()[index]?.example ? "grammar" : ""}\"></span>`;
    },
  }

  const onSlideChange =(swiper)=>{
    setSlideIndex(Number(swiper.activeIndex));
    stopCurrentAudio();
  }

  return (
    <Swiper
      className="swiper"
      tag="section"
      wrapperTag="ul"
      navigation
      pagination={(selectedLesson === 0) ? pagination : { "clickable": true }}
      spaceBetween={30}
      centeredSlides
      slidesPerView={'auto'}
      clickable= "true" // If true then clicking on pagination button will cause transition to appropriate slide.
      onSlideChange={onSlideChange}
    >
      {view}
    </Swiper>
  );
};

const Result =(props)=> {
  const { learningsContents, currentUser} = props;
  const { learningStore, globalStateStore, homeStore, contentsStore } = useStore();
  const { contentsData } = contentsStore;
  const { selectedLevel, selectedPart, selectedLesson } = homeStore
  const { initLastContents } = globalStateStore
  const { getLearnigTime, getBadge } = learningStore;
  const learningTime = getLearnigTime();

  const getTrueId = () => { // type: num or null
    const level = selectedLevel;
    let returnData = null;
    contentsData.forEach(data => {
      if(data.idx === level){
        returnData = data
      }
    })
    const returnSelect = {
      level : returnData?.id || 0,
      part : returnData?.parts[selectedPart]?.id || 0
    }

    return returnSelect;
  }

  useEffect(() => {
    const tId= getTrueId();
    const lessonId = (tId.level)*10000 + (tId.part)*100 + (selectedLesson+1)*1
    console.log("lessonId : " + lessonId);
    setTotalLearningTime(currentUser.id,learningTime.totalSecond);
    setLearnedContent(currentUser.id, lessonId)
    initLastContents(currentUser.id);
  }, []);
  
  const resultPage= {
    result_box: [
      {text: "획득 배찌", value: `${getBadge()}개`},
      {text: "학습 시간", value: `${learningTime.hour}시 ${learningTime.Min}분 ${learningTime.sec}초`},
    ],
    bottom_btn: [
      {text: "학습 현황 보기", onClick: ()=> {props.history.push("/profile/0");}},
      {text: "나가기", onClick: ()=> {props.history.push("/");}},
    ]
  }

  // learningResult.push(<div className="result-box"></div>)
  return (
    <div className="study-result-container">
      <div className="top">
        <div className="badges">
          <div className="current-badge">
            <img src={commonBadge} alt="" />
          </div>
          <div className="learning-result">
          {resultPage.result_box.map((val, idx)=>(
            <div className="result-box" key={`r-box-${idx}`}>
              <div>{val.text}</div>
              <div>{val.value}</div>
            </div>
          ))}
          </div>
        </div>
      </div>
      <p className="result-swiper-header"> 복습하기 </p>
      <CarouselContentsView contents={learningsContents}/>
      <div className="btn-bundle">
        <div className="wrapper">
          {resultPage.bottom_btn.map((val, idx)=>(
            <ToggleBtn className="home-btn" onClick={val.onClick} key={"rb-btn-",idx}>{val.text}</ToggleBtn>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withRouter(observer(Result));
