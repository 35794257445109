export const API_BASE_URL = // "https://localhost:8091/api";   // 로컬 테스트시
	process.env.REACT_APP_API_BASE_URL || "https://ai.masterkorea.com:8091/api";  // 운영배포시
//	process.env.REACT_APP_API_BASE_URL || "https://aispeaksvr.masterkorea.com/api";  // 신규 운영배포시
export const ACCESS_TOKEN = "accessToken";

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const PROTOTYPE_NUMBER = 1;
