import React from "react";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import CarouselSwiper from "@common/CarouselSwiper";
import CoachMark from "components/coach_mark/CoachMark";

import swiperLeftBtn from "@images/button/swiper_left_btn.png";
import swiperRightBtn from "@images/button/swiper_right_btn.png";
import coachHome from "@images/coach_mark/coach_home.png";
import coachPart from "@images/coach_mark/coach_part.png";

const LevelThumbSwiper = ({ swiperItem }) => {
  const { homeStore } = useStore();
  const { selectedLevel, setLevel, isLevelHeader } = homeStore;

  const onNaviBtnClick =(side)=>{
    const swiper = document.querySelector('.home-container .swiper-container')?.swiper;
    if(swiper){
      if(side === "right") swiper.slideNext();
      if(side === "left") swiper.slidePrev();
    }
  }
  const viewHomeCoMark = Boolean(localStorage?.getItem("hide-home-coach-mark"));
  const viewPartCoMark = Boolean(localStorage?.getItem("hide-part-coach-mark"));
  const isCoachHomeView = !isLevelHeader && !viewHomeCoMark;
  const isCoachPartView = isLevelHeader && !viewPartCoMark;
  
  return (
    <div className="home-swiper-wrapper">
      <CarouselSwiper 
        initialSlide={selectedLevel}
        onSlideChange={(swiper) => setLevel(Number(swiper.activeIndex))}
        renderContent={swiperItem()}
      />
      <img className="left nav-btn" style={{display: isLevelHeader? "none": ""}} src={swiperLeftBtn} alt="" onClick={()=>onNaviBtnClick("left")}/>
      <img className="right nav-btn" style={{display: isLevelHeader? "none": ""}} src={swiperRightBtn} alt="" onClick={()=>onNaviBtnClick("right")}/>
      <CoachMark on={isCoachHomeView} onMarkClick={()=>localStorage.setItem("hide-home-coach-mark", true)}>
        <img className="coach" style={{display : isCoachHomeView? "" : "none"}} src={coachHome} alt=""/>
      </CoachMark>
      <CoachMark on={isCoachPartView} onMarkClick={()=>localStorage.setItem("hide-part-coach-mark", true)}>
        <img className="coach top" style={{display : isCoachPartView? "" : "none"}} src={coachPart} alt=""/>
      </CoachMark>
    </div>
  );
};

export default observer(LevelThumbSwiper);
