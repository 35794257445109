import React, { Component } from 'react';
import './App.less';
import qs from 'qs';
import '@assets/theme/common.less';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { Layout, notification } from 'antd';
import { action, makeObservable, observable, configure } from 'mobx';
import { inject, observer } from 'mobx-react';
import { checkVisangUser, getCurrentUser, getReview } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

import Home from '../content/Home';
import Learning from '../content/learning/Learning';
import Review from '../content/review/Review';
import Profile from '../content/profile/Profile';
import Login from '../user/login/Login';
import LoginAction from '../user/login/LoginAction';
import Signup from '../user/signup/Signup';
import AppHeader from 'components/header/AppHeader';
import BottomTab from 'components/tab/BottomTab';
import NotFound from 'common/NotFound';
import LoadingIndicator from 'common/LoadingIndicator';

import homeBg from '@images/background/home_bg.png';
// import PrivateRoute from "../common/PrivateRoute";

const { Content } = Layout;

configure({
	enforceActions: 'never',
});

class App extends Component {

	currentUser = null;

	isAuthenticated = false;

	isLoading = true;

	constructor(props) {
		super(props);
		makeObservable(this, {
			currentUser: observable,
			isAuthenticated: observable,
			isLoading: observable,
			handleLogout: action,
			loadCurrentUser: action,
			handleLogin: action,
		});
		notification.config({
			placement: 'topRight',
			top: 70,
			duration: 2,
		});
	}

	getTodayKorean = (userId) => {
		const { homeStore } = this.props.store;
		const now = moment().format('YYYY-MM-DD');
		getReview(userId).then((response) => {
			const temp = response.filter((e) => {
				return e.dailyCheck === now;
			});
			if (temp.length !== 0) {
				homeStore.setReviewLearned(true);
			}
		}).catch((error) => {
			console.log(error);
		});
	};

	loginByURL = () => {
		if (this.props.location.search) {  // 운영배포시
			//if(true){ // 로컬 실행시
			try {
				console.log('location search : ', this.props.location.search);
				const userprofile = qs.parse(atob((this.props.location.search).substr(1)), { ignoreQueryPrefix: true });
				console.log(userprofile);
				checkVisangUser(userprofile.userIdx, userprofile.userID, userprofile.userPlatform) // 운영배포시
					//checkVisangUser("40595","test300@mastertopik.com","MT") // 로컬 실행시
					.then((response) => {
						console.log(response);
						if (response.resultMessage === 'success') {
							console.log(response);
							console.log(response.userName);
							console.log(response.userPlatform);
							console.log(response.UserPlatform);
							console.log(response.userIdx);
							let userPlatform = response.UserPlatform;
							if (!userPlatform) {
								userPlatform = response.userPlatform;
							}

							if (!response.userName || !userPlatform || !response.userIdx) {
								this.isLoading = false;
								notification.error({
									message: 'AI 발음 연습1',
									description: 'Sorry! Something went wrong. Please try again!1',
								});
								this.props.history.push('/');
							} else {
								console.log(response);
								var userIdx = response.userIdx;
								const userName = `${userPlatform}/${response.userName}`;
								if (userPlatform != 'MT') {
									userIdx = `${userPlatform}/${response.userIdx}`;
								}
								console.log(`userName : ${userName}`);
								this.props.history.push({
										pathname: `/login_action`,
										state: {
											name: userName,
											userIdx: userIdx,
										},
									},
								);
								this.isLoading = false;
							}
						} else {
							this.isLoading = false;
							notification.error({
								message: 'AI 발음 연습2',
								description: 'Sorry! Something went wrong. Please try again!2',
							});
							this.props.history.push('/');
						}
					})
					.catch((error) => {
						console.log(error);
						notification.error({
							message: 'AI 발음 연습2',
							description: 'Sorry! Something went wrong. Please try again!2',
						});
						this.props.history.push('/login');
					});
			} catch {
				console.log('location search : ', this.props.location.search);
				this.loadCurrentUser();
			}
		} else {
			console.log('location search : ', this.props.location.search);
			this.loadCurrentUser();
		}
	};

	loadCurrentUser = () => {
		const { userStore } = this.props.store;
		this.isLoading = true;
		getCurrentUser()
			.then((response) => {
				console.log(response.id);
				this.currentUser = response;
				userStore.setCurrentUser(response);
				this.getTodayKorean(response.id);
				this.isAuthenticated = true;
				this.isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				this.props.history.push('/login');
				this.isLoading = false;
				notification.info({
					message: 'Visang GEO App',
					description: 'Please login',
				});
			});
	};

	handleLogout = (
		redirectTo = '/login',
	) => {
		localStorage.removeItem(ACCESS_TOKEN);
		this.currentUser = null;
		this.isAuthenticated = false;

		this.props.history.push(redirectTo);
	};

	handleLogin = () => {
		this.loadCurrentUser();
		this.props.history.push('/');
	};

	componentDidMount() {
		this.loginByURL();
		console.log('app did mount');
	}

	componentDidUpdate() {
		const { globalStateStore } = this.props.store;
		const { location } = this.props;
		globalStateStore.updateBottomTabState(location);
		console.log('app did update');
	}

	render() {
		// const { store, location, history } = this.props;
		console.log('%c### APP RENDER ###', 'color: #a4f644; background-color: #000;');

		if (this.isLoading) {
			return <LoadingIndicator />;
		}
		return (
			<Layout className="app-container">
				<AppHeader />
				<Content className="app-content"
								 style={{ background: `no-repeat center / cover url(${homeBg}) rgba(0,0,0,0.5)` }}>
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<Home isAuthenticated={this.isAuthenticated} currentUser={this.currentUser} {...props} />
							)}
						/>
						<Route
							path="/learning/:content"
							render={(props) => (
								<Learning isAuthenticated={this.isAuthenticated} currentUser={this.currentUser} {...props} />
							)}
						/>
						<Route
							path="/review"
							render={(props) => (
								<Review isAuthenticated={this.isAuthenticated} currentUser={this.currentUser} {...props} />
							)}
						/>
						<Route
							path="/profile/:profile"
							render={(props) => (
								<>
									<Redirect to="/profile/0" />
									<Profile isAuthenticated={this.isAuthenticated} currentUser={this.currentUser} {...props} />
								</>
							)}
						/>
						<Route path="/login_action" render={(props) => <LoginAction onLogin={this.handleLogin} {...props} />} />
						<Route path="/login" render={(props) => <Login {...props} />} />
						<Route path="/signup/:signup" component={Signup} currentUser={this.currentUser} />
						<Route component={NotFound} />
					</Switch>
				</Content>
				<BottomTab isAuthenticated={this.isAuthenticated} currentUser={this.currentUser} onLogout={this.handleLogout} />
			</Layout>
		);
	}
}

export default withRouter(inject('store')(observer(App)));
