import React, {Component} from "react";
import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";

class AudioBtn extends Component {
  audio = new Audio();

  callback;

  argOnClick;

  constructor(props) {
    super(props);
    makeObservable(this, {
      play: action,
      onClick: action,
    });
    this.audio = new Audio("/data/"+props.src);
    this.callback = props.callback;
    this.argOnClick = props.onClick;
    this.audio.addEventListener('timeupdate', () => {
      console.log('event listener', this.audio.currentTime);
      if(this.audio.ended){
        console.log("음성 끝")
        console.log(this.audio.duration)
        if(this.callback){
          this.callback()
        }
      }
    });
  }

  play() {
    console.log(this.audio);
    this.stop();
    this.audio.pause();
    this.audio.currentTime = 0;
    if (this.audio) {
      this.audio.play()
      console.log("audiobtn play")
    }
  }

  stop() {
    console.log("------------------------------------------")
  }

  onClick = () => {
    console.log("audioclick evt");
    if(this.argOnClick){
      this.argOnClick(this.audio);
    }
    this.play()
  };

  render(){
    return(
      <button
        id={this.props.id}
        className={this.props.className}
        disabled={this.props.disabled}
        type="button"
        onClick={this.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </button>
    )
  }
}

export default observer(AudioBtn);
