import { observable, action, makeObservable } from "mobx";

class HomeStore {

  rootStore;

  part = [-1];

  isLevelHeader = false;

  selectedLevel = 0;

  selectedPart = -1;

  selectedLesson = -1;

  isReviewListOpen = false;

  isReviewLearned = false;

  constructor(root) {
    makeObservable(this, {
      selectedLevel: observable,
      selectedPart: observable,
      selectedLesson: observable,
      isLevelHeader: observable,
      isReviewListOpen: observable,
      isReviewLearned: observable,
      setLevel: action,
      setPart: action,
    });
    this.rootStore = root;

    // part state 개수 초기화
    const contentsData = root.contentsStore.contentsData;
    this.part = contentsData.map(()=>-1);
  }

  initHome = ()=>{
    console.log("홈 초기화")
    this.rootStore.timeoutStore?.stopTimeout();
    this.rootStore.audioStore?.stopCurrentAudio();
    this.rootStore.recorderStore?.initRecord();
    this.rootStore.learningStore?.initLearn();
  }

  // level
  setLevel = (val) => {
    this.selectedLevel = val;
  };

  setLevelHeader =(newState)=>{
    this.isLevelHeader = newState;
  }

  // part
  setPart =(val)=>{
    this.part[this.selectedLevel] = val;
    this.selectedPart = val;
  }

  resetPartSelect = () => {
    this.setPart(-1);
  };

  // lesson
  setLesson =(val)=>{
    this.selectedLesson = (val !== -1)? val: -1 ;
  }

  setReviewLearned = (state) =>{
    this.isReviewLearned = state
  }

  getReviewLearned = () =>{
    return this.isReviewLearned
  }

  setReviewOpen =(state)=>{
    this.isReviewListOpen = state;
  }
}

export default HomeStore;
