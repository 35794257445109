import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { useStore } from "store/Context";


const PartReportVideo = ({ content, resultData, slide, useElement })=> {
  const REPLAY = 1;
  const DUBBING = 2;
  const READY =  3;
  
  const { resultStore, audioStore } = useStore();
  const { setCurrentAudio, getCurrentAudio } = audioStore;
  const { getAudioData } = resultStore;

  const ref = useElement();
  const videoElement = ref && ref.current;
  
  const [slideIndex, setSlideIndex] = useState(0);
  const [nowPlaying, setNowPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentMode, setCurrentMode] = useState(READY); // 다시 듣기
  const currentGrammar = 0;
  
  const videoSrc = "/data/"+ content.video_url;
  const detailData = resultData?.detail[currentGrammar];

  // 동영상 시간 업데이트 함수
  const addTimeUpdate = () => {
    // const observedVideoElement = element;
    const observedVideoElement = ref && ref.current;
    if (observedVideoElement) {
      console.log("observed video : " + observedVideoElement.src);
      observedVideoElement.addEventListener("timeupdate", function () {
        if (observedVideoElement.ended) {
          console.log("종료 시간 ~~~~~~~~" + observedVideoElement.currentTime)
          setCurrentTime(observedVideoElement.currentTime);
          // setEndedPlay(true);
        }
        setCurrentTime(observedVideoElement.currentTime);
      });
      setNowPlaying(true);
      // handleControlVisible();
      // setEndedPlay(false);
      // observedVideoElement.play();
    }
  };

  const startDubbing = (currentIdx) => {
    if(detailData[currentIdx].audio){
      videoElement.muted = true
      getAudioData(detailData[currentIdx].audio, () => videoElement.play(), setCurrentAudio)
    }else{
      console.log("Can't find the recorded data");
    }
    // const audio = new AudioPlay(videoSrc, () => videoElement.play(), setCurrentAudio)
    // audio.play();
  }

  const getCurrentIndex = () => {
    if (currentMode) {
      content.scripts.forEach((script, idx) => {
        if (currentTime >= script.start_time && currentTime < script.end_time) {
          setSlideIndex(idx)
          if (detailData[idx]?.audio) {
            if (currentMode === REPLAY) {
              setCurrentMode(DUBBING)
              startDubbing(idx);
            }
          } else {
            console.log("지금은 녹음 아님!")
          }
        }
      });
    }
  }

  const workRecordPart = () => {
    if (currentMode === DUBBING) {
      console.log("더빙");
      setSlideIndex(slideIndex);
      // setCurrentIndex(slideIndex);
      const end_time = content.scripts[slideIndex].end_time;
      console.log(detailData[slideIndex].audio);
      if (currentTime > end_time) {
        if (!getCurrentAudio().ended) {
          videoElement.pause();
          videoElement.currentTime = end_time
        } else {
          videoElement.muted = false;
          setCurrentMode(REPLAY)
        }
        //소리가 나오고 있는지 체크 
        //소리가 나오고 있다면 video pause
        //소리가 나오지 않는다면 currentMode(REPLAY)
      }
    }
  }

  const onPlayIconClick = () => {
    if (videoElement) {
      if (nowPlaying) {
        setNowPlaying(false);
        if(currentMode===DUBBING){
          getCurrentAudio().pause();
        }
        videoElement.pause();
      } else {
        setNowPlaying(true);
        videoElement.play();
        if (currentMode === DUBBING && !getCurrentAudio().ended){
          getCurrentAudio().play();
        }
        else setCurrentMode(REPLAY);
      }
    }
  };

  useEffect(() => {
    ref.current.load();
    setNowPlaying(false);
    addTimeUpdate();
  }, []);

  useEffect(()=>{
    ref.current.load();
  }, [videoSrc])

  useEffect(() => {
    getCurrentIndex(); // (audioUrlArray)
    workRecordPart();
  }, [currentTime, currentMode])

  // 카드 슬라이드가 바뀌면 영상 일시 정지
  useEffect(()=>{
    setNowPlaying(false);
  },[slide]);
  console.log(videoSrc,"====================================");
  return(
    <video
      className="video"
      key={content.group}
      ref={ref}
      // controls
      width={"100%"}
      playsInline={true}
      onClick={onPlayIconClick}
    >
      <source src={videoSrc} type="video/mp4" />
    </video>
  )
};

export default observer(PartReportVideo);