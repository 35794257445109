import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./AppHeader.less";
import { Layout } from "antd";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import Popup from "@common/Popup";
import ToggleBtn from "common/button/ToggleBtn";

import backBtn from "@images/button/back_btn.png";
import starIcon from "@images/icon/star.png";
const { Header } = Layout;

const AppHeader = (props) => {
  const { globalStateStore, recorderStore, learningStore } = useStore();
	const {turnRecorderOff, score} = recorderStore;
  const { getBadge } = learningStore;
  const [popState, setPopupOpen] = useState(false);
  const isBackView = globalStateStore.isBackArrowView;
  const [badgeScoreView, setBadgeScoreView] = useState("");
  const badge = getBadge();
  const isStarView = isBackView && getBadge() !== 0 &&
		props.location.pathname !== "/learning/result" &&
		props.location.pathname !== "/review/result" &&
		props.location.pathname !== "/review/card" &&
		props.location.pathname !== "/review/dialogue" &&
		props.location.pathname !== "/review/video";
  
  const onYesBtnClick = ()=>{
    props.history.push("/");
    turnRecorderOff();
    setPopupOpen(false);
  }

  const updateStarBadge =(en)=>{
    const star = document.querySelector(".app-header  .header-item  .star-anim");
    star?.addEventListener("animationend", ()=>{
      setBadgeScoreView(`x${getBadge()}`);
      en.className = "numbering-anim"
    }, false);
  }

  useEffect(()=>{
    var e = document.getElementById("star");
    var en = document.getElementById("badge-num");
    if(badge !== 0){
      e.className = "star-anim";
      setTimeout(()=>{
        e.className = "star";
        en.className = "numbering"
      }, 2000)
    } 
  updateStarBadge(en);
  },[badge])

  useEffect(()=>{
    if(!isStarView){
      setBadgeScoreView("")
    }
  },[isStarView])

  return (
    <>
      <Header className="app-header" style={{ display: isBackView || isStarView ? "" : 'none' }}>
        <ToggleBtn
          className="back-btn header-item"
          style={{ display: isBackView ? "" : "none" }}
          onClick={() => setPopupOpen(true)}
        >
          <img src={backBtn} alt="back" />
        </ToggleBtn>
        <div
          className="header-item"
          style={{ display: isStarView ? "" : "none" }}
        >
          <div id="star" className="star"><img src={starIcon} alt="" /></div>
          <p id="badge-num" className="numbering-anim">{`${badgeScoreView}`}</p>
        </div>
      </Header>
      <Popup on={popState}>
        <div className="popup-name">정말 나가시겠습니까?</div>
        <div className="button-wrap">
          <ToggleBtn onClick={() => setPopupOpen(false)}>아니오</ToggleBtn>
          <ToggleBtn onClick={onYesBtnClick}>예</ToggleBtn>
        </div>
      </Popup>
		</>
	);
};

export default withRouter(observer(AppHeader));
