export function randomArray(round, count){
    if(!round){
      return []
    }
    let ranum = [];
    let i = 0;
    const sameNum = (arr , n) =>{
      for (let j = 0; j < ranum.length; j++) {
        if (n === arr[j]) 
          return true;
      }
      return false;
    }
    while (i < count){
      let n = Math.floor(Math.random() * round)
      if(!sameNum(ranum, n)){
        ranum.push(n);
        i += 1;
      }
    }
    return ranum
  }