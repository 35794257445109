import React from "react";
import Popup from "common/Popup";
import ToggleBtn from "common/button/ToggleBtn";

const SuspendLessonPopup = ({ on, onYesBtnClick, onNoBtnClick})=> {
  return(
    <Popup on={on}>
      <div className="popup-name">나가시겠습니까?</div>
      <div className="button-wrap">
        <ToggleBtn onClick={onNoBtnClick}>아니오</ToggleBtn>
        <ToggleBtn onClick={onYesBtnClick}>예</ToggleBtn>
      </div>
    </Popup>
  )
}

export default SuspendLessonPopup;