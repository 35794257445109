import React from "react";
import Popup from "common/Popup";
import ToggleBtn from "common/button/ToggleBtn";

const NoSavedLessonPopup = ({on, closePopup})=> {
  return(
    <Popup on={on}>
      <div className="popup-name">진행중인 학습이 없습니다.</div>
      <div className="button-wrap">
        <ToggleBtn style={{width: "50%"}} onClick={closePopup}>돌아가기</ToggleBtn>
      </div>
    </Popup>
  )
}

export default NoSavedLessonPopup;