// import ContentsStore from "../store/ContentsStore";

export const tools ={
  test :()=>{
    // const a = new ContentsStore;
    console.log("Test +++++++++")
    return 1;
  },
  red : (strs) => {
    return `\x1b[31m${strs.join('')}\x1b[0m`;
  },
  normal : (strs) => {
    return `\x1b[m${strs.join('')}\x1b[0m`;
  },
  getUserAvatar : (str) =>{
    const re = new RegExp(/\/(\w)/g);
    const userFirstLetter = re.exec(str);
    return userFirstLetter? userFirstLetter[1]?.toUpperCase(): null;
  }
}