import React, { Component } from 'react';
import { Form, notification } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { makeObservable, observable } from 'mobx';
import { signup, termsSignup } from 'util/APIUtils';
import './Signup.less';
import Terms from './Terms';
import SignupData from './SignupData.json';
import SignupFormItem from './SignupFormItem';
import { Pagination } from 'antd-mobile';

import homeBg from '@images/background/home_bg.png';

class Signup extends Component {
	useridx = '';

	studentName = '';

	guardianName = '';

	username = '';

	age = '';

	gender = '';

	nationality = SignupData.Signup[0].option[0].country_en;

	language = SignupData.Signup[3].option[0].language;

	currentPage = 0;

	constructor(props) {
		super(props);
		let userCell = '';
		let userIdx = '';
		if (props.location.state) {
			userCell = props.location.state.userCell;
			userIdx = props.location.state.userIdx;
		}
		this.useridx = userIdx;
		this.username = userCell;
		makeObservable(this, {
			useridx: observable,
			username: observable,
			age: observable,
			gender: observable,
			nationality: observable,
			language: observable,
			currentPage: observable,
		});

		console.log('Signup props', this.props);
	}

	setNationality = value => {
		this.nationality = value;
		console.log(`# nationlity : ${this.nationality}`);
	};

	setAge = value => {
		this.age = value;
		console.log(`# age : ${this.age}`);
	};

	setGender = value => {
		this.gender = value;
		console.log(`# gender : ${this.gender}`);
	};

	setLanguage = value => {
		this.language = value;
		console.log(`# language : ${this.language}`);
	};

	removeCoachMarkKey = () => {
		window.localStorage.removeItem('hide-home-coach-mark');
		window.localStorage.removeItem('hide-part-coach-mark');
		window.localStorage.removeItem('hide-grammar-coach-mark');
		window.localStorage.removeItem('hide-dialog-coach-mark');
		window.localStorage.removeItem('hide-video-coach-mark');
	};

	handleSubmit = () => {
		const { history, location } = this.props;
		console.log('handle submit', location);

		this.removeCoachMarkKey();
		const signupRequest = {
			username: this.useridx,
			// username: '1233',
			password: '123456',
			nickname: this.username,
			nationality: `${this.nationality}`,
			age: `${this.age}`,
			gender: this.gender,
			language: `${this.language}`,
		};

		signup(signupRequest)
			.then(response => {
				termsSignup({
					userId: this.useridx,
					studentName: this.studentName,
					guardianName: this.guardianName,
				});

				notification.success({
					message: 'Visang GEO App',
					description: "Thank you! You're successfully registered.",
				});

				this.props.history.push({
					pathname: `/login_action`,
					state: { name: this.username, userIdx: this.useridx },
				});
			})
			.catch(error => {
				console.log('signup error');
				notification.error({
					message: 'Visang GEO App',
					description:
						error.message || 'Sorry! Something went wrong. Please try again!',
				});
			});
	};

	addPageIndex = dir => {
		if (this.currentPage + dir < SignupData.Signup.length)
			if (this.currentPage + dir > 0) this.currentPage += dir; // direction : 1 or -1
	};

	setTemrsName = names => {
		this.studentName = names.studentName;
		this.guardianName = names.guardianName;
	};

	componentDidUpdate() {
		switch (this.props.match.params.signup) {
			case 'nationality':
				this.currentPage = 0;
				break;
			case 'age':
				this.currentPage = 1;
				break;
			case 'gender':
				this.currentPage = 2;
				break;
			case 'language':
				this.currentPage = 3;
				break;
			default:
				this.currentPage = -1;
				break;
		}
	}

	render() {
		const data = SignupData.Signup;
		const isTermsPage = this.props.match.params.signup === 'terms';
		return (
			<div
				className="signup-container full-screen-bg"
				style={{ background: `no-repeat center / cover url(${homeBg})` }}
			>
				{/* 약관동의 */}
				<Terms {...this.props} names={this.setTemrsName} />
				{/* 가입 입력 폼 */}
				<div
					className="signup-contents"
					style={{ display: isTermsPage ? 'none' : '' }}
				>
					<Pagination
						className="pagination"
						mode="pointer"
						total={data.length}
						current={this.currentPage + 1}
					/>
					<Form className="signup-form" onFinish={this.handleSubmit}>
						<SignupFormItem
							data={data[0]}
							value={this.nationality}
							setSignUpData={this.setNationality}
							addPageIndex={this.addPageIndex}
						/>
						<SignupFormItem
							data={data[1]}
							value={this.age}
							setSignUpData={this.setAge}
							addPageIndex={this.addPageIndex}
						/>
						<SignupFormItem
							data={data[2]}
							value={this.gender}
							setSignUpData={this.setGender}
							addPageIndex={this.addPageIndex}
						/>
						<SignupFormItem
							data={data[3]}
							value={this.language}
							setSignUpData={this.setLanguage}
							addPageIndex={this.addPageIndex}
						/>
					</Form>
				</div>
			</div>
		);
	}
}

export default withRouter(observer(Signup));
