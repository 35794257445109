import React from 'react';
import { Form } from "antd";
import ToggleBtn from "common/button/ToggleBtn";
import SignupRadio from "components/signup_widget/SignupRadio";
import ComboBox from "components/signup_widget/ComboBox";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { lineFeed } from 'util/Parser';

const FormItem = Form.Item;

const SignupFormItem = ({ data, value, setSignUpData, addPageIndex }) => {
    const history = useHistory();
    const match = useRouteMatch("/signup/:signup");
    const dataContainer = ()=>{
      switch(data.name){
        case "nationality":
          return data.option.map((val) => ({ label: val.country_en, value: val.country_en }));
          break;
        case "age":
          return data.option.map((val) => ({ label: val, value: val }));
          break;
        case "gender":
          return data.option.map((val) => ({ label: val, value: val }));
          break;
        case "language":
          return data.option.map((val) => ({ label: val.language, value: val.language }));
          break;
        default:
          return [];
      }
    } 
  
    const onPrevBtnClick = ()=> {
      history.push(`/signup/${data.previous}`);
      addPageIndex(-1);
    }
  
    const onNextBtnClick = ()=> {
      if (!value) value = data.option[0]; // 선택하지 않을 경우 첫번째 select로 설정
      setSignUpData(value);
      if (data.next) history.push(`/signup/${data.next}`);
      addPageIndex(1);
    }
  
    return (
      <FormItem className={data.name} style={{ display: match.params.signup === data.name ? "" : "none" }}>
        {/* <Pagination mode="pointer" total={4} current={data.id + 1} /> */}
        <h2 className="form-item-sentence">{lineFeed(data.text)}</h2>
          {data.name === "gender" ? (
            <SignupRadio data={dataContainer()} setSignUpData={setSignUpData} />
          ) : (
            <ComboBox dataContainer={dataContainer()} setSignUpData={(v)=> setSignUpData(v)}/>
          )}
        <div className="signup-btn-bundle">
          <ToggleBtn className="signup-btn previous-btn" onClick={onPrevBtnClick}> 이전 </ToggleBtn>
          <ToggleBtn className="signup-btn next-btn" type={data.type} onClick={onNextBtnClick}>
            {data.type === "submit" ? "제출" : "다음"}
          </ToggleBtn>
        </div>
      </FormItem>
    );
  };
export default SignupFormItem;
