import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "store/Context";
import "./LevelReport.less";
import { ReportBtnView } from "./Profile";

const LevelReport = ({ resultData }) => {
	const { profileStore } = useStore();
	const { pickedLevel, setPart } = profileStore;
	const pickedData = resultData.filter((data)=>data.id === pickedLevel);
	let data = pickedData[0]?.parts || resultData[0].parts;

	return (
		<div className="level-report-container slide-frame">
			{data.map((val, index) =>(
				<ReportBtnView
					className="part-box"
					key={`part-${val.id}`}
					val={val}
					setData={()=>setPart(index)}
				/>
			))}
    </div>

	);
};
export default observer(LevelReport); 