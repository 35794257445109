import React from 'react';
import { Select } from "antd";
import donwArrow from "@images/icon/down_arrow.png";
const { Option } = Select;

const ComboBox = ({ dataContainer, setSignUpData }) => {

	return (
		<Select
			className="signup-selector"
			suffixIcon={
				<img src={donwArrow} style={{ position: 'absolute', left: 0, top: '2px' }} alt="" />
			}
			autoFocus
			dropdownMatchSelectWidth={10}
			defaultValue={dataContainer[0].label}
			style={{ width: 304 }}
			listHeight={250}
			dropdownStyle={{ background: 'rgba(20, 23, 25, 0.7)', color: '#fff', borderRadius: '10px' }}
			onChange={(v, option) => setSignUpData(option.children)}
		>
			{dataContainer.map((data, idx) => (
				<Option key={('option-', idx)} value={data.val} style={{ color: '#fff' }}>
					{data.label}
				</Option>
			))}
		</Select>
	);
};

export default ComboBox;
