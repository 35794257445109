import { observable, action, makeObservable } from "mobx";
import { removeLastContent } from "util/APIUtils";

class GlobalStateStore {
  rootStore;

  onHiddenBottomTab = false;

  selectedBottomTab = "home";

  isBackArrowView = false;

  isLastContents = false;

  lastContent = {
    level: 0,
    part: 0,
    step: 0,
    grammar: 0,
    index : 0,
    badge : 0,
    time : 0
  }

  constructor(root) {
    makeObservable(this, {
      onHiddenBottomTab: observable,
      selectedBottomTab: observable,
      isBackArrowView: observable,
      isLastContents: observable,
      updateBottomTabState: action,
      onBottomTabChange: action,
    });
    this.rootStore = root;
  }

  // 풀 스크린 테스트
  fullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // 라우터 주소에 따라 하단 탭 숨기기
  hideBottomTab = (location) => {
    console.log(location.pathname);
    if (
      location.pathname === "/learning/card" ||
      location.pathname === "/learning/dialogue" ||
      location.pathname === "/learning/video" ||
      location.pathname === "/learning/result" ||
      location.pathname === "/review/card" ||
      location.pathname === "/review/dialogue" ||
      location.pathname === "/review/video" ||
      location.pathname === "/review/result"
    ) {
      this.onHiddenBottomTab = true;
      this.isBackArrowView = true;
    } else {
      this.onHiddenBottomTab = false;
      this.isBackArrowView = false;
    }
  };

  // 하단 탭에 따라 자동 렌더링
  updateBottomTabState(location) {
    switch (location.pathname){
      case "/": this.onBottomTabChange("home"); break;
      case "/study": this.onBottomTabChange("study"); break;
      case "/report": this.onBottomTabChange("report"); break;
      case "/profile/0": this.onBottomTabChange("profile"); break;
      default: this.onBottomTabChange("");
    }
    this.hideBottomTab(location);
    console.log("Update bottom tab!");
  }

  onBottomTabChange = (selectedTab) => {
    this.selectedBottomTab = selectedTab;
    // runInAction(() => {});
  };

  initLastContents = (userId) => {
    removeLastContent(userId).then((response)=>{
      console.log(response)
    })
    this.lastContent = {
      level: 0,
      part: 0,
      step: 0,
      grammar: 0,
      index : 0,
      badge : 0,
      time : 0
    }
    this.isLastContents = false;
  }

  setLocalLastContents = (lastContent) => {
    console.log(lastContent)
    if(lastContent){
      const level = Math.floor(lastContent.contentId / 100000000)
      const part = Math.floor((lastContent.contentId % 100000000) / 1000000)
      const step = Math.floor((lastContent.contentId % 1000000) / 10000)
      const grammar = Math.floor((lastContent.contentId % 10000) / 100)
      const index = lastContent.contentId % 100
      this.lastContent = {
        level: level,
        part: part,
        step: step,
        grammar: grammar,
        index : index,
        badge : lastContent.badge,
        time : lastContent.learning_time
      }
      console.log(this.lastContent);
      this.isLastContents = true;
    }else{
      this.lastContent = {
        level: 0,
        part: 0,
        step: 0,
        grammar: 0,
        index : 0,
        badge : 0,
        time : 0
      }
      this.isLastContents = false;
    }
  }
}
// const store = window.store = new UIStateStore();

export default GlobalStateStore;
