import { observable, makeObservable } from "mobx";
import {getAudioBase, getTestResult, getTotalLearningTime, getLearnedContent} from "../util/APIUtils"
import AudioPlay from "common/AudioPlay"

class ResultStore{

    rootStore;

    totalScore = 0;

    baseData = [];

    flag = false;

    audioPlayIns;
    
    constructor(root){
        makeObservable(this,{
            totalScore: observable,
            baseData : observable
        })
        this.rootStore = root;
        this.audioPlayIns = new AudioPlay();
    }

    getBaseData = (id, data) => {
        getTestResult(id).then((base)=>{
            data(base)
        }).catch((err) =>{
            console.log(err)
        })
    }

    getContentId = (id, set, step) => {
        getLearnedContent(id).then((base)=>{
            let tempArray = [];
            base.forEach((result) => {
                const cStep = Math.floor(result.lessonId % 100) - 1
                console.log("cstep ===================== : " + cStep)
                if(cStep === step){
                    tempArray.push(result.lessonId)
                }
            })
            set(tempArray, step);
        }).catch((err) =>{
            console.log(err)
        })
    }

    getTotalTime = (id, set) =>{
        getTotalLearningTime(id).then((time)=>{
            console.log(time[0].learningTime)
            set(time[0].learningTime)
        }).catch((err) =>{
            console.log(err)
        })
    }

    base64ToArrayBuffer=(base64)=> {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    getAudioData = (filename, endAudio ,setAudio) => {
        console.log(filename)
        if(!filename){
            return false;
        }
        if(this.flag){
            return false;
        }
        this.flag = true;
        
        //ios 재생 오류 //api 이전에 play 호출 시 재생됨
        this.audioPlayIns.setInit('');
        this.audioPlayIns.play();
        this.audioPlayIns.stop();
        //

        getAudioBase(filename).then((audio)=>{
            console.log('audio.data::', audio.data);
            if(audio.data){
                let blob = new Blob([this.base64ToArrayBuffer(audio.data)], {
                    type: 'audio/wav'
                })
                const url = window.URL.createObjectURL(blob);
                // const play = new AudioPlay(url, endAudio, setAudio);
                // play.play();
                console.log('오디오 플레이 객체', JSON.stringify(this.audioPlayIns));
                this.audioPlayIns.setInit(url, endAudio, setAudio, ()=>{
                    // console.log('로드완료 진짜 됨')
                });
                this.audioPlayIns.play();
            }else{
                console.log("음원데이터 없음");
                endAudio();
            }
            this.flag = false
        }).catch((err) =>{
            console.log(err)
            endAudio();
            this.flag = false
        })
    }

    getTotalScore = (id) => {
        getTestResult(id).then((ar)=>{
            let tempScore = 0
            ar.forEach(element => {
                const {acoustic, ko_HOLISTIC, ko_PHONOLOGY, ko_PITCH, ko_RATE, ko_SEGMENT} = element
                tempScore += acoustic*5
                tempScore += ko_HOLISTIC
                tempScore += ko_PHONOLOGY
                tempScore += ko_PITCH
                tempScore += ko_RATE
                tempScore += ko_SEGMENT
                console.log(element)
            });
            const returnScore = Math.round(tempScore/(ar.length*6*5))
            console.log(returnScore)
            this.totalScore = returnScore
        }
        ).catch((err) =>{
            console.log(err)
        })
        
    }

}
export default ResultStore;