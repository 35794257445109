import { observable, makeObservable } from "mobx";

class UserStore {
  rootStore;

  currentUser;

  constructor(root) {
    makeObservable(this, {
      // currentUser: observable,
    });
    this.rootStore = root;
  }

  setCurrentUser =(newUser)=>{
    this.currentUser = newUser;
  }
}
export default UserStore;
