import React from "react";
import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.css";
import { observer } from "mobx-react";
import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Pagination, Navigation]);

function CarouselSwiper({ onSlideChange, renderContent, initialSlide, navigation }) {

  return (
    <>
      {/* <SwiperJS/> */}
      <Swiper
        className="swiper"
        tag="section"
        wrapperTag="ul"
        navigation={navigation? true : false}
        pagination={true}
        initialSlide={initialSlide}
        spaceBetween={30}
        centeredSlides={true}
        slidesPerView={'auto'}
        onInit={() => console.log('Swiper initialized!')}
        clickable= "true" // If true then clicking on pagination button will cause transition to appropriate slide.
        onSlideChange={(swiper) => onSlideChange? onSlideChange(swiper): ""}
        onReachEnd={() => console.log('Swiper end reached')}>
          {renderContent}
      </Swiper>
    </>
  );
}

export default observer(CarouselSwiper);
