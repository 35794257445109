import React, { useState , useEffect } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ToggleBtn from "@common/button/ToggleBtn";
import { setReview, getReview } from "util/APIUtils";
import './DailyResult.less'
import { useStore } from "store/Context";
import ReviewCalendar from "components/calendar/ReviewCalendar";

import moment from 'moment';
import 'moment/locale/ko';
import { useHistory } from "react-router-dom";
moment.locale('ko');

const DailyResult = ({ learningsContents }) => {
  const history = useHistory();
  const { learningStore, userStore, homeStore } = useStore();
  const { currentUser } = userStore;
  const { setReviewLearned, selectedLesson } = homeStore
  const [ reviewDate , setReviewDate ] = useState([]);
  
  const learningTime = learningStore.getLearnigTime();
  const now = moment().format('YYYY-MM-DD');
  const learned = [
    learningsContents.review.step1,
    learningsContents.review.step2,
    learningsContents.review.step3,
  ] || [];

  const dailyResultData = [
    {text: "내가 복습한 문제", value: `${learned[selectedLesson]}개`},
    {text: "총 학습 시간", value: `${learningTime.hour}시 ${learningTime.Min}분 ${learningTime.sec}초`},
  ]

  useEffect(() => {
    learningStore.setEndTime();
    setReviewLearned(true)
    setReview(currentUser.id, now).then((message)=>{
      console.log(message)
      getReview(currentUser.id).then((data)=>{
        console.log(data)
        setReviewDate(data)
      }).catch((err)=>{
        console.log(err);
      })
    });
  }, []);

  return (
    <div className="daily-result-container" style={{ background: `no-repeat 75% / cover url(/images/background/basic1_1.jpg)`}}>
      <div className="content">
        <ReviewCalendar reviewDate={reviewDate} moment={moment}/>
        <div className="result-container">
          <div className="daily-result">
            {dailyResultData.map((val, idx)=>(
              <div className="result-box" key={`r-box-${idx}`}>
                <div>{val.text}</div>
                <div>{`${val.value}`}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer">
        <ToggleBtn className="btn-frame" onClick={() => history.push("/")}>
          나가기
        </ToggleBtn>
      </div> 
    </div>
  );
};

export default withRouter(observer(DailyResult));