import React from "react";
import ToggleBtn from "common/button/ToggleBtn";
import { Calendar } from "antd";

import backBtn from "@images/button/back_btn.png";
import reviewBadge from "@images/badge/review_badge.png";
import todayBadge from "@images/badge/review_badge_on.png";

const ReviewCalendar = ({reviewDate, moment})=>{

  const reviewCheck =(date)=>{
    return reviewDate.find(e=>e.dailyCheck === date.format('YYYY-MM-DD'));
  }

  return(
    <div className="calendar-wrapper">
      <Calendar
        fullscreen={false}        
        dateFullCellRender={(date) => {
          const badge = date.format('L') === moment().format('L')? todayBadge : reviewBadge;
          return (
            <div
              className={`${reviewCheck(date) ? "o" : ""}`}
              style={{ background: reviewCheck(date) ? `no-repeat center / 100% url(${badge})` : "" }}
            >
              {date.format('D')}
            </div>
          )
        }}
        headerRender={({ value, type, onChange }) => {
          const calendarLimit = value.format('YYYY-MM') === moment().format('YYYY-MM');
          return (
            <div className="calendar-header">
              <ToggleBtn
                className="left calendar-btn"
                onClick={() => {
                  const newValue = value.clone();
                  newValue.month(parseInt(value.month() - 1, 10));
                  onChange(newValue);
                }}>
                <img src={backBtn} />
              </ToggleBtn>
              <p>
                {`${value.year()}년 ${value.month() + 1}월`}
              </p>
              <ToggleBtn
                className="right calendar-btn"
                onClick={() => {
                  console.log(value.month() + 1, value.format('YYYY-MM'), moment().format('YYYY-MM'));
                  if (calendarLimit) return;
                  const newValue = value.clone();
                  newValue.month(parseInt(value.month() + 1, 10));
                  onChange(newValue);
                }}>
                <img src={backBtn} style={{opacity: calendarLimit? 0.5: 1}} />
              </ToggleBtn>
            </div>
          );
        }}
      />
    </div>
  )
}


export default ReviewCalendar;