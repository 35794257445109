import React, { useEffect } from "react";
import { login } from "../../util/APIUtils";
import { notification } from "antd";
import { ACCESS_TOKEN } from "../../constants";
import qs from "qs";

import LoadingIndicator from "common/LoadingIndicator";
import { useHistory, useLocation } from "react-router-dom";

const LoginAction =({ onLogin })=> {
  const history = useHistory(), location = useLocation();
  
  const handleSubmit = () => {
    if(!location.state){
      notification.error({
              message: "AI 발음 연습",
              description: "Sorry! Something went wrong. Please try again!",
            });
      return history.push(`/login`)
    }
    const userName = location.state.name;
    const userIdx = location.state.userIdx;
    console.log(userName,userIdx);
    if (userName) {
      const values = {
        username: userIdx,
        password: "123456",
      };
      console.log(`user name: ${userName}`);
      const loginRequest = { ...values };
      login(loginRequest)
        .then((response) => {
          localStorage.setItem(ACCESS_TOKEN, response.accessToken);
          onLogin();
        })
        .catch((error) => {
          if (error.status === 401) {
            history.push({
              pathname: "/signup/terms",
              state: { userCell: userName,
                userIdx : userIdx,
              },
            });
          } else {
            console.log(error, error?.message);
            // notification.error({
            //   message: "Visang GEO App",
            //   description: error.message || "Sorry! Something went wrong. Please try again!",
            // });
          }
        });
    }
  };

  useEffect(()=>{
    handleSubmit();
  },[])

  return (
    <div className="login-container">
      <h1 className="page-title" style={{color: "#fff"}}>Loading...</h1>
      <LoadingIndicator className="loading-indicator" isLoading/>
    </div>
  );
}

export default LoginAction;
