import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, withRouter } from "react-router-dom";
import { useStore } from "../store/Context";
import { Accordion } from "antd-mobile";
import { notification } from "antd";
import LightList from "common/LightList";
import { getLearnedContent } from "util/APIUtils"
// import StepProgress from "components/progress/StepProgress"

import grammar from "@images/icon/grammar.png";
import dialogue from "@images/icon/dialogue.png";
import video_roleplay from "@images/icon/video_roleplay.png";
import done_badge from "@images/badge/done_badge.png";
const Panel = Accordion.Panel;

const PartList = ({level}) => {
  const history = useHistory();
  const { homeStore, learningStore, globalStateStore, userStore, contentsStore } = useStore();
  const { contentsData } = contentsStore;
  const { initLastContents } = globalStateStore
  const { currentUser } = userStore;
  const { selectedLevel, setPart, setLesson } = homeStore;
  const { setStartTime } = learningStore;
  const [doneCheckList, setDoneCheckList] = useState([]);
  let data = level.parts;

  const lessonData =[
    {text: "문법을 보며 연습해봅시다.", thumb: grammar},
    {text: "대화를 보며 연습해봅시다.", thumb: dialogue},
    {text: "영상을 보며 연습해봅시다.", thumb: video_roleplay},
  ];

  const onClick=(i)=>{
    initLastContents(currentUser.id);
    setStartTime();
    switch(i){
      case 0: history.push({ pathname: `/learning/card`, from: "home"}); break;
      case 1: history.push({ pathname: `/learning/dialogue`, from: "home"}); break;
      case 2: history.push({ pathname: `/learning/video`, from: "home"}); break;
      default: notification.info({ message: "Can't find the contents" });
    }
    setLesson(i);
  };

  const handlePartSelect = (key) => {
    key? setPart(Number(key)) : setPart(-1);
  };

  const PartPanelView = (part)=>(
    <>
      <div className="part-header-num">{part.id}</div>
      <div>{part.title}</div>
    </>
  );
  const getTrueId = (sPart) => { // type: num or null
    const level = selectedLevel;
    let returnData = null;
    contentsData.forEach(data => {
      if(data.idx === level){
        returnData = data
      }
    })
    console.log(returnData);
    const returnSelect = {
      level : returnData?.id || 0,
      part : returnData?.parts[sPart]?.id || 0
    }

    return returnSelect;
  }
  const check =(part, step)=>{
    const tId = getTrueId(part)
    const thisIndex = (tId.level)* 10000 + (tId.part) * 100;
    const tmp = (val)=> val === thisIndex + step + 1;
    return doneCheckList?.find(tmp)? true : false;
  };

  useEffect(()=>{
    if(currentUser){
      getLearnedContent(currentUser.id).then((base) => {
        setDoneCheckList(base.map( data => data.lessonId))
      }).catch((err) => {
        console.log(err)
      })
    }
  },[])

  return (
    <div className="part-container common-scroll-white">
      <Accordion
      className="part-accordion" accordion onChange={ handlePartSelect }>
        {data.map((part, i)=>(
          <Panel className="part-panel" header={PartPanelView(part)} key={part, i}>
            <LightList data ={lessonData} renderItem = {(lesson, idx)=> (
              <div className="lesson-item" onClick={()=>onClick(idx)}>
                <div className="progress-wrapper">
                  {/* <StepProgress part={part} idx={idx}/> */}
                  <img className="progress-bg" src={lesson.thumb} />
                </div>                
                <div className="lesson-contents">
                  <div className="lesson-title">{lesson.text}</div>
                  <div className="start-btn"> 시작 </div>
                  <img className="done-badge" src={done_badge} style={{display: check(i,idx)? "": "none"}} alt=""/>
                </div>
              </div>
            )} />
          </Panel>
        ))}
      </Accordion>
    </div>
  );
};
export default withRouter(observer(PartList));
