import React  from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import "@assets/css/styles.less";
import "./index.less";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import { StoreProvider } from "./store/Context";
import RootStore from "./store/RootStore";

const rootStore :RootStore = new RootStore();
const Root = (
    <StoreProvider value={rootStore}>
      <Provider store={rootStore}>
        <Router>
          <App />
        </Router>
      </Provider>
    </StoreProvider>
);

ReactDOM.render(Root, document.getElementById("root"));
