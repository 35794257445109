import React, { Component } from "react";
import { Radio } from "antd";
import { observer } from "mobx-react";
import { observable, action, makeObservable } from "mobx";

const RadioGroup = Radio.Group;

class SignupRadio extends Component {
  gender = "선택 안 함";

  constructor(props) {
    super(props);
    makeObservable(this, {
      gender: observable,
      onChange: action,
    });
  }

  onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.gender = e.target.value;
    this.props.setSignUpData(this.gender);
    // this.props.value = this.gender;
  };

  render() {
    const { data } = this.props;
    return (
      <RadioGroup className="radio-group" value={this.gender} onChange={this.onChange}>
        {data.map((content, idx) => {
          return (
            <Radio key={(content.value, idx)} value={content.value}>
              {content.value}
            </Radio>
          );
        })}
      </RadioGroup>
    );
  }
}

export default observer(SignupRadio);
