import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { useStore } from "store/Context";
import ToggleBtn from "common/button/ToggleBtn";

import backBtn from "@images/button/back_btn.png"
import setting from "@images/icon/setting.png"
import { useHistory, useRouteMatch } from "react-router";
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);

const ProfileHeader = ({reportContents}) => {
	const { Header } = Layout;
	const { profileStore } = useStore();
	const { profileState, isSettingOpen, handleSetting, pickedPart, setPart,
			resetLevel, resetPart, resetStep } = profileStore;
	const [isBackBtnView, setBtnView] = useState(true);
	const history = useHistory();
	const match = useRouteMatch("/profile/:profile");
  
	
	const onBackBtnClick = () => {
		if (isSettingOpen) { handleSetting(); return; }
		const slideIdx = match.params.profile; // profile slide index
    history.push(`/profile/${Number(slideIdx) + -1}`);
		setPart(-1);

		switch(profileState) {
			// case "setting" : handleSetting(); break;
			case "level" : resetLevel(); break;
			case "part" : resetPart(); resetStep(); break;
			case "step" : resetStep(); break;
			default: break;
		}
  };

	const onSettingClick =()=>{
		handleSetting();
		setBtnView(true);
	}

	useEffect(()=>{
		if(location.pathname==="/profile/0" && !isSettingOpen)
			setBtnView(false);
		else{
			setBtnView(true);
		}
	},[location.pathname])

	return (
		<Header className="profile-header">
			{/* back btn */}
			<ToggleBtn className="button back-btn" 
				onClick={onBackBtnClick} style={{display: isBackBtnView ? "": "none"}}>
				<img src={backBtn} alt="back" />
			</ToggleBtn>
			<div className="level-title" style={{ display: isSettingOpen ? "none" : ""}}>
				{pickedPart === -1 ? "학습 현황"
				: `${reportContents?.id}과 ${reportContents?.title}`}
			</div>
			{/* setting btn */}
			<ToggleBtn className="button setting-btn" onClick={onSettingClick}
				style={{ display: isSettingOpen ? "none" : "" }} >
				<img src={setting} alt="setting" />
			</ToggleBtn>
		</Header>
	);
};

export default ProfileHeader;