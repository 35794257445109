import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Dialogue.less";
import { observer } from "mobx-react";
import ToggleBtn from "common/button/ToggleBtn";
import { useStore } from "store/Context";
import AudioPlay from "common/AudioPlay"
import { getJSX, excludeBlock} from "util/Parser";
import RecordingDots from "common/RecordingDots";
import LoadingIndicator from "common/LoadingIndicator";
import { randomArray } from "common/Reviwe";
import CoachMark from "components/coach_mark/CoachMark";
import { Pagination } from 'antd-mobile';
import MicCheckPopup from "@components/popup/MicCheckPopup";

import ScoreBadge from "@images/badge/common_score_badge.png";
import RedScoreBadge from "@images/badge/red_score_badge.png";
import ListeningBtn from "@images/button/listening_btn.png";
import RetryBtn from "@images/button/retry_btn.png";
import XBtn from "@images/button/x_btn.png";
import recorderBtn from "@images/button/stop_recording_btn.png";
import dialogueCoachMark from "@images/coach_mark/coach_dialogue.png";
import { setLastContents } from "util/APIUtils";

export const BubbleView = ({contents, currentRole, slideIndex, onClick, scoreView, mode}) => {
  if(!contents) return <></>;
  const { sequences, role} = contents;
  const profilePath = "/data";
  const isResultView = mode === "result";
  let isHighLight = false; 
  return(
    <div className="bubble-container">
    {sequences.map((val, idx) => {
      const isCurrentBubble = isResultView? true : slideIndex === idx;
      const isRightSide = val.role === currentRole;
      isHighLight = isCurrentBubble;
      return (
        <div key={(val, idx)} className="bubble-box"
          style={{display: ((idx > slideIndex) && !isResultView)? "none": "" }}
        >
          {!isRightSide?
            <img
              className="profile-img"
              src={`${profilePath}/${role[val.role - 1].img_url}`}
              style={{opacity: isCurrentBubble? 1: 0.4}}
            />
          : null}
          <div className="bubble-wrapper">
            <p className="person-name" style={{opacity: isCurrentBubble? 1: 0.4}}>
              {isRightSide? "" : role[val.role - 1].name}
            </p>
            <div className={`bubble ${isRightSide? "right" : "left"} ${isHighLight? "on" : ""} ${isResultView? mode : ""}`} onClick={()=>onClick(idx)} >
              <div className={`message ${isRightSide? "right" : "left"}`} style={{opacity: isCurrentBubble? 1: 0.4}}>{getJSX(val.message)}</div>
              <div className="sub" style={{opacity: isCurrentBubble? 1: 0.4}}>{val.pronunciation}</div>
              <div className="en sub">{val.sentence_en}</div>
            </div>
            {scoreView[idx]?(
              <div 
                className={`sub-badge ${isRightSide? "right" : "left"}`} 
                style={{display: scoreView[idx]? "" : "none", background: `no-repeat center / 100% url(${ScoreBadge})`}}>
                {scoreView[idx]}
              </div>
            ): null}
          </div>
        </div>
      );
    })}
  </div>
)}

const Dialogue = ({ currentUser, learningsContents } ) => {
  const location = useLocation();
  const fromHome = location.from === "home";
  const todaysKorean = location.from === "TodaysKorean";
  const { recorderStore, learningStore, audioStore, globalStateStore, timeoutStore } = useStore();
  const { lastContent } = globalStateStore;
  const { setCurrentTimeout } = timeoutStore;
  const [todayNum, setTodayNum] = useState(randomArray(learningsContents.learn_dialog.length, learningsContents.review.step2))

  const dialogueLength = todaysKorean? learningsContents.review.step2 - 1 : learningsContents.learn_dialog.length - 1;
  const startgrammar = (lastContent.grammar -1) >= 0 && !todaysKorean ? (lastContent.grammar - 1) : (0)
  const [dialogIdx, setDialogIdx] = useState(startgrammar);
  const [currentGrammar, setCurrentGrammar] = useState(todaysKorean ? todayNum[startgrammar] : startgrammar);
  
  const [autoAudio, setAutoAudio] = useState();
  const [slideIndex, setSlideIndex] = useState(0);
  const [currentRole, setcurrentRole] = useState(1);
  const [currentMode, setCurrentMode] = useState("learning");
  const [isRoleListView, setIsRoleListView] = useState(false);
  const history = useHistory();

  const { sequences, role } = learningsContents.learn_dialog[currentGrammar];
  const { isPopupOpen, getIsLoading, recordBool, recordToggle, setPopup, setIdToRecord, retry, getAudioUrl, setRecord, setEndRecord, getRecord } = recorderStore;
  const { currentRecord, score, scoreObject, getRecordScore, isMicConnected, setMicState, getMicState } = recorderStore;
  const { setCurrentAudio } = audioStore;
  const { setEndTime, getBadge, setBadge } = learningStore;
  const isLastBubble = slideIndex === sequences.length - 1;
  const CONTAINER_QUERY = ".dialogue-container #dialogue-scroll";
  const [audioUrlArray , setAudioUrlArray] = useState(new Array(sequences.length));
  const [scoreView, setScoreView] = useState(new Array(sequences.length));
  const [isBadge , setIsbadge] = useState(new Array(sequences.length).fill(false));
  const [isShowBadge, setIsShowBadge] = useState(false);

  const endTime = todaysKorean ? null : setEndTime;

  const [isCoachView, setIsCoachMarkView] = useState(true);
  const viewDialogCoMark = Boolean(localStorage?.getItem("hide-dialog-coach-mark"));
  const isCoachMarkView = isCoachView && !todaysKorean && !viewDialogCoMark;

  const currentScript = excludeBlock(sequences[slideIndex].message);
  const isCurrentRole = sequences[slideIndex].role === currentRole;
  const currentId = sequences[slideIndex].id;

  const [audioPlayIns, setAudioPlayIns] = useState(new AudioPlay());

  if(!(fromHome || todaysKorean)){
    console.log("Only from Home page");
    history.push("/");
  }
  
  const initData = () => {
    setSlideIndex(0);
    setPopup(false);
    setAudioUrlArray(new Array(sequences.length))
    setScoreView(new Array(sequences.length))
    if(sequences.length === 1){
      setRecord(true);
      recordSet();
    }
  };

  const changeRole = (newRole) => {
    const idx = role.findIndex( r=> r===newRole);
    if(currentRole)
      setcurrentRole(idx + 1);
    setIsRoleListView(false);
    initData();
  };

  const nextGrammar = () => {
    if(todaysKorean){
      setCurrentGrammar(todayNum[dialogIdx+1]);
    }else{
      setCurrentGrammar(currentGrammar + 1);
    }
    setIsbadge(new Array(sequences.length).fill(false))
    setDialogIdx(dialogIdx+1)
    setcurrentRole(1);
    initData();
  };

  const scrollByBubble=()=>{
    const doLocal = document.querySelector(CONTAINER_QUERY)?.offsetTop;
    const local = document.querySelectorAll(".bubble-box")[slideIndex]?.offsetTop;
    document.querySelector(CONTAINER_QUERY)?.scrollTo({
      left: 0,
      top: (local - doLocal),
      behavior: 'smooth'
    });
    // document.querySelector(CONTAINER_QUERY)?.scrollTo(0,(local - doLocal));
  }

  const addNextGrammar =()=>{
    setPopup(false);
    setSlideIndex(slideIndex + 1);

    if(slideIndex) setTimeout(scrollByBubble, 100);
  }

  const onNextBtnClick = () => {
    setPopup(false);
    if (dialogIdx < dialogueLength)
      nextGrammar();
    else{
      if(todaysKorean){
        // history.push({pathname: "/review/result", learned: `${learningsContents.reviwe.step2}`});
        history.push("/review/result");
      }else{
        history.push("/learning/result");
      }
      setSlideIndex(0);
    }
  }

  const getUrlArray = () =>{
    if (currentRole === sequences[slideIndex].role)
      audioUrlArray[slideIndex] = getAudioUrl();
		setAudioUrlArray(audioUrlArray);
  }

  const setNextScore =()=>{
    // sub score view
    const currentScore = getRecordScore();
    if (currentScore === "연결안됨"){
      setMicState(false);
    }else{
      setMicState(true);
    }
    if(currentScore ==="None" || currentScore === "연결안됨"){
      scoreView[slideIndex] = "";
      setScoreView(scoreView);
    }
    else {
      scoreView[slideIndex] = currentScore
      setScoreView(scoreView);
    }
  }
  
  const setBadgeCount = () =>{
    console.log(isBadge, slideIndex)
    if(!isBadge[slideIndex]){
      console.log("==================================================================?");
      isBadge[slideIndex] = true;
      setIsbadge(isBadge);
      setBadge(getBadge() + 1);
    }
  }

  const autoAudioHandler =(audioPlay)=>{
    const audio = audioPlay;
    audio.play();
    setAutoAudio(audio);
  }

  const autoNext = (retryCount)=>{
    getUrlArray();
    setNextScore();
    const cRecord = getRecord();
    console.log(retryCount, "======================================");
    if(!retryCount){
      setBadgeCount()
    }
    setIsShowBadge(true)
    if(!isLastBubble || (retryCount && cRecord)){
      console.log("AUTO AUDIO PLAY")
      const tempTimeout = setTimeout(()=>{
        if(retryCount){
          setPopup(false);
          // autoAudioHandler(new AudioPlay(
					// 	'/data/' + sequences[slideIndex].audio_url,
					// 	endAudio,
					// 	setCurrentAudio,
					// ));
          audioPlayIns.setInit(
						'/data/' + sequences[slideIndex].audio_url,
						endAudio,
						setCurrentAudio,
					)
          autoAudioHandler(audioPlayIns);
        }else{
          addNextGrammar();
        }
      },2000)
      setCurrentTimeout(tempTimeout);
    }else if(isLastBubble){
      const tempTimeout = setTimeout(()=>{
        setIsShowBadge(false);
      },2000)
      setCurrentTimeout(tempTimeout);
    }
  }

  const endAudio = () =>{
    if(getRecordScore() === "연결안됨"){
      setMicState(false);
    }
    if(sequences[slideIndex].role === currentRole){
      recordToggle(currentScript, endTime);
    }else{
      if(isLastBubble){
        setPopup(true);
      }else{
        addNextGrammar();
      }
    }
  }

  const confirmMicConneced =()=>{
    setMicState(true);
    recordSet();
  }

  const playOneDialogueCycle = (idx) =>{
    const lastBubble = idx > sequences.length - 1;
    if (lastBubble){
      console.log("재생완료");
      setCurrentMode("learning");
      setRecord(false);
      setPopup(true);
    } else {
      setSlideIndex(idx);
      if(slideIndex) scrollByBubble();
      // autoAudioHandler(
      //   new AudioPlay(audioUrlArray[idx]? audioUrlArray[idx] : "/data/" + sequences[idx].audio_url,
      //   () => playOneDialogueCycle(idx + 1),
      //   setCurrentAudio)
      // );
      
      audioPlayIns.setInit(audioUrlArray[idx]? audioUrlArray[idx] : "/data/" + sequences[idx].audio_url,
      () => playOneDialogueCycle(idx + 1),
      setCurrentAudio)
      autoAudioHandler(audioPlayIns);
    }
  }

  const recordSet = () =>{
    setIdToRecord(currentUser.id, currentId,currentScript,2,isCurrentRole);
    if(currentMode === "learning"){
      setEndRecord(autoNext);
      // autoAudioHandler(new AudioPlay(
			// 	'/data/' + sequences[slideIndex].audio_url,
			// 	endAudio,
			// 	setCurrentAudio,
			// ));
      audioPlayIns.setInit(
				'/data/' + sequences[slideIndex].audio_url,
				endAudio,
				setCurrentAudio,
			)
      autoAudioHandler(audioPlayIns);
    }
  }

  const bubbleClick = (idx) => {
    if(recordBool === "unrecord" && isLastBubble && isPopupOpen && !(retry && currentRecord)){
      // autoAudioHandler(new AudioPlay(
      //   '/data/' + sequences[idx].audio_url,
      //   ()=>console.log("음성만 실행"),
      //   setCurrentAudio,
      // ));
      audioPlayIns.setInit(
        '/data/' + sequences[idx].audio_url,
        ()=>console.log("음성만 실행"),
        setCurrentAudio,
      )
      autoAudioHandler(audioPlayIns);
    }
  }

  useEffect(()=>{
    initData();
  },[])

  useEffect(() =>{
    setIsShowBadge(isPopupOpen)
  },[isPopupOpen])

  // useEffect(()=>{
  //   console.log("---------------------------------",isMicConnected, getMicState(), getRecordScore());
  //   const currentScore = getRecordScore();
  //   // if (currentScore === "연결안됨"){
  //   //   setMicState(false);
  //   // }else{
  //   //   setMicState(true);
  //   // }
  // },[scoreView])
  useEffect(() => {
    console.log("currentGrammar : " , currentGrammar,"===========================================")
    if(!todaysKorean){
      setLastContents(currentUser.id,currentId, getBadge() ,setEndTime())
    }
    recordSet()
  }, [currentGrammar]);
  
  useEffect(() => {
    if (isCoachMarkView || !getMicState()) return;
    recordSet()
  }, [slideIndex]);

  useEffect(() => {
    if (isCoachMarkView)return;
    else {
      localStorage.setItem("hide-dialog-coach-mark", true);
    }
  }, [isCoachMarkView]);

  

  console.log("RECORD BOOL", recordBool);
  console.log(isMicConnected, getMicState());

  const getRoleChangeList =()=>{
    return role.filter((ro, i) => i !== currentRole -1 )
  }

  return (
    <div className="dialogue-container">
      <CoachMark on={isCoachMarkView} onMarkClick={()=>{setIsCoachMarkView(false); recordSet();}}>
        <img className="coach-sentence" src={dialogueCoachMark} style={{display: isCoachMarkView? "": "none"}}/>
      </CoachMark>
      <Pagination className="pagination" mode="pointer" total={learningsContents.learn_dialog.length} current={currentGrammar+1} />
      <div id="dialogue-scroll" className="top  common-scroll-white" style={{visibility: isCoachMarkView? "hidden": "visible"}}>
        <BubbleView
          contents={learningsContents.learn_dialog[currentGrammar]}
          currentRole={currentRole}
          slideIndex={slideIndex}
          onClick={bubbleClick}
          scoreView={scoreView}
          mode={recordBool === "unrecord" && isLastBubble && isPopupOpen && !(retry && currentRecord)? "result" : ""}
        />
      </div>
      <div className="widget-container bottom" style={{ display: recordBool === "unrecord"? "none" : ""}}>
        <ToggleBtn
          className="widget record"
          on={recordBool !== "unrecord" && isMicConnected}
          onClick={() => { recordToggle(currentScript, endTime); autoAudio.stop(); }}
        >
          <img src={recordBool !== "unrecord"? recorderBtn : ""} alt=""/>
          {recordBool !== "unrecord"? <RecordingDots /> : null}
        </ToggleBtn>
      </div>
      <LoadingIndicator className="loading-indicator" isLoading={getIsLoading()}/>
      <div className="role-list-bg dimed-bg fadein" style={{display: isRoleListView? "" : "none"}}>
        <div className="role-list">
          <img className="back-btn" src={XBtn} alt="" onClick={()=>setIsRoleListView(false)}/>
          {getRoleChangeList().map((roleItem,i)=>(
            <ToggleBtn 
              className="role-list-item pop-btn-frame" 
              key={"role-item", i}
              onClick={()=>changeRole(roleItem)}
            >
              {roleItem.name}
            </ToggleBtn>
          ))}
        </div>
      </div>
      {isPopupOpen ? (
        (retry && currentRecord) ? null : (
          <div className="set-bottom">
            {isLastBubble? (
              <>
                <img className="voca-widget" alt="" src={ListeningBtn} onClick={()=>{setSlideIndex(0); setPopup(false); setCurrentMode("listen"); playOneDialogueCycle(0);}} />
                <img className="retry voca-widget" alt="" src={RetryBtn} onClick={initData} />
                <ToggleBtn className="btn-frame small-font" onClick={()=> setIsRoleListView(true)}>역할 변경</ToggleBtn>
                <ToggleBtn className={`btn-frame small-font`} onClick={onNextBtnClick}>
                  {dialogIdx < dialogueLength? "다음 대화" : "결과 보기"}
                </ToggleBtn>
              </>
            ): null}
          </div>
        )
      ) : null}
      {isPopupOpen && currentRecord && isShowBadge ? (
        retry?(
          <>
            <div className="retry-panel absolute-center"> 다시 말해보세요 </div>
            <div className="badge absolute-center bg-center" style={{ backgroundImage: `url(${RedScoreBadge})` }}>
              {score}
              {/* {scoreObject.scoreNum} */}
            </div>
          </>
        ):(
          <div className="badge absolute-center bg-center" style={{ backgroundImage: `url(${ScoreBadge})` }}>
            {score}
          </div>
        )
      ): null}
      <MicCheckPopup on={!isMicConnected} onYesBtnClick={confirmMicConneced}/>
    </div>
  );
}

export default observer(Dialogue);
