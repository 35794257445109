import React from "react";
import "./Login.less";

import main_title from "@images/title.png";
import homg_bg from "@images/background/home_bg.png";
import ToggleBtn from "common/button/ToggleBtn";

const Login =()=> {
  const urls = [
    { name: "Mk", path: "https://www.mastertopik.com"},
    { name: "MT", path: "https://www.mastertopik.com"},
    { name: "Kl", path: "https://www.mastertopik.com"},
  ]
  const sendRedirect =(newHref)=>{
    window.location.href = newHref;
  }

  return (
    <div className="login-container bg-center" style={{backgroundImage : `url(${homg_bg})`}}>
      <img className="main-title" src={main_title} alt="no Title"/>
      <div className="link-btns-wrapper">
        {urls.map((url, i)=>(
          <ToggleBtn className="link-btn" key={`link-${i}`} onClick={()=> sendRedirect(url.path)}>{url.name}</ToggleBtn>
        ))}
      </div>
    </div>
  );
}

export default Login;
