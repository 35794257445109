import React from "react";
import { Radar } from "@ant-design/charts";

const RadarReport = (props) => {
  const { partData } = props;
  const data = [
    { item: "유창성", score: partData.ko_PITCH }, { item: "음절", score: partData.ko_PHONOLOGY },
    { item: "강세", score: partData.ko_PITCH }, { item: "속도", score: partData.ko_RATE },
    { item: "발성", score: partData.ko_SEGMENT },
  ];
  const config = {
    data, xField: "item", yField: "score",
    meta: { score: { alias: "점수", min: 0, max: 100 } },
    xAxis: { line: null, tickLine: null, grid: { line: { style: { lineDash: null } } } },
    yAxis: { line: null, tickLine: null, grid: { line: { type: "line", style: { lineDash: null } } } },
    area: {}, point: {},
  };
  return <Radar {...config} className="radar" color="rgb(8, 193, 187)" style={{ width: "80%", height: "320px"}}/>;
};

export default RadarReport;