// import { resolve } from "node:path";
import { API_BASE_URL, ACCESS_TOKEN } from '../constants';

const request = options => {
	const headers = new Headers({
		'Content-Type': 'application/json',
	});

	if (localStorage.getItem(ACCESS_TOKEN)) {
		headers.append(
			'Authorization',
			`Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
		);
	}

	const defaults = { headers };
	options = { ...defaults, ...options, timeout: 30000 };

	return fetch(options.url, options).then(response =>
		response.json().then(json => {
			if (!response.ok) {
				return Promise.reject(json);
			}
			return json;
		}),
	);
};

export function checkVisangUser(userIdx, userID, usersite) {
	let userPlatform = '';
	console.log('usersite : ' + usersite);

	// 운영배포시 아래 스위치문으로
	switch (usersite) {
		case 'MT':
			userPlatform = 'https://renewal.mastertopik.com/api/ai/member.asp';
			break;
		case 'MK':
			userPlatform = 'https://ai.masterkorea.com:8091/connect/return';
			break;
		case 'MV':
			userPlatform = 'https://ai.masterkorea.com:8091/connect/return';
			break;
		case 'MTT':
			userPlatform = 'https://www.mastertopik.com/api/v1/connect/aiSpeak';
			break;
		case 'MKT':
			userPlatform =
				'https://academy.masterk1.com/api/v1/com/connect/aiMemberCheck';
			break;
		case 'MVT':
			userPlatform =
				'https://www.masterkorean.vn/local/audio_ai/partner_callback.php';
			break;
		case 'EDU':
			userPlatform = 'https://ai.masterkorea.com:8091/connect/return';
			break;
		default:
			userPlatform = 'https://ai.masterkorea.com:8091/connect/return';
			break;
	}

	// userPlatform = 'https://localhost:8091/connect/return'; // 로컬 테스트용 230309
	// userPlatform = "https://192.168.0.78:8091/connect/return"
	//userPlatform = "https://renewal.mastertopik.com/api/ai/member.asp"    // 운영서버 용

	const headers = new Headers({
		'Content-Type': 'application/x-www-form-urlencoded',
	});
	const postParameter = {
		userIdx: userIdx,
		userID: userID,
	};
	const xparameter = 'userIdx=' + userIdx + '&userID=' + userID;
	console.log('userPlatform : ' + userPlatform);
	console.log('postParameter : ', postParameter);
	let options = {
		method: 'POST',
		body: xparameter, //JSON.stringify(postParameter),
	};

	const defaults = { headers };
	options = { ...defaults, ...options };
	console.log(options);
	return fetch(userPlatform, options).then(response =>
		response.json().then(json => {
			if (!response.ok) {
				return Promise.reject(json);
			}
			return json;
		}),
	);
}

export function login(loginRequest) {
	return request({
		url: `${API_BASE_URL}/auth/signin`,
		method: 'POST',
		body: JSON.stringify(loginRequest),
	});
}

export function signup(signupRequest) {
	// console.log('signupRequest', signupRequest);
	return request({
		url: `${API_BASE_URL}/auth/signup`,
		method: 'POST',
		body: JSON.stringify(signupRequest),
	});
}

// 230614 순철 추가
export function termsSignup(signupRequest) {
	// console.log('termsSignup', signupRequest);
	return request({
		url: `${API_BASE_URL}/auth/userSign?userId=${signupRequest.userId}&studentName=${signupRequest.studentName}&guardianName=${signupRequest.guardianName}`,
		method: 'POST',
	});
}

export function setAgreeTerms(username) {
	return request({
		url: `${API_BASE_URL}/auth/agreeterms?username=${username}`,
		method: 'GET',
	});
}

export function checkUsernameAvailability(username) {
	return request({
		url: `${API_BASE_URL}/user/checkUsernameAvailability?username=${username}`,
		method: 'GET',
	});
}

export function checkEmailAvailability(email) {
	return request({
		url: `${API_BASE_URL}/user/checkEmailAvailability?email=${email}`,
		method: 'GET',
	});
}

export function setTestResult(resultData) {
	console.log(resultData);
	return request({
		url: `${API_BASE_URL}/testResult/log`,
		method: 'POST',
		body: JSON.stringify(resultData),
	});
}

export function getTestResult(userid) {
	console.log(userid);
	return request({
		url: `${API_BASE_URL}/testResult/${userid}`,
		method: 'GET',
	});
}

export function getLastContents(userid) {
	console.log(userid);
	return request({
		url: `${API_BASE_URL}/lastcontents/view?userId=${userid}`,
		method: 'GET',
	});
}

export function removeLastContent(userid) {
	return request({
		url: `${API_BASE_URL}/lastcontents/remove?userId=${userid}`,
		method: 'GET',
	});
}
export function setLastContents(userId, contentId, badge, learning_time) {
	console.log(contentId);
	return request({
		url: `${API_BASE_URL}/lastcontents/set?userId=${userId}&contentId=${contentId}&badge=${badge}&learningTime=${learning_time}`,
		method: 'GET',
	});
}

export function setTotalLearningTime(userId, learning_time) {
	console.log(learning_time);
	return request({
		url: `${API_BASE_URL}/testResult/learn?userId=${userId}&learningTime=${learning_time}`,
		method: 'GET',
	});
}

export function getTotalLearningTime(userId) {
	console.log('========= 학습현황 == userId : ' + userId);

	/*
  // 기존 230307
  return request({
    url: `${API_BASE_URL}/testResult/learntime?userId=${userId}`,
    method:"GET",
  })
  //*/

	// 수정 230307
	const jdata = {
		userId: userId,
	};
	return request({
		url: `${API_BASE_URL}/testResult/learntime_new`,
		method: 'POST',
		body: JSON.stringify(jdata),
	});
}

export function setLearnedContent(userId, lessonId) {
	return request({
		url: `${API_BASE_URL}/testResult/learned?userId=${userId}&lessonId=${lessonId}`,
		method: 'GET',
	});
}

export function getLearnedContent(userId) {
	return request({
		url: `${API_BASE_URL}/testResult/learnedContent?userId=${userId}`,
		method: 'GET',
	});
}

export function getAudioBase(filename) {
	/*
  // 기존 
  console.log(filename)
  return request({
    url: `${API_BASE_URL}/audio?filename=${filename}`,
    method:"GET",
  })
  */

	// 수정 230309
	const jdata = {
		audio_file: filename,
	};
	return request({
		url: `${API_BASE_URL}/audio_new`,
		method: 'POST',
		body: JSON.stringify(jdata),
	});
}

export function setReview(userId, dateData) {
	console.log(dateData);
	return request({
		url: `${API_BASE_URL}/review/set?userId=${userId}&reviewDate=${dateData}`,
		method: 'GET',
	});
}

export function getReview(userId) {
	return request({
		url: `${API_BASE_URL}/review/view?userId=${userId}`,
		method: 'GET',
	});
}

export function getAiServerResult(audiodata, audioscript) {
	const jdata = {
		data: audiodata,
		script: audioscript,
	};
	console.log(jdata.script);
	return request({
		url: `${API_BASE_URL}/ai`,
		method: 'POST',
		body: JSON.stringify(jdata),
	});
}

export function getCurrentUser() {
	if (!localStorage.getItem(ACCESS_TOKEN)) {
		return Promise.reject('No access token set.');
	}

	return request({
		url: `${API_BASE_URL}/user/me`,
		method: 'GET',
	});
}
