import React, { useEffect, useState } from "react";

const RecordingDots = ({ total, perSec }) => {
	const [dotIdx, setDotIdx] = useState(0);
	const maxValue = total - 1 || 4;
	const sec = 1000 * perSec || 1000;

	useEffect(() => {
		if (dotIdx > maxValue) setDotIdx(0);
		else {
			setTimeout(() => {
				setDotIdx(dotIdx + 1);
			}, sec);
		}
		return () => clearTimeout();
	}, [dotIdx]);

	useEffect(() => {
		return () => setDotIdx(0);
	}, []);

	// const [value, setVale] = useState(1);
	// const [isIncrease, setIsIncrease] = useState(false);
	// useEffect(()=>{
	// 	function tick(){
	// 		return setTimeOut(()=>setValue(value+1),1000);
	// 	}
	// 	if(!isIncrease) return undefined;
	// 	tick();
	// 	return ()=>clearTimeOut(tick);
	// },[value,isIncrease])

	return (
		<div className="recording-dots-container">
			{Array.from(new Array(total || 5)).map((v, i) => (
				<div
					key={"dots-" + i}
					className={`recording-dot-wrap ${dotIdx >= i ? 'recording-dot-wrap-active' : ''}`}
				>
					<span />
				</div>
			))}
		</div>
	);
};

export default RecordingDots;
