import React, { useEffect } from "react";
import { Route, withRouter, Switch, useHistory, useLocation } from "react-router-dom";
import "./Learning.less";
import { observer } from "mobx-react";
import SentenceCard from "./SentenceCard";
import Dialogue from "./Dialogue";
import VideoRolePlay from "./VideoRolePlay";
import Result from "./Result";
import { useStore } from "store/Context";
import { notification } from "antd";

// 학습 컨텐츠의 기본 화면
const Learning = ({ currentUser }) => {
  const { homeStore, contentsStore } = useStore();
  const {contentsData} = contentsStore;
  const { selectedLevel, selectedPart } = homeStore;
  const history = useHistory();
  const dataPart = contentsData[selectedLevel]?.parts[selectedPart] || contentsData[0].parts[0];
  const location = useLocation();
  const isInVideoRolePage = location.pathname === "/learning/video";
  const lvImgUrl ="/data";

  console.log("--level--: ", selectedLevel);
  console.log("--part---: ", selectedPart);

  // check user
  if (!currentUser) {
    history.push("/login");
    notification.info({
      message: "Visang GEO App",
      description: "Please login",
    });
    return <></>;
  }

  useEffect(()=>{
    console.log("check :",currentUser);
    // 선택한 과가 없을 경우 메인화면으로 이동
    if (selectedPart === -1) {
      history.push("/");
      notification.warning({
        message: 'Visang GEO App',
        description: 'Sorry! Something went wrong. Please try again!'
      });
    }
  },[])

  const getPartData = () => { // type: num or null
    const level = selectedLevel;
    let returnData = null;
    contentsData.forEach(data => {
      if(data.idx === level){
        returnData = data
      }
    })
    return returnData?.parts[selectedPart] || returnData.parts[0];
  }

  return (
    <div className="learning-contents-container">
      <div className="learning-bg">
        <img
          style={{filter: isInVideoRolePage? "blur(10px) contrast(0.5)": "blur(15px) brightness(0.5)"}}
          src={`${lvImgUrl}/${dataPart.img_url}`} 
          alt="no background image"
        />
      </div>
      <div className="learning-header">
        {`${dataPart.id}과 ${dataPart.title}`}
      </div>
      <div id="learning-contents">
        <img
          className="inner-bg"
          style={{filter: isInVideoRolePage? "blur(10px) contrast(0.5)": "brightness(0.5)"}}
          src={`${lvImgUrl}/${dataPart.img_url}`} 
          alt="no background image"
        />
        <Switch>
          <Route exact path="/learning/card" render={() => <SentenceCard currentUser={currentUser} learningsContents={getPartData()} />}/>
          <Route exact path="/learning/dialogue" render={() => <Dialogue currentUser={currentUser} learningsContents={getPartData()} />}/>
          <Route exact path="/learning/video" render={() => <VideoRolePlay currentUser={currentUser} learningsContents={getPartData()} />}/>
          <Route path="/learning/result" render={() => <Result currentUser={currentUser} learningsContents={getPartData()} />}/>
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(observer(Learning));
