import * as React from 'react';

function _sentence2jsx(
	lines: Array<string>,
	blockClass: string | null = 'block',
	splitClass?: string,
): JSX.Element[] {
	const keyObj = { key: 0 };
	const ret: JSX.Element[] = [];
	let container: JSX.Element[] = [];

	if (!blockClass) return ret; // 블록 처리가 필요한 경우
	lines.forEach((line, idx) => {
		let lastIndex = 0;
		container = _splitSpace(line, keyObj, splitClass);
		const tokens = line.split(/\s/g);
		let tarr: React.ReactNode[] = [];
		let blockMode = 0;

		// 블록이 없는 문장은 제외
		if (/\{(.*?)\}/g.exec(line)) {
			tokens.map((token, i) => {
				const blockPattern = new RegExp(/\{(.*?)\}/g);
				const frontBPattern = new RegExp(/\{(.*)?/g);
				// const frontBPattern = new RegExp(/\{(.*?)+/g);
				const backBPattern = new RegExp(/(.*)?\}/g);
				// const backBPattern = new RegExp(/(.*?)\}/g);
				let braceNum = 2;

				let bToken = blockPattern.exec(token);
				if (!bToken) {
					bToken = frontBPattern.exec(token);
					braceNum = 1;
				}
				if (!bToken) {
					bToken = backBPattern.exec(token);
					braceNum = 1;
				}

				// ---- 블록이 있는 토큰만 통과.
				if (bToken) {
					// 블록에 감싸진 모든 토큰을 처리하기 위한 조건
					blockMode ? (blockMode = 0) : (blockMode = 1);
					lastIndex = bToken.index + bToken[1].length + braceNum;

					const frontToken = token.substring(0, bToken.index); // ""{가}다
					if (frontToken) tarr.push(<span key={keyObj.key++}>{frontToken}</span>);

					const blockToken = (
						<span className={blockClass} key={keyObj.key++}>
							{' '}
							{bToken[1]}{' '}
						</span>
					); // "{가}"다
					tarr.push(blockToken);

					const backToken = token.substring(lastIndex); // {가}"다"
					if (backToken) tarr.push(<span key={keyObj.key++}>{backToken}</span>);

					//console.log(tarr);

					const newLine = (
						<span key={keyObj.key++} className={splitClass}>
							{tarr.map(t => t)}
						</span>
					);
					container[i] = newLine;
					tarr = [];
				} else if (blockMode) {
					const newLine = (
						<span key={keyObj.key++} className={splitClass}>
							<span className={blockClass} key={keyObj.key++}>
								{token}
							</span>
						</span>
					);
					container[i] = newLine;
				}
			});
		}
		ret[idx] = <React.Fragment key={keyObj.key++}>{container}</React.Fragment>;
	});
	return ret;
}

function _splitSpace(
	sentence: string,
	keyObj: { key: number },
	splitClass?: string,
): JSX.Element[] {
	const tokens = sentence.split(/\s/g);
	const pattern = new RegExp(/[\.\!\?\s]/g);
	const elements: JSX.Element[] = [];
	for (let i = 0; i < tokens.length; i++) {
		const token = tokens[i];
		if (token === '') continue;

		const nodes: React.ReactNode[] = [];
		let lastIdx = 0;
		let sTmp = '';

		let result = pattern.exec(token);
		while (result) {
			if (result.index > lastIdx) {
				sTmp = token.substring(lastIdx, result.index);
				nodes.push(<span key={keyObj.key++}>{sTmp}</span>);
			}
			sTmp = result[0];
			nodes.push(sTmp);

			lastIdx = pattern.lastIndex;
			result = pattern.exec(token);
		}
		if (lastIdx < token.length) {
			sTmp = token.substring(lastIdx);
			nodes.push(<span key={keyObj.key++}>{sTmp}</span>);
		}
		elements.push(
			<span key={keyObj.key++} className={splitClass}>
				{nodes.map(node => node)}
			</span>,
		);
	}
	return elements;
}

// 블록("{}")이 들어간 문구 처리
export const getJSX = (text: string): JSX.Element => {
	const lines =
		text
			?.replace(/\s\s+/gi, ' ')
			.replace(/<\s*br\s*\/*\s*>/gi, '<br>')
			.split('<br>') || [];
	const elements = _sentence2jsx(lines, 'block', 'word');
	return (
		<>
			{elements.map((el, idx) => {
				if (idx === elements.length - 1) return el;
				else
					return (
						<React.Fragment key={idx}>
							{el}
							<br />
						</React.Fragment>
					);
			})}
		</>
	);
};

// 줄바꿈 처리
export const lineFeed = (text: string): JSX.Element => {
	const lines =
		text
			?.replace(/\s\s+/gi, ' ')
			.replace(/<\s*br\s*\/*\s*>/gi, '<br>')
			.split('<br>') || [];
	return (
		<>
			{lines.map((el, idx) => {
				if (idx === lines.length - 1) return el;
				else
					return (
						<React.Fragment key={idx}>
							{el}
							<br />
						</React.Fragment>
					);
			})}
		</>
	);
};

// 중괄호 제거
export const excludeBlock = (text: string): string => {
	const lines =
		text
			?.replace(/\s\s+/gi, ' ')
			.replace(/<\s*br\s*\/*\s*>/gi, '<br>')
			.split('<br>') || [];

	const newLine = lines.map(line => {
		const pattern = /[{}]/gi; //(임시)  /[\{(.*?)\}]/gi
		if (pattern.test(line)) return line.replace(pattern, '');
		return line;
	});
	// console.log(newLine);

	return newLine.join('');
};

// 중괄호 제거 + 줄바꿈
export const blockLineFeed = (text: string): (string | JSX.Element)[] => {
	const lines =
		text
			?.replace(/\s\s+/gi, ' ')
			.replace(/<\s*br\s*\/*\s*>/gi, '<br>')
			.split('<br>') || [];
	const isLastList = (idx: number) => idx === lines.length - 1;

	const newLine = lines.map((line, idx) => {
		const pattern = /[{}]/gi; //(임시)  /[\{(.*?)\}]/gi
		if (pattern.test(line))
			return (
				<React.Fragment key={idx}>
					{line.replace(pattern, '')}
					{isLastList(idx) ? '' : <br />}
				</React.Fragment>
			);
		return (
			<React.Fragment key={idx}>
				{line}
				{isLastList(idx) ? '' : <br />}
			</React.Fragment>
		);
	});

	return newLine;
};
