import React from "react";
import Popup from "common/Popup";
import ToggleBtn from "common/button/ToggleBtn";
import { useHistory } from "react-router";
import { useStore } from "store/Context";

const MicCheckPopup = ({on, onYesBtnClick})=> {
  const history = useHistory();
  const { recorderStore } = useStore();
  const { turnRecorderOff } = recorderStore;

  const onNoBtnClick =()=>{
    history.push("/");
    turnRecorderOff();
  }

  return(
    <Popup on={on}>
      <div className="popup-name">마이크를 확인하세요.</div>
      <div className="button-wrap">
        <ToggleBtn onClick={onNoBtnClick}>나가기</ToggleBtn>
        <ToggleBtn onClick={onYesBtnClick}>계속하기</ToggleBtn>
      </div>
    </Popup>
  )
}

export default MicCheckPopup;