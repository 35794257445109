import React, { Fragment, useEffect, useRef, useState } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import "./VideoRolePlay.less";
import { observer, inject } from "mobx-react";
import ToggleBtn from "@common/button/ToggleBtn";
import LoadingIndicator from "common/LoadingIndicator";
// import AudioBtn from "@common/button/AudioBtn";
import { useStore } from "../../store/Context";
import AudioPlay from "common/AudioPlay"
import RecordingDots from "common/RecordingDots";
import { getJSX, excludeBlock } from "util/Parser";
import { randomArray } from "common/Reviwe";
import CoachMark from "components/coach_mark/CoachMark";
import { Pagination } from 'antd-mobile';
import MicCheckPopup from "@components/popup/MicCheckPopup";

import ListeningBtn from "@images/button/listening_btn.png";
import VideoPlayBtn from "@images/button/video_play_btn.png";
import PauseBtn from "@images/button/pauseBtn.png";
import RetryBtn from "@images/button/retry_btn.png";
import RedScoreBadge from "@images/badge/red_score_badge.png";
import ScoreBadge from "@images/badge/common_score_badge.png";
import XBtn from "@images/button/x_btn.png";
import videoCoachMark from "@images/coach_mark/coach_video1.png";
import videoCoachMark2 from "@images/coach_mark/coach_video2.png";
import speakerOnBtn from "@images/button/stop_recording_btn.png";
import recorderBtn from "@images/button/recorder_btn.png";
import { setLastContents } from "util/APIUtils";

const LISTENING = 1;
const LEARNING = 2;
const RESULT = 3;
const REPLAY = 4;
const RECORD = 5;
const DUBBING = 6;
const CLICK = 7;

const VideoRolePlay = (props) => {
  const location = useLocation();
  const history = useHistory();
  const fromHome = location.from === "home";
  const todaysKorean = location.from === "TodaysKorean";
  const ref = useRef(null);
  const { currentUser, learningsContents } = props;

  
  const [slideIndex, setSlideIndex] = useState(-1)
  // const [currentMode, setCurrentMode] = useState(LEARNING); //바로 학습으로
  const [currentMode, setCurrentMode] = useState(LISTENING); // 듣기 부터
  const [currentRole, setcurrentRole] = useState(1);
  
  const [nowPlaying, setNowPlaying] = useState(false);
  const [endedPlay, setEndedPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [showControl, setShowControl] = useState(true);
  const [isRoleListView, setIsRoleListView] = useState(false);
  
  const { recorderStore, learningStore, audioStore, globalStateStore, timeoutStore } = useStore();
  const { setCurrentTimeout, stopTimeout } = timeoutStore;
  const { lastContent } = globalStateStore
  const { setCurrentAudio, getCurrentAudio } = audioStore;
  const { setEndTime, getBadge, setBadge } = learningStore;
  const {
    isPopupOpen,
    getIsLoading,
    getRecordBool,
    recordToggle,
    score,
    scoreObject, 
    setPopup,
    setIdToRecord,
    getRetry,
    setEndRecord,
    getAudioUrl,
    getRecordScore,
    isMicConnected,
    setMicState,
    getMicState
  } = recorderStore;

  const retry = getRetry();
  
  const [todayNum, setTodayNum] = useState(randomArray(learningsContents.learn_movie.length, learningsContents.review.step3))
  
  const videoRoleLength = todaysKorean? learningsContents.review.step3 - 1 : learningsContents.learn_movie.length - 1;
  const startgrammar = (lastContent.grammar -1) >= 0 && !todaysKorean ? (lastContent.grammar - 1) : (0)
  const [videoRoleIdx, setVideoRoleIdx] = useState(startgrammar);
  const [currentGrammar, setCurrentGrammar] = useState(todaysKorean ? todayNum[startgrammar] : startgrammar);
  
  const { scripts, video_url, role } = learningsContents.learn_movie[currentGrammar];
  const [audioUrlArray, setAudioUrlArray] = useState(new Array(scripts.length));
  const [scoreView, setScoreView] = useState(new Array(scripts.length));
  const [isBadge , setIsbadge] = useState(new Array(scripts.length).fill(false));

  const isLastBubble = slideIndex === scripts.length - 1;
  
  const currentScript = (slideIndex >= 0)? excludeBlock(scripts[slideIndex].script) : "";
  // const currentAudioTime = (scripts[slideIndex].end_time - scripts[slideIndex].start_time + 2) * 1000;
  let currentId = (slideIndex >= 0)? scripts[slideIndex].id : scripts[0].id;
  const isCurrentRole = (slideIndex >= 0)? scripts[slideIndex].role === currentRole : false;

  const CONTAINER_QUERY = ".video-roleplay-container #video-roleplay-scroll";
  
  const [isCoachView, setCoachView] = useState(true);
  const viewVideoCoMark = Boolean(localStorage?.getItem("hide-video-coach-mark"));
  const isCoachMarkView = isCoachView && !todaysKorean && !viewVideoCoMark;

  const [audioPlayIns, setAudioPlayIns] = useState(new AudioPlay());

  if(!(fromHome || todaysKorean)){
    console.log("Only from Home page");
    history.push("/");
  }

  // 다음 비디오
  const nextGrammar = () => {
    if(todaysKorean){
      setCurrentGrammar(todayNum[videoRoleIdx+1]);
    }else{
      setCurrentGrammar(currentGrammar + 1);
    }
    setIsbadge(new Array(scripts.length).fill(false))
    setVideoRoleIdx(videoRoleIdx+1)
    initData();
    setcurrentRole(1);
    setCurrentMode(LISTENING);
    
  };

  const scrollByBubble = (slideIndex) => {
    if (slideIndex > 0) {
      const dolocal = document.querySelector(CONTAINER_QUERY)?.offsetTop;
      const local = document.querySelectorAll(".bubble-box")[slideIndex]?.offsetTop;
      console.log("local : ", local);
      console.log("offset : ", (local - dolocal))
      if (local) {
        document.querySelector(CONTAINER_QUERY)?.scrollTo({
          left: 0,
          top: (local - dolocal - 130),
          behavior: 'smooth',
        });
      }
    }
  }

  const setCurrentIndex = (currentIndex) => {
    console.log("슬라이드 인덱스 셋!"+currentIndex);
    setSlideIndex(currentIndex);
    scrollByBubble(currentIndex);
  }

  // const totalTime = (ref && ref.current && ref.current.duration) || 0;
  const videoElement = ref && ref.current;
  const videoSrc = "/data/"+video_url;

  const recordStart = (currentIdx) => {
    if (videoElement && getRecordBool() === "unrecord") {
      const currentScript = excludeBlock(scripts[currentIdx].script);
      videoElement.pause();
      console.log("녹음시작==============================================================")
      console.log(getRecordScore(), isMicConnected, getMicState());
      setEndRecord(autoNext);
      recordToggle(currentScript, setEndTime);
    }
  }

  const replayStart = (currentIdx) => {
    videoElement.muted = true
    // const audio = new AudioPlay(audioUrlArray[currentIdx], () => { videoElement.play();}, setCurrentAudio)
    // audio.play();
    audioPlayIns.setInit(audioUrlArray[currentIdx], () => { videoElement.play();}, setCurrentAudio)
    audioPlayIns.play();
  }

  const getCurrentIndex = () => {
    if(!isMicConnected){
      const videoElement = ref && ref.current;
      videoElement?.pause();
      return;
    }
    if (currentMode < RECORD) {
      scripts.forEach((script, idx) => {
        if (currentTime >= script.start_time && currentTime < script.end_time) {
          setCurrentIndex(idx)
          if (script.role === currentRole) {
            if (currentMode === LEARNING) {
              setCurrentMode(RECORD)
            } else if (currentMode === REPLAY) {
              setCurrentMode(DUBBING)
              replayStart(idx);
            }
          } else {
            console.log("지금은 녹음 아님!")
          }
        }else if(currentTime === script.end_time){
          if (currentMode === RESULT) {
            skipListen();
            return;
          }
        }
        if(idx === scripts.length-1){
          if(currentTime >= script.end_time){
            if(currentMode === LISTENING || currentMode === RESULT){
              setCurrentIndex(idx)
            }
          }
        }
      });
    }
  }

  const workRecordPart = () => {
    if (currentMode === RECORD) {
      console.log("리트라이 녹음")
      setCurrentIndex(slideIndex);
      // const start_time = scripts[slideIndex].start_time;
      const end_time = (slideIndex >= 0)? scripts[slideIndex].end_time : 0;
      console.log("현재시간 : " + currentTime + "끝 시간 : " + end_time);
      if (currentTime > end_time) {
        recordStart(slideIndex)
      }
    } else if (currentMode === DUBBING) {
      console.log("더빙")
      setCurrentIndex(slideIndex);
      const end_time = (slideIndex >= 0)? scripts[slideIndex].end_time : 0;
      if (currentTime > end_time) {
        if (!getCurrentAudio().ended) {
          videoElement.pause();
          videoElement.currentTime = end_time
        } else {
          videoElement.muted = false;
          setCurrentMode(REPLAY)
        }
        //소리가 나오고 있는지 체크 
        //소리가 나오고 있다면 video pause
        //소리가 나오지 않는다면 currentMode(REPLAY)
      }
    } else if(currentMode === CLICK){
      console.log("클릭")
      const end_time = (slideIndex >= 0)? scripts[slideIndex].end_time : 0;
      console.log(end_time + "==============================================")
      console.log(slideIndex + "==============================================")
      if (videoElement.currentTime > end_time) {
        skipListen()
        setCurrentMode(RESULT)
      }
    }
  }

  const endPlay = (ended) => {
    console.log("ended : " + ended);
    if (ended && (!isCurrentRole || !retry || currentMode !== RECORD)) {
      console.log("영상 종료"+ isCurrentRole + " : " + retry + " : " + currentMode);
      if (currentMode !== LISTENING) {
        setCurrentMode(RESULT);
      }
      videoElement.muted = false
      setNowPlaying(false);
      setPopup(true);
    } else {
      console.log("변경?");
      setNowPlaying(true)
      setEndedPlay(false)
    }
  }

  // 동영상 시간 업데이트 함수
  const addTimeUpdate = () => {
    const observedVideoElement = ref && ref.current;
    if (observedVideoElement) {
      console.log("obsddreaf sout  : " + observedVideoElement.src+ "====================================");
      observedVideoElement.addEventListener("timeupdate", function () {
        if (observedVideoElement.ended) {
          console.log("종료 시간 ~~~~~~~~" + observedVideoElement.currentTime)
          setCurrentTime(observedVideoElement.currentTime);
          setEndedPlay(true);
        }
        setCurrentTime(parseFloat((observedVideoElement.currentTime).toFixed(2)));
      });
      if(isCoachMarkView) {
        observedVideoElement.pause();
      }else{
        setNowPlaying(true);
        setEndedPlay(false);
        observedVideoElement.play();
      }
    }
  };

  // play icon 클릭했을떄 실행되는 함수
  const onPlayIconClick = () => {
    if(getRecordBool() === "unrecord"){
      if (videoElement) {
        stopTimeout();
        if (nowPlaying) {
          setNowPlaying(false);
          if(currentMode===DUBBING){
            getCurrentAudio().pause()
          }
          videoElement.pause();
        } else {
          setPopup(false)
          setNowPlaying(true);
          setEndedPlay(false);
          if(currentMode === DUBBING && !getCurrentAudio().ended){
            getCurrentAudio().play()
          }
          videoElement.play();
        }
      }
    }
  };

  // control bar visible 관련 함수
  const handleControlVisible = () => {
    if(nowPlaying){  
      if (showControl) {
        const timeO = setTimeout(() => {
          setShowControl(false);
        }, 2000);
        setCurrentTimeout(timeO)
      }
    }else{
      stopTimeout();
      setShowControl(true);
    }
  };

  const setVideoIndex = (idx) => {
    if (videoElement) {
      // controller를 옮긴 시점에 currentTime이 최신화 되지 않아, 이를 위해 수정
      videoElement.currentTime = scripts[idx].start_time;
      //nowPlaying ? videoElement.play() : videoElement.pause();
    }
  }

  const onNextBtnClick = (index) => {
    if (videoElement) {
      console.log("next btn", "currentendTime : " , scripts[index].end_time);
      videoElement.currentTime = scripts[index].end_time
      videoElement.play();
      setPopup(false);
    }
  };

  const onNextClick = () => {
    setPopup(false);
    if(videoRoleIdx < videoRoleLength){
      nextGrammar();
    }else{
      if(todaysKorean){
        // history.push({pathname: "/review/result", learned: `${learningsContents.reviwe.step3}`});
        history.push("/review/result");
      }else{
        history.push("/learning/result");
      }
    }
  };

  const bubbleClick = (idx) => {
    if(getRecordBool() === "unrecord" && currentMode === RESULT){
      console.log("말풍선 클릭")
      setPopup(false)
      setEndedPlay(false)
      setVideoIndex(idx)
      setCurrentIndex(idx);
      setCurrentMode(CLICK);
      // console.log(currentMode) // result
      // if(currentMode === DUBBING || currentMode === REPLAY){
      //   videoElement.muted = false
      //   setCurrentMode(REPLAY)
      // }
      videoPlay();
    }
  }

  const onRetryRecord = () => {
    setPopup(false);
    setCurrentMode(RECORD);
    setVideoIndex(slideIndex);
    videoElement.play();
  }

  const setGrammar = () => {
    return scripts.map((val, idx) => {
      const isListening = currentMode === LISTENING;
      const isCurrentBubble = slideIndex === idx;
      const isRightSide = val.role === currentRole;
      const userTurn = val.role === currentRole;
      const isLearningTime = currentMode === RECORD || currentMode === LEARNING;
      const isAllFocusing = isCurrentBubble || !isLearningTime;
      const isScriptView = isLearningTime && isRightSide && isCurrentBubble && !endedPlay;
      const profilePath = "/data";
      return (        
        <div key={(val, idx)} className="bubble-box"
          style={{display: (idx > slideIndex)? "none": "" }}>
          {!isRightSide?
            <img
              className="profile-img"
              src={`${profilePath}/${role[val.role - 1].img_url}`}
              style={{opacity: isAllFocusing? 1: 0.4}}
            />
          : null}
          <div className="bubble-wrapper">
            <p className="person-name" style={{opacity: isAllFocusing? 1: 0.4}}>
              {isRightSide? "" : role[val.role - 1].name}
            </p>
            <div className={`bubble ${userTurn ? "right" : "left"}`} onClick={() => { bubbleClick(idx) }}>
              <div className={`script ${isAllFocusing ? "on" : ""} ${userTurn ? "user" : ""} ${isListening? "all": ""}`}>
                {isScriptView ? (val.keyword) : getJSX(val.script)}
              </div>
            </div>
            {scoreView[idx]?(
              <div 
                className={`sub-badge ${userTurn? "right" : "left"}`} 
                style={{display: scoreView[idx]? "" : "none", background: `no-repeat center / 100% url(${ScoreBadge})`}}>
                {scoreView[idx]}
              </div>
            ): null}
          </div>
        </div>
      );
    });
  };

  const initData = () => {
    setSlideIndex(-1);
    setCurrentTime(0);
    setPopup(false);
    scrollByBubble(0);
    setEndedPlay(false)
    setScoreView(new Array(scripts.length))
  };

  const videoPlay = () => {
    setNowPlaying(true);
    if(videoElement){
      videoElement.play();
    }
  }

  const changeRole = (newRole) => {
    const idx = role.findIndex( r=> r===newRole);
    if(currentRole)
      setcurrentRole(idx + 1);
    setCurrentMode(LEARNING);
    setIsRoleListView(false);
    setAudioUrlArray(new Array(scripts.length))
    initData();
    videoPlay();
  };

  const changeListen = () => {
    setCurrentMode(LEARNING);
    initData();
    videoPlay();
  }

  const skipListen = () => {
    videoElement.currentTime = videoElement.duration
    setCurrentTime(videoElement.duration)
  }

  const getUrlArray = () => {
    if(slideIndex >= 0){
      if (currentRole === scripts[slideIndex].role){
        audioUrlArray[slideIndex] = getAudioUrl();
        setAudioUrlArray(audioUrlArray);
      }
    } 
  }
  
  const confirmMicConneced =()=>{
    setMicState(true);
    recordStart(slideIndex);
  }

  const setNextScore =()=>{
    // sub score view
    const currentScore = getRecordScore();
    if (currentScore === "연결안됨"){
      setMicState(false);
      // setCurrentMode("")
    }else{
      setMicState(true);
    }
    if(currentScore ==="None" || currentScore === "연결안됨"){
      if(slideIndex >= 0){
        scoreView[slideIndex] = ""
        setScoreView(scoreView);
      }
    }
    else {
      if(slideIndex >= 0){  
        scoreView[slideIndex] = currentScore
        setScoreView(scoreView);
      }
    }
  }

  const setBadgeCount = () =>{
    if(!isBadge[slideIndex]){
      isBadge[slideIndex] = true;
      setIsbadge(isBadge);
      setBadge(getBadge() + 1);
    }
  }

  const autoNext = (retryCount) => {
    console.log("오토오오오!")
    getUrlArray();
    setNextScore();
    if(!retryCount){
      setBadgeCount()
    }
    const tempTimeout = setTimeout(() => {
      if (retryCount) {
        onRetryRecord();
      } else {
        setCurrentMode(LEARNING);
        onNextBtnClick(slideIndex);
      }
    }, 2000)
    setCurrentTimeout(tempTimeout);
  }

  const replayVideoRolePlay = () => {
    setSlideIndex(-1);
    setCurrentTime(0);
    setPopup(false);
    scrollByBubble(0);
    setEndedPlay(false)
    videoPlay();
    setCurrentMode(REPLAY);
  }

  const reLearning = () => {
    initData(); 
    videoPlay();
    setCurrentMode(LEARNING)
  }

  useEffect(() => {
    ref.current.load();
    addTimeUpdate();
    console.log(currentId);
    if(!todaysKorean){
      setLastContents( currentUser.id , currentId, getBadge() ,setEndTime())
    }
  }, [currentGrammar]);

  useEffect(() => {
    if (!isCoachMarkView){
      videoPlay();
      localStorage.setItem("hide-video-coach-mark", true);
    }
  }, [isCoachMarkView]);
  
  useEffect(() =>{
    setIdToRecord(currentUser.id, currentId,currentScript, 3, isCurrentRole);
  }, [slideIndex])

  useEffect(() => {
    getCurrentIndex();
    workRecordPart();
  }, [currentTime, currentMode])

  useEffect(() => {
    endPlay(endedPlay);
  }, [endedPlay])

  useEffect(() => {
    handleControlVisible();
  },[nowPlaying])

  const getRoleChangeList =()=>{
    return role.filter((ro, i) => i !== currentRole -1 )
  }

  return (
    <div className="video-roleplay-container">
      <div className="video-roleplay-contents">
        <div className="top video-box">
          <Pagination className="pagination" mode="pointer" total={learningsContents.learn_movie.length === 1? "" : learningsContents.learn_movie.length} current={currentGrammar+1} />
          <video
            className={"video-wrapper"}
            loop={false}
            muted={false}
            autoPlay={true}
            width={"100%"}
            ref={ref}
            playsInline={true}
            onClick={onPlayIconClick}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
          <img className="control-btn" onClick={onPlayIconClick} style={{display : showControl? "" : "none"}} src={nowPlaying? PauseBtn : VideoPlayBtn}/>
          <CoachMark on={isCoachMarkView} onMarkClick={()=>setCoachView(false)}>
            <img className="coach-sentence" src={videoCoachMark} style={{display: isCoachMarkView? "": "none"}}/>
            <img className="coach-sentence2" src={videoCoachMark2} style={{display: isCoachMarkView? "": "none"}}/>
          </CoachMark>
        </div>
        <div id="video-roleplay-scroll" className="common-scroll-black" >
          <canvas className="gradient-dimed-bg" style={{display: !nowPlaying && currentMode !== LISTENING? "":"none"}}/>
          <div className="bubble-container">{setGrammar()}</div>
        </div>
      </div>
      <div className="widget-container bottom">
        <ToggleBtn
          className="widget record"
          style={{ display: (getRecordBool() !== "unrecord") ? "" : "none" }}
          on={getRecordBool() !== "unrecord"}
          onClick={() => { setEndRecord(autoNext); recordToggle(currentScript, setEndTime); }}
        >
          <img src={getRecordBool() !== "unrecord"? speakerOnBtn : recorderBtn} alt=""/>
          {getRecordBool() !== "unrecord"? <RecordingDots /> : null}
        </ToggleBtn>
      </div>
      <LoadingIndicator className="loading-indicator" isLoading={getIsLoading()}/>
      <div className="role-list-bg dimed-bg" style={{display: isRoleListView? "" : "none"}}>
        <div className="role-list">
          <img className="back-btn" src={XBtn} alt="" onClick={()=>setIsRoleListView(false)}/>
          {getRoleChangeList().map((roleItem,i)=>(
            <ToggleBtn 
              className="role-list-item btn-frame"
              key={"role-item", i}
              onClick={()=>changeRole(roleItem)}
            >
              {roleItem.name}
            </ToggleBtn>
          ))}
        </div>
      </div>
      {/* 딤처리 */}
      <div className="dimed-bg" style={{ display: isPopupOpen && isMicConnected && nowPlaying ? "" : "none" }}>
        {retry ? (
          <>
            <div className="retry-panel absolute-center">{"다시 말해보세요"}</div>
            <div className="badge absolute-center bg-center" style={{ backgroundImage: `url(${RedScoreBadge})` }}>
              {score}
              {/* {scoreObject.scoreNum} */}
            </div>
          </>
        ) : (
          <div className="badge absolute-center" style={{ background: `no-repeat center / 100% url(${ScoreBadge})` }}>
            {score}
          </div>
        )}
      </div>
      {/* 하단 버튼 */}
      <div className="btn-bundle" style={{ display: isPopupOpen ? "" : "none" }}>
        {!nowPlaying ? (
          (currentMode === LISTENING) ? (
            <ToggleBtn className="btn-frame small-font" onClick={changeListen}>학습 하기</ToggleBtn>
          ) : (
            <>
              <img className="voca-widget" alt="" src={ListeningBtn} onClick={replayVideoRolePlay}/>
              <img className="retry voca-widget" alt="" src={RetryBtn} onClick={reLearning} />
              <ToggleBtn className="btn-frame small-font" onClick={()=> setIsRoleListView(true)}>역할 변경</ToggleBtn>
              <ToggleBtn className={`btn-frame ${isLastBubble ? "small-font" : ""}`} onClick={onNextClick}>
                {videoRoleIdx < videoRoleLength? "다음 대화" : "결과 보기"}
              </ToggleBtn>
            </>
          )
        ) : null}
      </div>
      <div className="btn-bundle" style={{ display: isPopupOpen || isCoachMarkView ? "none" : "" }}>
          {(currentMode === LISTENING || currentMode === RESULT) ? (
            <ToggleBtn className="btn-frame small-font" onClick={skipListen}>SKIP</ToggleBtn>
          ) : null}
      </div>
      <MicCheckPopup on={!isMicConnected} onYesBtnClick={confirmMicConneced}/>
    </div>
  );
}

export default withRouter(inject("store")(observer(VideoRolePlay)));
