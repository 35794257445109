import UserStore from "./UserStore";
import GlobalStateStore from "./GlobalStateStore";
import HomeStore from "./HomeStore";
import LearningStore from "./LearningStore";
import RecorderStore from "./RecorderStore";
import ProfileStore from "./ProfileStore";
import ContentsStore from "./ContentsStore"
import ResultStore from "./ResultStore"
import AudioStore from "./AudioStore"
import TimeoutStore from "./TimeoutStore"

export default class RootStore {
  userStore;

  globalStateStore;

  homeStore;

  learningStore;

  recorderStore;

  profileStore;

  contentsStore;

  resultStore;

  audioStore;

  timeoutStore

  constructor() {
    this.contentsStore = new ContentsStore(this);
    this.userStore = new UserStore(this);
    this.globalStateStore = new GlobalStateStore(this);
    this.homeStore = new HomeStore(this);
    this.learningStore = new LearningStore(this);
    this.recorderStore = new RecorderStore(this);
    this.profileStore = new ProfileStore(this);
    this.resultStore = new ResultStore(this);
    this.audioStore = new AudioStore(this);
    this.timeoutStore = new TimeoutStore(this);
  }
}
