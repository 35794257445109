import { observable, makeObservable } from "mobx";

class AudioStore {
    rootStore;

    audio = new Audio();

    constructor(root){
        this.rootStore = root;
        makeObservable(this, {
            audio: observable,
        });
    }

    getCurrentAudio =() => {
        return this.audio
    }

    setCurrentAudio = (audio) => {
        this.stopCurrentAudio();
        this.audio = audio
        console.log("setCurrentAudio")
    }

    stopCurrentAudio = () => {
        if(this.audio){
            this.audio.pause()
            this.audio.currentTime = 0;
        }
    }
    
}

export default AudioStore