export function getTotalScore(data){
    let totalScore = 0
    data.forEach(element => {
        const {acoustic, ko_HOLISTIC, ko_PHONOLOGY, ko_PITCH, ko_RATE, ko_SEGMENT} = element
        totalScore += acoustic*5
        totalScore += ko_HOLISTIC
        totalScore += ko_PHONOLOGY
        totalScore += ko_PITCH
        totalScore += ko_RATE
        totalScore += ko_SEGMENT
    });
    if(data.length > 0){
        const returnScore = Math.round(totalScore/(data.length*6*5))
        return returnScore
    }else{
        return 0
    }
}

export function getScore(data, level){
    let tScore = 0
    let scoreRate = [2,3,1,1,3]
    if(level === 1){
        scoreRate = [2,3,1,1,3]
    }else if(level === 2){
        scoreRate = [2,3,2,2,1]
    }else{
        console.log("score레벨 잘못됨 : " + level)
    }
    const {acoustic, ko_HOLISTIC, ko_PHONOLOGY, ko_PITCH, ko_RATE, ko_SEGMENT} = data
    const scoreA = acoustic*50/100
    let scoreK = 0;
    scoreK += ko_HOLISTIC*scoreRate[0]
    scoreK += ko_PHONOLOGY*scoreRate[1]
    scoreK += ko_PITCH*scoreRate[2]
    scoreK += ko_RATE*scoreRate[3]
    scoreK += ko_SEGMENT*scoreRate[4]
    scoreK = scoreK/100

    if(scoreA <= 5){
        if(scoreK <= 5){
            tScore = scoreA + scoreK
        }else{
            tScore = scoreK * 2
        }
    }else if(scoreK <= 5){
        tScore = scoreA * 2
    }else{
        tScore = scoreA + scoreK
    }
    return Math.round(tScore)
}

function getLevelScore(resultData, level, scoreLevel){
    let levelScore = 0;
    let count = 0;
    resultData.forEach(data =>{
        if(data.contentId >= (level*100000000) && data.contentId < ((level+1)*100000000)){
            levelScore += getScore(data,scoreLevel)
            count += 1;
        }
    })
    if(count > 0){
        return Math.round(levelScore/count)   
    }else{
        return 0
    }
}

function getLevelProgress(resultData,contentData){
    let totalLength = 0;
    let resultLength = 0;
    const level = contentData.id
    contentData.parts.forEach(part => {
        part.learn_sentence.forEach((sentence) =>{
            totalLength += sentence.drills.length
        })
        part.learn_dialog.forEach((dialog) =>{
            totalLength += dialog.sequences.length
        })
        part.learn_movie.forEach((movie) =>{
            totalLength += movie.scripts.length
        })
    })
    resultData.forEach(data => {
        if(data.contentId >= (level*100000000) && data.contentId < ((level+1)*100000000)){
            resultLength += 1;
        }
    })
    console.log(totalLength, resultData.length);
    if(totalLength > 0){
        return Math.round(resultLength/totalLength*100)
    }else{
        return 0
    }
}

export function getLevelBadge(resultData, level){
    let levelBadge = 0;
    resultData.forEach(data =>{
        if(data.contentId >= (level*100000000) && data.contentId < ((level+1)*100000000)){
            levelBadge += 1
        }
    })
    return levelBadge
}

function getChapterScore(resultData, level, chapter, scoreLevel){
    let round = level*100 + chapter
    let chapterScore = 0;
    let count = 0;
    resultData.forEach(data =>{
        if(data.contentId >= (round*1000000) && data.contentId < ((round+1)*1000000)){
            chapterScore += getScore(data,scoreLevel)
            count += 1;
        }
    })
    if(count > 0){
        return Math.round(chapterScore/count)  
    }else{
        return 0
    }
}

function getChapterProgress(resultData, partData, level){
    let round = level*100 + partData.id
    let totalLength = 0;
    let resultLength = 0;

    partData.learn_dialog.forEach((dialog) =>{
        totalLength += dialog.sequences.length
    })
    partData.learn_sentence.forEach((sentence) =>{
        totalLength += sentence.drills.length
    })
    partData.learn_movie.forEach((movie) =>{
        totalLength += movie.scripts.length
    })

    resultData.forEach(data => {
        if(data.contentId >= (round*1000000) && data.contentId < ((round+1)*1000000)){
            resultLength += 1;
        }
    })
    if(totalLength > 0){
        return Math.round(resultLength/totalLength*100)
    }else{
        return 0
    }
}

function getChapterBadge(resultData, level, chapter){
    let round = level*100 + chapter
    let chapterBadge = 0;
    resultData.forEach(data =>{
        if(data.contentId >= (round*1000000) && data.contentId < ((round+1)*1000000)){
            chapterBadge += 1;
        }
    })
    return chapterBadge;
}

function getDetailScore(resultData, selectLevel, selectPart, step, scoreLevel ){
    let round = (selectLevel)*10000 + (selectPart)*100 + step+1
    let acoustic = 0;
    let ko_HOLISTIC = 0;
    let ko_PHONOLOGY =0;
    let ko_PITCH = 0;
    let ko_RATE = 0;
    let ko_SEGMENT =0;
    let score = 0;
    let count = 0;
    resultData.forEach(data =>{
        if(data.contentId >= (round*10000) && data.contentId < ((round+1)*10000)){
            acoustic += data.acoustic;
            ko_HOLISTIC += data.ko_HOLISTIC;
            ko_PHONOLOGY += data.ko_PHONOLOGY;
            ko_PITCH += data.ko_PITCH;
            ko_RATE += data.ko_RATE
            ko_SEGMENT += data.ko_SEGMENT;
            score += getScore(data,scoreLevel);
            count += 1;
        }
    })
    let returnObject
    if(count > 0){
        const dcount = count*5
        returnObject = {
            "acoustic" : Math.round(acoustic/dcount),
            "ko_HOLISTIC" : Math.round(ko_HOLISTIC/dcount),
            "ko_PHONOLOGY" : Math.round(ko_PHONOLOGY/dcount),
            "ko_PITCH" : Math.round(ko_PITCH/dcount),
            "ko_RATE" : Math.round(ko_RATE/dcount),
            "ko_SEGMENT" : Math.round(ko_SEGMENT/dcount),
            "score" : Math.round(score/count)
        }
    }else{
        returnObject = {
            "acoustic" : 0,
            "ko_HOLISTIC" : 0,
            "ko_PHONOLOGY" : 0,
            "ko_PITCH" : 0,
            "ko_RATE" : 0,
            "ko_SEGMENT" : 0,
            "score": 0
        }
    }
    return returnObject;
}

function findGrade(resultData, contentId){
    let returnData = ""
    resultData.forEach(data =>{
        if(data.contentId === Number(contentId)){
            returnData = data.grade;
        }
    })
    return returnData;
}

function findAudio(resultData, contentId){
    let returnData = ""
    resultData.forEach(data =>{
        if(data.contentId === Number(contentId)){
            returnData = data.audio_file;
        }
    })
    return returnData;
}

export function getStepProgress(resultData, selectData, step){
    let tempArray = [];
    let tempProgress = 0;
    let amount = 0;

    switch (step){
        case 0 :
            tempArray = selectData.learn_sentence.map(grammar => {
                amount += grammar.drills.length;
                const tmpGradeArr = grammar.drills.map(drill => findGrade(resultData,drill.id));
                return tmpGradeArr.filter(item => item !== "").length;
            })
            tempProgress = Math.round(tempArray.reduce((a,b) => a + b) / amount * 100);
            break;
        case 1 :
            tempArray = selectData.learn_dialog.map(grammar => {
                amount += grammar.sequences.length;
                const tmpGradeArr = grammar.sequences.map(sequence => findGrade(resultData,sequence.id));
                return tmpGradeArr.filter(item => (item !== "")).length;
            })
            tempProgress = Math.round(tempArray.reduce((a,b) => a + b) / amount * 100);
            break;
        case 2 :
            tempArray = selectData.learn_movie.map(grammar => {
                amount += grammar.scripts.length;
                const tmpGradeArr = grammar.scripts.map(script => findGrade(resultData,script.id));
                return tmpGradeArr.filter(item => (item !== "")).length;  
            })
            tempProgress = Math.round(tempArray.reduce((a,b) => a + b) / amount * 100);
            break;
    }
    console.log(tempArray.reduce((a,b) => a + b), amount);
    return tempProgress;
}

function getDetail(resultData, selectData, step){
    let tempArray = [];
    let count = 0;
    switch (step){
        case 0 :
            selectData.learn_sentence.forEach(grammar =>{
                // 문법 단위
                tempArray.push(
                    grammar.drills.map(drill =>({
                        "idx" : count++,
                        "text": drill.sentence_kr,
                        "grade" : findGrade(resultData,drill.id),
                        "audio" : findAudio(resultData,drill.id)
                    }))
                )                
            })
            break;
        case 1 :
            selectData.learn_dialog.forEach(grammar =>{
                // 대화 단위
                tempArray.push(
                    grammar.sequences.map(sequence =>({
                        "idx" : count++,
                        "text": sequence.message,
                        "grade" : findGrade(resultData,sequence.id),
                        "audio" : findAudio(resultData,sequence.id)
                    }))
                )
            })
            break;
        case 2 :
            selectData.learn_movie.forEach(grammar =>{
                // 영상 단위
                tempArray.push(
                    grammar.scripts.map(script =>({
                        "idx" : count++,
                        "text": script.script,
                        "grade" : findGrade(resultData,script.id),
                        "audio" : findAudio(resultData,script.id)
                    }))
                )
            })
            break;
    }
    return tempArray;
}

export function getStepScore(resultData, grammarData, selectLevel, selectPart){
    let selectData = "";
    let scoreLevel = 0;
    let comments = {
        "A+" : "A+",
        "A" : "A",
        "B" : "B",
        "C" : "C",
        "D" : "D"
    }
    let grades = {
        "A+" : 80,
        "A" : 50,
        "B" : 20,
        "C" : 2,
        "D" : 1
    }
    let realPart = 0;
    let realLevel = 0;
    grammarData.forEach((content) =>{
        if(content.id === selectLevel){
            content.parts.forEach((part, idx) =>{
                if(idx === selectPart) {
                    selectData = part;
                    scoreLevel = content.score_level
                    comments = content.comment;
                    grades = content.grade;
                    realLevel = content.id;
                    realPart = part.id;
                }
            })
        }
    })
    console.log(selectData, selectPart);
    if(selectData === ""){
        selectData = grammarData[0].parts[0]
    }
    let tempArray = []
    for(let i = 0; i < 3; i ++){
        let step;
        let dScore = getDetailScore(resultData,realLevel, realPart,i,scoreLevel)
        switch (i){
            case 0 :
                step = {
                    "idx" : 0,
                    "title" : "어휘",
                    "score" : Math.floor(dScore.score),
                    "badge" : 5,
                    "acoustic" : dScore.acoustic,
                    "ko_HOLISTIC" : dScore.ko_HOLISTIC,
                    "ko_PHONOLOGY" : dScore.ko_PHONOLOGY, 
                    "ko_PITCH" : dScore.ko_PITCH, 
                    "ko_RATE" : dScore.ko_RATE, 
                    "ko_SEGMENT" : dScore.ko_SEGMENT,
                    "comment" : comments[getGrade(Math.floor(dScore.score),grades['A+'],grades['A'],grades['B'],grades['C'],grades['D'])],
                    "detail" : getDetail(resultData, selectData, i)
                }
                break;
            case 1 :
                step = {
                    "idx" : 1,
                    "title" : "대화",
                    "score" : Math.floor(dScore.score),
                    "badge" : 6,
                    "acoustic" : dScore.acoustic,
                    "ko_HOLISTIC" : dScore.ko_HOLISTIC,
                    "ko_PHONOLOGY" : dScore.ko_PHONOLOGY, 
                    "ko_PITCH" : dScore.ko_PITCH, 
                    "ko_RATE" : dScore.ko_RATE, 
                    "ko_SEGMENT" : dScore.ko_SEGMENT,
                    "comment" : comments[getGrade(Math.floor(dScore.score),grades['A+'],grades['A'],grades['B'],grades['C'],grades['D'])],
                    "detail" : getDetail(resultData, selectData, i)
                }
                break;
            case 2 :
                step = {
                    "idx" : 2,
                    "title" : "영상",
                    "score" : Math.floor(dScore.score),
                    "badge" : 7,
                    "acoustic" : dScore.acoustic,
                    "ko_HOLISTIC" : dScore.ko_HOLISTIC,
                    "ko_PHONOLOGY" : dScore.ko_PHONOLOGY, 
                    "ko_PITCH" : dScore.ko_PITCH, 
                    "ko_RATE" : dScore.ko_RATE, 
                    "ko_SEGMENT" : dScore.ko_SEGMENT,
                    "comment" : comments[getGrade(Math.floor(dScore.score),grades['A+'],grades['A'],grades['B'],grades['C'],grades['D'])],
                    "detail" : getDetail(resultData, selectData, i)
                }
                break;
            default :
                step = {
                    "idx" : 2,
                    "title" : "영상",
                    "acoustic" : 80,
                    "ko_HOLISTIC" : 80,
                    "ko_PHONOLOGY" : 60, 
                    "ko_PITCH" : 90, 
                    "ko_RATE" : 100, 
                    "ko_SEGMENT" : 100,
                    "comment" : "너는 천재 멋쟁이 억양의 높낮이를 유의하며 이야기해보아요",
                    "detail" : [
                        { "idx" : 0, "title" : "택배를 보내러 우체국에 갔어요", "audio": "aaa.wav" },
                        { "idx" : 1, "title" : "택배를 보내러 우체국에 갔어요", "audio": "aaa.wav" },
                        { "idx" : 2, "title" : "택배를 보내러 우체국에 갔어요", "audio": "aaa.wav" },
                        { "idx" : 3, "title" : "택배를 보내러 우체국에 갔어요", "audio": "aaa.wav" },
                        { "idx" : 4, "title" : "택배를 보내러 우체국에 갔어요", "audio": "aaa.wav" }
                    ]
                }
                break;
        }
        tempArray.push(step);
    }
    return tempArray;
}

export function getResultScore(resultData, contentsData){
    let tempArray = []
    contentsData.forEach((content) =>{
        const levelScore = getLevelScore(resultData, content.id, content.score_level)
        const levelProgress = getLevelProgress(resultData, content)
        const levelBadge = getLevelBadge(resultData, content.id);
        let parts = []
        content.parts.forEach((part) => {
            const chapterScore = getChapterScore(resultData, content.id, part.id, content.score_level)
            const chapterProgress = getChapterProgress(resultData, part, content.id)
            const chapterBadge = getChapterBadge(resultData, content.id, part.id)
            let partTemp={
                "id" : part.id,
                "title" : part.title,
                "progress" : chapterProgress,
                "score" : chapterScore,
                "badge" : chapterBadge
            }
            parts.push(partTemp)
        })
        let contentTemp = {
            "id" : content.id,
            "title" : content.title,
            "progress" : levelProgress,
            "score" : levelScore,
            "badge" : levelBadge,
            "parts" : parts
        }     
        tempArray.push(contentTemp)   
    })
    return tempArray
}

export function getGrade(score, ApScore, AScore, BScore, CScore, DScore){
    if (ApScore <= score){
        return "A+"
    }else if( AScore <= score && score < ApScore ){
        return "A"
    }else if( BScore <= score && score < AScore){
        return "B"
    }else if(CScore <= score && score < BScore){
        return "C"
    }else if(score <= CScore){
        return "D"
    }else {
        console.log(DScore);
        return "점수오류"
    }
}
